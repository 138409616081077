import { KoreanContainer, KoreanItem } from '../../styles/onBoardStyle';
import Checkbox from './checkbox';

interface UsPPProps {
	isFirstChecked: boolean;
	checkFirst: Function;
	isScndChecked: boolean;
	checkScnd: Function;
}

const UsPP = (props: UsPPProps) => {
	return (
		<KoreanContainer style={{ padding: '0px 0px 24px' }}>
			<KoreanItem style={{ marginLeft: 'initial' }}>
				<label>
					When you use the Clinique Reality Skincare Recommendation Diagnostic Tool, Clinique accesses and temporarily retains a scan of your facial geometry from your image in order to provide you with a skin diagnostic experience and related product recommendations. Your facial geometry data may be shared with or accessed by our service providers
					for these purposes. Clinique will only retain your facial geometry data for the duration of your use of the Clinique Reality Skincare Recommendation Diagnostic Tool and will not retain the data thereafter. For information about Clinique's privacy practices, please read our{' '}
					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='https://www.clinique.com/customer-care/general-info/privacy-policy'>
						Privacy Policy
					</a>
					.
				</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isFirstChecked} onChange={() => props.checkFirst(!props.isFirstChecked)} disabled={false} />
				<label>You expressly consent and provide a written release to the temporary access, use, storage and disclosure of your facial geometry data for the purposes described above.</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isScndChecked} onChange={() => props.checkScnd(!props.isScndChecked)} disabled={false} />
				<label>
					By checking this box, you agree to the processing of your skin diagnostic results by Clinique, which may be characterized as sensitive personal information that requires your consent for processing depending on applicable law. This information is collected and processed for the purpose of providing you product recommendations. You have
					the right to withdraw your consent to such processing at any time by selecting “Limit Use of Sensitive Personal Information” on our{' '}
					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='https://privacyportal.onetrust.com/webform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/51605180-d44a-45d9-bf11-2ae9c0b07028?source=policy'>
						Privacy Portal
					</a>
					.
				</label>
			</KoreanItem>
		</KoreanContainer>
	);
};

export default UsPP;
