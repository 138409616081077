import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AnalyseContainer = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 100svh;
	width: 100%;
`;
export const AnalyseBarContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 5;
	bottom: 124px;
	flex-direction: column;
	padding: 0px 16px;
	box-sizing: border-box;

	@media (max-width: 768px) {
		bottom: 64px;
	}
`;

export const AnalyseMainText = styled(motion.p)`
	font: normal normal 500 18px/100% 'Clinique Helvetica Neue';
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
	color: white;
	margin-bottom: 32px;
	opacity: 0;
	text-align: center;

	@media (max-width: 768px) {
		font: normal normal 400 20px/100% 'Clinique Helvetica Neue';
		margin-bottom: 16px;
	}
`;

export const BarContainer = styled.div`
	display: flex;
	width: 70%;
	gap: 4px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const EmptyBar = styled.div`
	background-color: rgba(250, 250, 250, 0.3);
	height: 4px;
	flex: 1;
	position: relative;

	@media (max-width: 768px) {
		height: 4px;
	}
`;

export const FillingBar = styled(motion.div)`
	background-color: #adddb7;
	height: 4px;
	width: 0%;
	z-index: 2;
	position: absolute;

	@media (max-width: 768px) {
		height: 4px;
	}
`;

export const AnalyzeCanvas = styled.canvas`
	height: 100dvh;
	width: auto;
	min-width: 100vw;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	object-fit: cover;
`;

export const ImageUser = styled.img`
	position: absolute;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	min-width: 100vw;
	object-fit: cover;

	@media (min-width: 768px) {
		width: 100%;
		height: initial;
	}
`;

export const Curtain = styled.div`
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 24.54%, rgba(0, 0, 0, 0) 74.62%, rgba(0, 0, 0, 0.25) 100%);
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 1;
	top: 0px;
	left: 0px;
`
