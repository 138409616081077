import { SkinAnalyse } from "../../type"

export const demkyParser = (demkyResult: any): SkinAnalyse[] => {
    let skinAnalysis: SkinAnalyse[] = [
        { name: "irritation", score: computeIrritation(demkyResult) },
        { name: "blemishes", score: computeBlemishes(demkyResult), prediction: demkyResult.results.ACNE.pred },
        { name: "fatigue", score: computeFatigue(demkyResult), prediction: demkyResult.results.EYE_BAGS.pred },
        { name: "lines_wrinkles", score: computeWrinkles(demkyResult), prediction: demkyResult.results.WRINKLES.pred },
        { name: "uneven_skintone", score: -1 },
        { name: "pores_uneven_structure", score: -1 },
        { name: "loss_of_volume", score: -1 },
        { name: "dehydration", score: -1 },
    ]

    console.warn(skinAnalysis)
    return skinAnalysis.sort((a: SkinAnalyse, b: SkinAnalyse) => {
        return b.score - a.score;
    });
}

const computeBlemishes = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    if (!demkyResults.results.ACNE) {
        return -1
    }

    switch (demkyResults.results.ACNE.level) {
        case "high":
            return 1
        case "medium":
            return 0.66
        case "low":
            return 0.33
        case "very_low":
            return 0.15
        default:
            return 0
    }
}

const computeWrinkles = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    if (!demkyResults.results.WRINKLES) {
        return -1
    }

    switch (demkyResults.results.WRINKLES.level) {
        case "high":
            return 1
        case "medium":
            return 0.66
        case "low":
            return 0.33
        case "very_low":
            return 0.15
        default:
            return 0
    }
}

const computeFatigue = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let darkCirclesScore: number = 0
    let eyeBagsScore: number = 0

    switch (demkyResults.results.DARK_CIRCLES.level) {
        case "high":
            darkCirclesScore = 1
            break
        case "medium":
            darkCirclesScore = 0.66
            break
        case "low":
            darkCirclesScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.EYE_BAGS.level) {
        case "high":
            eyeBagsScore = 1
            break
        case "medium":
            eyeBagsScore = 0.66
            break
        case "low":
            eyeBagsScore = 0.33
            break
        default:
            break
    }
    console.warn((eyeBagsScore + darkCirclesScore) / 2)
    return (eyeBagsScore + darkCirclesScore) / 2
}

const computeIrritation = (demkyResults: any): number => {
    // L'irritation c'est la moyenne de l'acne, des rougeurs et de la roughness.

    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let acneScore: number = 0
    let rednessScore: number = 0

    switch (demkyResults.results.ACNE.level) {
        case "high":
            acneScore = 1
            break
        case "medium":
            acneScore = 0.66
            break
        case "low":
            acneScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.REDNESS.level) {
        case "high":
            rednessScore = 1
            break
        case "medium":
            rednessScore = 0.66
            break
        case "low":
            rednessScore = 0.33
            break
        default:
            break
    }
    return (rednessScore + acneScore) / 2
}