import { getCliniqueWebsiteForImageUrl } from "../../constantes/utilitaries"
import { ConcernName, Item, Product, Recommendation, SkinType } from "../../type"
import rednessVideo from "../../assets/videos/Redness.mp4"
import acneVideo from "../../assets/videos/Breakout.mp4"
import lossVolumeVideo from "../../assets/videos/Loss of Elasticity.mp4"
import oilinessVideo from "../../assets/videos/Oiliness.mp4"
import poreVideo from "../../assets/videos/Pores.mp4"
import fatigueVideo from "../../assets/videos/Discoloration.mp4"
import unevenTextureVideo from "../../assets/videos/Uneven Texture.mp4"
import wrinklesVideo from "../../assets/videos/Wrinkles.mp4"
import healthyVideo from "../../assets/videos/healthyVideo.mp4"


export const handleQuantity = (product: Product | undefined) => {
    let displayQuantity: string = ""

    if (!product) {
        return displayQuantity
    }

    if (!product.items) {
        return displayQuantity
    }

    if (product.items!.length > 0) {
        let item: Item | undefined = product.items.find(item => item.sku_id === product.instoreSku)

        if (item) {
            displayQuantity = item.quantity
        } else {
            displayQuantity = product.items[0].quantity
        }
    }

    return displayQuantity
}

export const handleStepText = (t: Function, routineStep: number) => {
    switch (routineStep) {
        case 1:
            return t("step-1", "Step 1: Cleanse")
        case 2:
            return t("step-2", "Step 2: Exfoliate")
        case 3:
            return t("step-3", "Step 3: Moisturize")
        default:
            return "Step problem"
    }
}

export const handleShortDescription = (product: Product | undefined): string => {
    let displayedDesc: string = ""

    if (!product) {
        return displayedDesc
    }
    if (product.texts) {
        if (product.texts.find((text: { label: string, content: string }) => text.label === "short_description")!) {
            displayedDesc = product.texts.find((text: { label: string, content: string }) => text.label === "short_description")!.content
        }
    }
    if (displayedDesc.length > 200) {
        displayedDesc = displayedDesc.slice(0, 200) + "..."
    }
    return displayedDesc
}

export const handlePrice = (product: Product | undefined): string => {
    let displayedPrice: string = ""

    if (!product) {
        return displayedPrice
    }

    if (product.items) {
        let item: Item | undefined = product.items.find(item => item.sku_id === product.instoreSku)

        if (item) {
            displayedPrice = item.price
        } else {
            displayedPrice = ""
        }
    }
    return displayedPrice
}

export const handleSrc = (product: Product | undefined, market: string) => {

    if (!product) {
        return ""
    }

    if (!product.items) {
        return ""
    }

    if (product.items) {
        let item: Item | undefined = product.items.find(item => item.sku_id === product.instoreSku)

        if (item) {
            return new URL(item.image[0].src, getCliniqueWebsiteForImageUrl(market)).toString();
        } else {
            const src = product.items[0]?.image[0]?.src
            return src ? new URL(src, getCliniqueWebsiteForImageUrl(market)).toString() : "";
        }
    }
}

export const handleSkinTypetext = (skinType: SkinType, t: Function): string => {
    switch (skinType) {
        case SkinType.dry:
            return t("type-1")
        case SkinType.dryCombination:
            return t("type-2")
        case SkinType.oilyCombination:
            return t("type-3")
        case SkinType.veryOily:
            return t("type-4")
        default:
            return "problem"
    }
}

export const getTagsFromRecommendations = (product: Product, recommendations: Recommendation[]) => {
    let productToComplete: Product = product
    for (let i = 0; i < recommendations.length; i++) {
        for (let ir = 0; ir < recommendations[i].mainProducts.length; ir++) {
            if (productToComplete.prod_base_id === recommendations[i].mainProducts[ir].prod_base_id) {
                if (!productToComplete.tags.includes(recommendations[i].concern) && productToComplete.tags.length < 2) {
                    productToComplete.tags.push(recommendations[i].concern)
                }
            }
        }

        for (let ir = 0; ir < recommendations[i].otherProducts.length; ir++) {
            if (productToComplete.prod_base_id === recommendations[i].otherProducts[ir].prod_base_id) {
                if (!productToComplete.tags.includes(recommendations[i].concern) && productToComplete.tags.length < 2) {
                    productToComplete.tags.push(recommendations[i].concern)
                }
            }
        }
    }
}

export const checkIfHyperReal = (market: string, product?: Product): boolean => {

    if (!product) {
        return false
    }

    if (market === "UK" || market === "IE" || market === "TRIE" || market === "TRUK") {
        return false
    }

    if (product.prod_base_id === "87057") {
        return true
    }

    return false
}

export const handleVideoConcern = (concern: ConcernName) => {
    switch (concern) {
        case ConcernName.BLEMISHES:
            return acneVideo
        case ConcernName.DEHYDRATION:
            return oilinessVideo
        case ConcernName.IRRITATION:
            return rednessVideo
        case ConcernName.LOSS_OF_VOLUME:
            return lossVolumeVideo
        case ConcernName.PORES:
            return poreVideo
        case ConcernName.WRINKLES:
            return wrinklesVideo
        case ConcernName.UNEVEN_SKINTONE:
            return unevenTextureVideo
        case ConcernName.FATIGUE:
            return fatigueVideo
        default:
            return healthyVideo
    }
}