import { defaultCBSKConfig, SkinDiagApiConfig } from "./defaults"
import * as actionTypes from '../../constantes/actionTypes'

export const getEffectsFromOTSTC = (blob: Blob, baseIds: string[], dispatch: Function) => {
    let config: SkinDiagApiConfig = defaultCBSKConfig()
    let effectsFile = require("../../assets/Data/Effect.json")

    baseIds.forEach((baseid: string) => {
        console.log("[BEFORE/AFTER] Fetching Effects for " + baseid)

        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer f15ac5dd598e2234f17e12cd8b72535dxhQjYc9+Rm7OeIJ7yn0z1/uo19wnYNRA1RfD47vZ4MHeEULWlUBUTFREYmKkKMNL");

        var formdata = new FormData();
        formdata.append("face", blob, "userImg.jpeg");
        formdata.append("effect", effectsFile[baseid]["effect"])

        fetch(config.url + "/effect", {
            headers: myHeaders,
            method: 'POST',
            body: formdata
        })
            .then(response => response.json())
            .then(response => dispatch({type: actionTypes.UPDATE_EFFECTS, value: {sku: baseid, imgURL: response.url}}))
    });
}