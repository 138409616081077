import { BackButton, Bar, Component, ComponentBackground, CrossButton, EmptyBar, EyesButton, ForeHeadButton, HyperrealContainer, HyperRealImg, HyperRealText, HyperRealTitle, HyperRealVideo, HyperRealVideoContainer, ImgsContainer, NoseButton, Shadow, SndRound, TapToSelect, TapToZoom, TextContainer } from "../styles/hyperrealStyle"

import videoAsianForWorld from '../assets/Data/hyperreal/ChineseAsian_noEarrings_sm.mp4'
import videoAsian from '../assets/Data/hyperreal/ChineseAsian_noEarrings_sm 2.mp4'
import videoCaucasian2 from '../assets/Data/hyperreal/FP02_noEarrings_sm.mp4'
import videoEuropean from '../assets/Data/hyperreal/FP04_noEarrings_sm.mp4'
import videoBlack from '../assets/Data/hyperreal/FP06_noEarrings_sm.mp4'

import { useEffect, useRef, useState } from "react"
import cross from '../assets/icons/Cross.svg'
import { AnimatePresence } from "framer-motion"

import europeanFace from '../assets/Images/hyperreal/CaucasianFace.jpg'
import caucasianFace2 from '../assets/Images/hyperreal/CaucasianFace2.jpg'
import asianFace from '../assets/Images/hyperreal/AsianFace.jpg'
import asianFaceForWorld from '../assets/Images/hyperreal/AsianFaceForWorld.jpg'
import blackFace from '../assets/Images/hyperreal/BlackFace.jpg'
import { useSelector } from "react-redux"
import { CliniqueState } from "../type"
import { useTranslation } from "react-i18next"

interface HYprops {
    displayHyperReal: Function
}

const HyperrealView = (props: HYprops) => {
    const [isVideoOpen, openVideo] = useState<EthnyType | null>(null)
    const market = useSelector((state: CliniqueState) => state.session.market)
    const { t } = useTranslation()
    const videoRef = useRef<HTMLVideoElement>(null)
    const [idleInterval, setIdleInterval] = useState<any>(null)
    const [eyesIdleInterval, setEyesIdleInterval] = useState<any>(null)
    const [ForeHeadIdleInterval, setForeHeadIdleInterval] = useState<any>(null)
    const [noseIdleInterval, setNoseIdleInterval] = useState<any>(null)
    const [isBackButtonEnabled, enableBackButton] = useState<boolean>(false)
    const [AreRoundButtonEnabled, enableRoundButton] = useState<boolean>(false)
    const [isBarEnabled, enableBar] = useState<boolean>(false)
    const asianMarkets = ["CN", "JP", "KR", "HK", "TW", "TH", "TRTH", "TRASIA", "TRKR", "TRJP", "TRCN", "HK", "TRHK", "TRTW", "TRCHINA", "SG", "TRSG"]

    enum HRState {
        idle,
        eyesZoomed,
        foreHeadZoomed,
        noseZoomed
    }

    enum EthnyType {
        european = "EUROPEAN",
        caucasian2 = "CAUCASIAN",
        asian = "ASIAN",
        asianForWorld = 'ASIAN_FOR_WORLD',
        black = "BLACK"
    }

    const [HRstate, setHRState] = useState<HRState>(HRState.idle)

    useEffect(() => {
        if (videoRef.current && isVideoOpen) {
            let _idleInterval = setInterval(() => {
                if (getFrame() === 100) {
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 0
                    videoRef.current!.play()
                }
            }, 30)
            setIdleInterval(_idleInterval)
            enableBackButton(false)
            enableBar(false)
        } else {
            window.clearInterval(idleInterval)
            setHRState(HRState.idle)
        }
        //eslint-disable-next-line
    }, [isVideoOpen])

    const handleEyesClick = () => {
        if (HRstate === HRState.idle) {
            enableRoundButton(false)
            window.clearInterval(idleInterval)
            videoRef.current!.currentTime = 3.33
            let _idleEyesInterval = setInterval(() => {
                if (getFrame() === 260) {
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 5.67
                    videoRef.current!.play()
                }
            }, 30)
            setTimeout(() => {
                enableBackButton(true)
            }, 2500);
            setTimeout(() => {
                enableBar(true)
            }, 2500);
            setHRState(HRState.eyesZoomed)
            setEyesIdleInterval(_idleEyesInterval)
        }
    }

    const handleForeHeadClick = () => {
        if (HRstate === HRState.idle) {
            enableRoundButton(false)
            window.clearInterval(idleInterval)
            videoRef.current!.currentTime = 11
            let _idleForeHeadInterval = setInterval(() => {
                if (getFrame() === 490) {
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 13.4
                    videoRef.current!.play()
                }
            }, 30)
            setTimeout(() => {
                enableBackButton(true)
            }, 2500);
            setTimeout(() => {
                enableBar(true)
            }, 2500);
            setHRState(HRState.foreHeadZoomed)
            setForeHeadIdleInterval(_idleForeHeadInterval)
        }
    }

    const handleNoseClick = () => {
        if (HRstate === HRState.idle) {
            enableRoundButton(false)
            window.clearInterval(idleInterval)
            videoRef.current!.currentTime = 18.63
            let _idleNoseInterval = setInterval(() => {
                if (getFrame() === 720) {
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 21
                    videoRef.current!.play()
                }
            }, 30)
            setTimeout(() => {
                enableBackButton(true)
            }, 2500);

            setTimeout(() => {
                enableBar(true)
            }, 2500);
            setHRState(HRState.noseZoomed)
            setNoseIdleInterval(_idleNoseInterval)
        }
    }

    const back = () => {
        if (HRstate === HRState.eyesZoomed) {
            enableBackButton(false)
            enableBar(false)
            window.clearInterval(eyesIdleInterval)
            window.clearInterval(idleInterval)

            videoRef.current!.currentTime = 8.67
            let _zoomOut = setInterval(() => {
                if (getFrame() === 329) {
                    enableRoundButton(true)
                    window.clearInterval(_zoomOut)
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 0
                    videoRef.current!.play()
                    let _idleInterval = setInterval(() => {
                        if (getFrame() === 100) {
                            videoRef.current!.pause()
                            videoRef.current!.currentTime = 0
                            videoRef.current!.play()
                        }
                    }, 30)
                    setIdleInterval(_idleInterval)
                    setHRState(HRState.idle)
                }
            }, 30)
        } else if (HRstate === HRState.foreHeadZoomed) {
            enableBackButton(false)
            enableBar(false)
            window.clearInterval(ForeHeadIdleInterval)
            window.clearInterval(idleInterval)

            videoRef.current!.currentTime = 16.47
            let _zoomOut = setInterval(() => {
                if (getFrame() === 558) {
                    enableRoundButton(true)
                    window.clearInterval(_zoomOut)
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 0
                    videoRef.current!.play()
                    let _idleInterval = setInterval(() => {
                        if (getFrame() === 100) {
                            videoRef.current!.pause()
                            videoRef.current!.currentTime = 0
                            videoRef.current!.play()
                        }
                    }, 30)
                    setIdleInterval(_idleInterval)
                    setHRState(HRState.idle)
                }
            }, 30)
        } else if (HRstate === HRState.noseZoomed) {
            enableBackButton(false)
            enableBar(false)
            window.clearInterval(noseIdleInterval)
            window.clearInterval(idleInterval)

            videoRef.current!.currentTime = 24.1
            let _zoomOut = setInterval(() => {
                if (getFrame() === 780) {
                    enableRoundButton(true)
                    window.clearInterval(_zoomOut)
                    videoRef.current!.pause()
                    videoRef.current!.currentTime = 0
                    videoRef.current!.play()
                    let _idleInterval = setInterval(() => {
                        if (getFrame() === 100) {
                            videoRef.current!.pause()
                            videoRef.current!.currentTime = 0
                            videoRef.current!.play()
                        }
                    }, 30)
                    setIdleInterval(_idleInterval)
                    setHRState(HRState.idle)
                }
            }, 30)
        }
    }

    const getFrame = () => {
        const frameRate = 30
        return Math.floor(videoRef.current!.currentTime * frameRate)
    }

    const closeVideos = () => {
        window.clearInterval(eyesIdleInterval)
        window.clearInterval(ForeHeadIdleInterval)
        window.clearInterval(idleInterval)
        window.clearInterval(noseIdleInterval)
        openVideo(null)
        enableRoundButton(false)
        enableBar(false)
    }

    const handlingTypeVideoSrc = () => {
        switch (isVideoOpen) {
            case EthnyType.caucasian2:
                return videoCaucasian2
            case EthnyType.european:
                return videoEuropean
            case EthnyType.black:
                return videoBlack
            case EthnyType.asianForWorld:
                return videoAsianForWorld
            default:
                return videoAsian
        }
    }

    return (
        <ComponentBackground
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <Shadow />
            <Component>
                {!isVideoOpen && <CrossButton
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.5 }} big src={cross} onClick={() => {
                        closeVideos()
                        props.displayHyperReal(false)
                    }} />}

                <TextContainer>
                    <HyperRealTitle>
                        {t("choose-closest-skintone")}
                    </HyperRealTitle>

                    {/* <HyperRealText>
                            {t("clinical-version-16-weeks")}
                        </HyperRealText> */}

                    <HyperRealText>
                        {t("see-it-visibility")}
                    </HyperRealText>

                </TextContainer>
                <ImgsContainer>
                    {!asianMarkets.includes(market) && <HyperrealContainer onClick={() => openVideo(EthnyType.caucasian2)}>
                        <HyperRealImg $url={caucasianFace2} />
                    </HyperrealContainer>}

                    {asianMarkets.includes(market) && <HyperrealContainer onClick={() => openVideo(EthnyType.asian)}>
                        <HyperRealImg $url={asianFace} />
                    </HyperrealContainer>}

                    {!asianMarkets.includes(market) && <HyperrealContainer onClick={() => openVideo(EthnyType.asianForWorld)}>
                        <HyperRealImg $url={asianFaceForWorld} />
                    </HyperrealContainer>}

                    {!asianMarkets.includes(market) && <HyperrealContainer onClick={() => openVideo(EthnyType.european)}>
                        <HyperRealImg $url={europeanFace} />
                    </HyperrealContainer>}

                    {!asianMarkets.includes(market) && <HyperrealContainer onClick={() => openVideo(EthnyType.black)}>
                        <HyperRealImg $url={blackFace} />
                    </HyperrealContainer>}

                </ImgsContainer>

                <TapToSelect>
                    Tap image to select
                </TapToSelect>

                <AnimatePresence>
                    {isVideoOpen &&
                        <HyperRealVideoContainer
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 0.5 }}>
                            <HyperRealVideo
                                onLoadedData={(e) => setTimeout(() => {
                                    enableRoundButton(true)
                                }, 400)}
                                playsInline
                                autoPlay
                                loop
                                muted
                                ref={videoRef}>
                                <source type="video/mp4" src={handlingTypeVideoSrc()} />
                            </HyperRealVideo>

                            <AnimatePresence>
                                {isBackButtonEnabled && <BackButton
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    enabled={true}
                                    onClick={() => back()}>
                                    {t("back")}
                                </BackButton>}
                            </AnimatePresence>

                            <AnimatePresence>
                                {isBarEnabled && <EmptyBar
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Bar />
                                </EmptyBar>}
                            </AnimatePresence>


                            {!isBackButtonEnabled &&
                                <AnimatePresence>
                                    <CrossButton
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        src={cross}
                                        onClick={() => closeVideos()} />
                                </AnimatePresence>
                            }

                            <AnimatePresence>
                                {AreRoundButtonEnabled && <EyesButton
                                    key={6}
                                    onClick={() => handleEyesClick()}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 0.8 }}
                                    exit={{ opacity: 0 }}>
                                    <TapToZoom>{t("tap-for-results")}</TapToZoom>
                                    <SndRound>
                                        <SndRound small />
                                    </SndRound>
                                </EyesButton>}

                                {AreRoundButtonEnabled && isVideoOpen !== EthnyType.asian && <ForeHeadButton
                                    key={7}
                                    onClick={() => handleForeHeadClick()}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 0.8 }}
                                    exit={{ opacity: 0 }}>
                                    <TapToZoom>{t("tap-for-results")}</TapToZoom>
                                    <SndRound>
                                        <SndRound small />
                                    </SndRound>
                                </ForeHeadButton>}

                                {AreRoundButtonEnabled && isVideoOpen !== EthnyType.asian && <NoseButton
                                    key={8}
                                    onClick={() => handleNoseClick()}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 0.8 }}
                                    exit={{ opacity: 0 }}>
                                    <TapToZoom>{t("tap-for-results")}</TapToZoom>
                                    <SndRound>
                                        <SndRound small />
                                    </SndRound>
                                </NoseButton>}
                            </AnimatePresence>

                        </HyperRealVideoContainer>
                    }
                </AnimatePresence>

            </Component>
        </ComponentBackground>
    )
}

export default HyperrealView