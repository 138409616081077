import { useSelector } from 'react-redux';
import { CliniqueState, StepView } from '../type';
import AnalyseComponent from './analyse';
import Camera from './camera';
import OnBoardComponent from './onBoard';
import QuestComponent from './newQuest';
import ResultsComponent from './newResultViews/main';
import TutoComponent from './tuto';
import ReadyComponent from './analysisReady'

const ViewDispatcher = () => {
	const stepView = useSelector((state: CliniqueState) => state.session.stepView);

	switch (stepView) {
		case StepView.camera:
			return Camera();
		case StepView.pictureAnalyse:
			return AnalyseComponent();
		case StepView.onBoard:
			return OnBoardComponent();
		case StepView.tuto:
			return TutoComponent();
		case StepView.quests:
			return QuestComponent();
		case StepView.ready:
			return ReadyComponent();
		case StepView.results:
			return ResultsComponent();
		default:
			return Camera();
	}
};

export default ViewDispatcher;
