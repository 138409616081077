import { ANALYZE_IMAGE_WIDTH } from "../constants";

export const extractCanvasContext = (source: HTMLVideoElement | HTMLCanvasElement, mirrored?: boolean): CanvasRenderingContext2D | null => {
    if (source instanceof HTMLCanvasElement) {
        return source.getContext('2d', {
            willReadFrequently: true,
        });
    }

    const { videoHeight, videoWidth } = source;

    const videoRatio = videoWidth / videoHeight;

    const canvas = document.createElement('canvas');
    canvas.width = ANALYZE_IMAGE_WIDTH;
    canvas.height = ANALYZE_IMAGE_WIDTH / videoRatio;
    const ctx = canvas.getContext('2d', {
        willReadFrequently: true,
    });

    if (!ctx) {
        return null
    }

    if (mirrored) {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
    }

    ctx.drawImage(source, 0, 0, source.videoWidth, source.videoHeight, 0, 0, ANALYZE_IMAGE_WIDTH, ANALYZE_IMAGE_WIDTH / videoRatio);

    return ctx;
}