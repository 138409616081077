import { DetectionConstraints } from "../types/detectionConstraints";

export const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
    width: 2304,
    height: 3072,
    aspectRatio: 3 / 4,
    facingMode: 'user',
};

export const DEFAULT_DETECTION_CONSTRAINTS: DetectionConstraints = {
    backDistance: 0.7,
    frontDistance: 0.33,
    positionRight: 0.1,
    positionLeft: 0.1,
    positionTop: 0.4,
    positionBottom: 0.7,
    rotation: 3,
    brightnessDiff: 15,
    brightnessLevel: 60
}

export const DEFAULT_COUNTDOWN = 3000;

export const DEFAULT_DETECTION_INTERVAL = 300;

export const DEFAULT_WARNINGS = {
    distance: true,
    position: true,
    rotation: true,
    brightness: true,
}

export const DEFAULT_PHOTO_SETTINGS = {
    type: 'jpeg',
    quality: 0.92,
}

export const ANALYZE_IMAGE_WIDTH = 720;