import { CBSKError } from './api/skinDiag/defaults';
import { Predictions } from 'a3camera';


export type CliniqueState = {
	session: Session
}

export type Session = {
	stepView: StepView,
	analyseStep: number,
	isMicrosite: boolean,
	market: string,
	retailer: Retailer | null,
	imageUser: any | null,
	error: CBSKError[],
	sessionID: string,
	biometrics: any,
	analyses: Array<SkinAnalyse>,
	skinType: SkinType,
	uvProtection: UVprotection,
	predictions: Predictions | null,
	nascentImage: string,
	MindStixReco?: {},
	recommendations: Recommendation[],
	effects: {
		sku: string,
		imgURL: string
	}[]
}

export type Recommendation = {
	concern: ConcernName,
	mainProducts: Product[],
	uvProducts: Product[],
	otherProducts: Product[]
}

export enum Retailer {
	sephora = "sephora",
	MS = "marksandspencer",
	ultah = "ultah",
	other = "OTHER"
}

export enum StepView {
	onBoard = 0,
	tuto = 1,
	camera = 2,
	pictureAnalyse = 3,
	quests = 4,
	ready = 5,
	results = 6
}

export enum SkinType {
	dry = "dry",
	dryCombination = "dryCombo",
	oilyCombination = "oilyCombo",
	veryOily = "veryOily",
}

export enum UVprotection {
	builtIn = "builtIn",
	protectionBase = "protectionBase",
	mineral = "mineral",
	highSPF = "highSPF",
}

export type SkinAnalyse = {
	name: string
	score: number
	displayScore?: number
	imageURL?: string
	prediction?: any
}

export type FaceLandmarksDetectionStatus = {
	loading?: boolean;
	noFace?: boolean;
	modelError?: boolean;
	good?: boolean;
	badPositionLeft?: boolean;
	badPositionRight?: boolean;
	badPositionTop?: boolean;
	badPositionBottom?: boolean;
	badDistanceFar?: boolean;
	badDistanceClose?: boolean;
	badOrientation?: boolean;
	badBrightness?: boolean;
};

export type FaceLandmarksDetectionResults = {
	status?: FaceLandmarksDetectionStatus;
	predictions?: any;
	image?: ImageData;
};

export enum FoundationMatchStatus {
	loading,
	noFace,
	error,
	good,
	badPosition,
	badDistanceFar,
	badDistanceClose,
}

export type FoundationMatchResults = {
	faceAreas: FaceArea[];
	averageColor?: SkinColor;
	mainColor?: SkinColor;
	secondaryColor?: SkinColor;
	// canvas?: OffscreenCanvas;
	status?: FoundationMatchStatus;
};

export const FACE_AREAS: any = {
	leftForehead: {
		origin: 299,
		abs: 333,
		ord: 297,
	},
	middleForehead: {
		origin: 109,
		abs: 338,
		ord: 108,
	},
	rightForehead: {
		origin: 69,
		abs: 104,
		ord: 67,
	},
	nose: {
		origin: 45,
		abs: 275,
		ord: 8,
	},
	leftCheek: {
		origin: 422,
		abs: 364,
		ord: 330,
	},
	rightCheek: {
		origin: 202,
		abs: 135,
		ord: 119,
	},
	chin: {
		origin: 194,
		abs: 418,
		ord: 176,
	},
};

export type SkinColor = {
	rgb: [number, number, number];
	lab: [number, number, number];
	ita: number;
	skinColor: string;
};

export type FaceArea = {
	name: string;
	x: number;
	y: number;
	width: number;
	height: number;
	data?: ImageData;
	skinColor?: SkinColor;
	lab?: number[];
};

export type FaceAreaColor = {
	faceArea: FaceArea,
	skinColor: SkinColor
}

export enum ConcernName {
	WRINKLES = "lines_wrinkles",
	UNEVEN_SKINTONE = "uneven_skintone",
	IRRITATION = "irritation",
	PORES = "pores_uneven_structure",
	LOSS_OF_VOLUME = "loss_of_volume",
	BLEMISHES = "blemishes",
	FATIGUE = "fatigue",
	DEHYDRATION = "dehydration",
	NONE = "none"
}

export enum Category {
	cleanser = "cleanser",
	dailySpfProtection = "dailySpfProtection",
	dailySpfProtection1 = "dailySpfProtectionQuiz1",
	dailySpfProtection2 = "dailySpfProtectionQuiz2",
	dailySpfProtection3 = "dailySpfProtectionQuiz3",
	dailySpfProtection4 = "dailySpfProtectionQuiz4",
	eyecream = "eyecream",
	foundation = "foundation",
	id = "id",
	makeupRemover = "makeupRemover",
	serum = "serum",
	moisturizer = "moisturizer",
	moisturizer3 = "moisturizer3step",
	toner = "toner",
	wateryLotion = "wateryLotion",
	bodyCare = "bodyCare"
}

export enum Status {
	active = "ACTIVE",
	oos = "OOS",
	doNotDisplay = "DO_NOT_DISPLAY"
}

export type Product = {
	base_id: string,
	instoreSku: string,
	skuID: string,
	sppURL: string,
	priority: number,
	category?: Category,
	order?: number
	images: any[],
	name: string,
	price: string,
	prod_base_id: string,
	tags: ConcernName[]
	dayNightValue?: "day" | "night" | "both"
	texts?: {
		label: string,
		content: string
	}[],
	items?: Item[]
}

export type Item = {
	sku_id: string,
	quantity: string,
	price: string,
	status: Status,
	shadeCode?: string,
	shadeName?: string,
	image: {
		alt: string,
		height: number
		src: string
		width: number
	}[]
}

export interface Layer {
	image: string,
	position: {
		topLeft: {
			name: string,
			index: number,
			offsetX: number,
			offsetY: number,
		},
		bottomRight: {
			name: string,
			index: number,
			offsetX: number,
			offsetY: number,
		}
		offsetX?: number,
		offsetY?: number,
		xMirrored?: boolean,
		yMirrored?: boolean
	}
	opacity?: number,
	zoom?: number
}

export enum ResultStep {
	score = "SCORE",
	uvProtection = "UV_PROTECTION",
	reco = "RECO"
}

export const CONCERN_LEVELS = ['null', 'veryLow', 'low', 'medium', 'high'] as const;

export type ConcernLevel = typeof CONCERN_LEVELS[number];