import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import i18n from './i18nextInit';
import { AppStyled, GlobalStyle } from './styles/basics';
import { CliniqueState, Retailer, StepView } from './type';
import { Footer } from './views/footer';
import LandscapeComponent from './views/landscape';
import { Topper } from './views/topper';
import ViewDispatcher from './views/viewDispatcher';
// import SephoraApp from './SephoraApp'
import { A3CameraContextProvider } from 'a3camera';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import * as Actiontypes from './constantes/actionTypes';
import { generateSessionID, parseLanguagesJSON } from './constantes/utilitaries';

function App() {
	const [isLandscape, setLandscape] = useState<boolean>(false);
	const step = useSelector((state: CliniqueState) => state.session.stepView);
	const dispatch = useDispatch();
	const market = useSelector((state: CliniqueState) => state.session.market);

	useEffect(() => {
		setLandscape(window.innerWidth > window.innerHeight);

		window.addEventListener('resize', () => {
			setLandscape(window.innerWidth > window.innerHeight);
		});

		let url = new URL(window.location.href);
		if (url.searchParams.get('retailer') === 'sephora') {
			dispatch({ type: Actiontypes.UPDATE_RETAILER, value: Retailer.sephora });
		} else if (url.searchParams.get('retailer') === 'ms') {
			dispatch({ type: Actiontypes.UPDATE_RETAILER, value: Retailer.MS });
		} else if (url.searchParams.get('retailer')) {
			dispatch({ type: Actiontypes.UPDATE_RETAILER, value: Retailer.other });
		}

		if (url.searchParams.get('microsite')) {
			dispatch({ type: Actiontypes.SET_MICROSITE, value: true });
		}

		let _market = url.searchParams.get('market');
		if (_market) {
			switch (_market) {
				case 'EMEA': // Europe -> Fr
					_market = 'FR';
					break;
				case 'EAEU':
					_market = 'RU';
					break;
				case 'UK': // England
					_market = 'GB';
					break;
				case 'LATAM': // South America (latin) -> Brazil
					_market = 'BR';
					break;
				case 'ASIA':
					_market = 'CN';
					break;
				case 'PAC': // APAC -> Australia
					_market = 'AU';
					break;
				case 'ES':
				case 'SP':
					_market = 'ES';
					break;
				case 'NA':
					_market = 'US';
					break;
				default: //North America and Default
					break;
			}
			dispatch({ type: Actiontypes.SET_MARKET, value: url.searchParams.get('market') });
		} else {
			dispatch({ type: Actiontypes.SET_MARKET, value: 'US' });
		}

		if (url.searchParams.get('parseJSON')) {
			parseLanguagesJSON();
		}

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (market) {
			dispatch({ type: Actiontypes.UPDATE_SESSION_ID, value: generateSessionID(market, i18n.language) });
		}
		//eslint-disable-next-line
	}, [market, i18n.language]);

	const handleFooterDisplayed = () => {
		switch (step) {
			case StepView.results:
			case StepView.quests:
				return false;
			default:
				return true;
		}
	};

	return (
		<AppStyled step={step}>
			<A3CameraContextProvider>
				<GlobalStyle />
				{isLandscape ? (
					<LandscapeComponent />
				) : (
					<>
						<Topper step={step} />
						<AnimatePresence>
							<ViewDispatcher />
						</AnimatePresence>
						{handleFooterDisplayed() && <Footer step={step} />}
					</>
				)}
			</A3CameraContextProvider>
		</AppStyled>
	);
}

export default App;
