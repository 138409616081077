import { FaceLandmarker } from '@mediapipe/tasks-vision';
import { ANALYZE_IMAGE_WIDTH } from '../constants';
import { FACE_AREA_POSITIONS } from '../constants/faceAreaPositions';
import { MESH_ANNOTATIONS } from '../constants/meshAnnotations';
import { FaceLandmarksDetectionResults, FaceLandmarksDetectionStatus, MeshArea, MeshAreaRecord } from '../types';
import { DetectionConstraints } from '../types/detectionConstraints';
import { EnabledWarnings } from '../types/enabledWarnings';
import { FaceArea, FaceAreaColor } from '../types/faceAreaColor';
import { FoundationMatch } from '../types/foundationMatch';
import { extractCanvasContext } from './extractCanvasContext';
import foundationMatch, { computeAverageColor, computeMainColor, labDetection } from './foundationMatch';
import { mapValueToRange } from './mapValueToRange';
import { rgb2lab } from './rgb2lab';

export const faceDetection = async (source: HTMLVideoElement | HTMLCanvasElement | null, configDetection: DetectionConstraints, warnings: EnabledWarnings, model?: FaceLandmarker, mirrored?: boolean, ignoreStatus?: boolean): Promise<FaceLandmarksDetectionResults> => {
    let status: FaceLandmarksDetectionStatus = {
        good: true,
        loading: false,
        modelError: false,
        noFace: false,
        badPositionBottom: false,
        badPositionLeft: false,
        badPositionRight: false,
        badPositionTop: false,
        badDistanceFar: false,
        badDistanceClose: false,
        badOrientation: false,
        badBrightness: false,
    };

    if (!model) {
        return {
            status: {
                loading: true,
                modelError: true,
            },
        };
    }

    if (!source) {
        return {
            status: {
                loading: true,
            },
        };
    }

    const ctx = extractCanvasContext(source, mirrored);
    
    if (!ctx) {
        return {
            status: {
                loading: true,
            },
        };
    }
    
    const imageWidth = ctx.canvas.width;
    const imageHeight = ctx.canvas.height;
    const imageRatio = imageWidth / imageHeight;

    const predictions = model.detect(ctx.canvas);

    if (predictions.faceLandmarks.length === 0) {
        return {
            status: {
                noFace: true,
                good: false,
            },
        };
    }

    const keypoints = predictions.faceLandmarks[0].map((keypoint) => [keypoint.x, keypoint.y]);

    const annotations: MeshAreaRecord = Object.entries(MESH_ANNOTATIONS).reduce((acc: MeshAreaRecord, [key, value]) => {
        acc[key as MeshArea] = value.map((pos: number) => Object.values(keypoints[pos]).slice(0, 3));
        return acc;
    }, {} as MeshAreaRecord);

    // On recupere les differentes zones du visage pour verifier la lumiere et pour le foundationMatch
    const faceAreas: FaceArea[] = findFaceAreas(keypoints, ctx, ANALYZE_IMAGE_WIDTH, ANALYZE_IMAGE_WIDTH / imageRatio);


    // Si pas en mode debug on verifie la lumiere
    if (warnings.brightness) {
        const box = keypoints.reduce((acc: { xMin: number, yMin: number, xMax: number, yMax: number, width: number, height: number }, [x, y]) => {
            acc.xMin = Math.min(acc.xMin, x);
            acc.yMin = Math.min(acc.yMin, y);
            acc.xMax = Math.max(acc.xMax, x);
            acc.yMax = Math.max(acc.yMax, y);
            acc.width = Math.abs(acc.xMax - acc.xMin);
            acc.height = Math.abs(acc.yMax - acc.yMin);
            return acc;
        }, { xMin: 1, yMin: 1, xMax: 0, yMax: 0, width: 0, height: 0 });

        const contour = annotations.silhouette;
        const { faceLightArray } = imageToLabArray(ctx.canvas, box, contour) || { faceLightArray: [[]] };

        const lValues = faceAreas.filter((area) => area.name !== 'chin').map((faceArea: FaceArea) => {
            const lab = labDetection(faceArea.data?.data.buffer as Buffer);
            faceArea.lab = lab;
            return lab[0];
        });


        const max = Math.max(...lValues);
        const min = Math.min(...lValues);
        const avg = lValues.reduce((a, b) => a + b, 0) / lValues.length;

        const diff = Math.max(Math.abs(max - avg), Math.abs(min - avg));



        const columnOfHighestL = findColumnOfHighestL(faceLightArray);
        const angle = mapValueToRange(columnOfHighestL.maxCol, 0, faceLightArray[0].length, 45, 135) - 90;

        status = {
            ...status,
            lightSourceOrientation: angle,
            brightnessLevel: avg,
        }

        if ((diff > configDetection.brightnessDiff) || (avg < configDetection.brightnessLevel)) {

            status = {
                ...status,
                badBrightness: true,
                good: false,
            };
        }
    }
    let res: FaceLandmarksDetectionResults = {
        status: positionDetection(annotations, status, configDetection, warnings, imageWidth, imageHeight),
    };

    if (res.status?.good || ignoreStatus) {
        const faceAreaColorList: FaceAreaColor[] = faceAreas.map((faceArea: FaceArea) => {
            return {
                faceArea: faceArea,
                skinColor: foundationMatch(faceArea.data?.data.buffer as Buffer)
            }
        });


        const fmRes: FoundationMatch = {
            faceAreas: faceAreas,
            averageColor: computeAverageColor(faceAreaColorList),
            mainColor: computeMainColor(faceAreaColorList),
        };

        res = {
            ...res,
            foundationMatch: fmRes,
            predictions: {
                annotations: annotations,
                keypoints: keypoints
            },
        };
    }

    return res;
};

const positionDetection = (annotations: any, status: FaceLandmarksDetectionStatus, configDetection: DetectionConstraints, warnings: EnabledWarnings, imgWidth: number, imgHeight: number): FaceLandmarksDetectionStatus => {
    if (annotations.length === 0) {
        status.noFace = true;
        status.good = false;
    }

    if (warnings.distance) {
        const cheeksDistance = getDistance(annotations.leftCheek[0], annotations.rightCheek[0]);

        if (cheeksDistance <= 1 - 1 * configDetection.backDistance) {
            status.badDistanceFar = true;
            status.good = false;
        }

        if (cheeksDistance >= 1 * configDetection.frontDistance) {
            status.badDistanceClose = true;
            status.good = false;
        }
    }

    if (warnings.position) {
        if (annotations.noseBottom[0][0] < (1 / 2 - (1 / 2 * configDetection.positionLeft))) {
            status.badPositionLeft = true;
            status.good = false;
        }

        if (annotations.noseBottom[0][0] > (1 / 2 + (1 / 2 * configDetection.positionLeft))) {
            status.badPositionRight = true;
            status.good = false;
        }

        if ((annotations.noseBottom[0][1]) < (1 - 1 * (configDetection.positionTop))) {
            status.badPositionTop = true;
            status.good = false;
        }

        if ((annotations.noseBottom[0][1]) > (1 * (configDetection.positionBottom))) {
            status.badPositionBottom = true;
            status.good = false;
        }
    }

    if (warnings.rotation) {
        const faceRotationAngle = Math.abs(Math.atan2(annotations.rightEyeUpper0[4][1] - annotations.leftEyeUpper0[4][1], annotations.rightEyeUpper0[4][0] - annotations.leftEyeUpper0[4][0]) * 180 / Math.PI);

        if (Math.abs(180 - faceRotationAngle) > configDetection.rotation) {
            status.badOrientation = true;
            status.good = false;
        }
    }
    return status;
};

export const findFaceAreas = (predictions: number[][], ctx: CanvasRenderingContext2D, ctxWidth: number, ctxHeight: number): FaceArea[] => {
    return Object.entries(FACE_AREA_POSITIONS).map(([faceArea, position]) => {
        const x = Object.values(predictions[position.origin])[0];
        const y = Object.values(predictions[position.origin])[1];
        const x2 = Object.values(predictions[position.abs])[0];
        const y2 = Object.values(predictions[position.abs])[1];
        const x3 = Object.values(predictions[position.ord])[0];
        const y3 = Object.values(predictions[position.ord])[1];

        const width = computeWidth(x, y, x2, y2);
        const height = computeHeight(x, y, x3, y3);
        const data = ctx?.getImageData(x * ctxWidth, y * ctxHeight, width * ctxWidth, height * ctxHeight);

        return {
            name: faceArea,
            x: x,
            y: y,
            width: width,
            height: height,
            data: data,
        };
    });

};

// Compute the height of the rectangle that represents one area of the face
export const computeHeight = (x1: number, y1: number, x2: number, y2: number) => {
    if (y1 < y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

// Compute the width of the rectangle that represents one area of the face
export const computeWidth = (x1: number, y1: number, x2: number, y2: number) => {
    if (x1 < x2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getDistance = (a: Array<any>, b: Array<any>): number => {
    return Math.sqrt((b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]));
};

type ImageLabArray = {
    faceLightArray: number[][],
    bgLightArray: number[][],
}

const imageToLabArray = (image: HTMLCanvasElement, box: any, contour: any): (ImageLabArray | null) => {
    const height = box.height * image.height;
    const width = box.width * image.width;
    const xMin = box.xMin * image.width;
    const yMin = box.yMin * image.height;

    const faceCanvas = document.createElement('canvas');
    faceCanvas.width = image.width;
    faceCanvas.height = image.height;
    const ctx = faceCanvas.getContext('2d', { willReadFrequently: true });
    if (!ctx) return null;

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, faceCanvas.width, faceCanvas.height);


    ctx.beginPath();
    ctx.moveTo(contour[0][0] * image.width, contour[0][1] * image.height);
    for (let i = 1; i < contour.length; i++) {
        ctx.lineTo(contour[i][0] * image.width, contour[i][1] * image.height);
    }
    ctx.closePath();
    ctx.clip();

    // draw clipped face
    ctx.drawImage(image, 0, 0, faceCanvas.width, faceCanvas.height);


    // draw data to a box sized canvas
    const canvas2 = document.createElement('canvas');
    canvas2.width = width;
    canvas2.height = height;
    const ctx2 = canvas2.getContext('2d');
    if (!ctx2) return null;
    ctx2.fillStyle = 'black';
    ctx2.fillRect(0, 0, width, height);

    ctx2.translate(width, 0);
    ctx2.scale(-1, 1);
    ctx2.drawImage(faceCanvas, xMin, yMin, width, height, 0, 0, width, height);

    const data2 = ctx2?.getImageData(0, 0, width, height).data;

    const lightArray = [];

    for (let y = 0; y < canvas2.height; y++) {
        const labRow = [];
        for (let x = 0; x < canvas2.width; x++) {
            const i = (y * canvas2.width + x) * 4;
            const r = data2[i];
            const g = data2[i + 1];
            const b = data2[i + 2];
            const lab = rgb2lab([r, g, b]);
            labRow.push(lab[0]);
        }
        lightArray.push(labRow);
    }

    return {
        faceLightArray: lightArray,
        bgLightArray: [],
    };
}

const findColumnOfHighestL = (lab2DArray: number[][]) => {
    let maxL = -1;
    let maxColumn = 0;

    for (let y = 0; y < lab2DArray.length; y++) {
        for (let x = 0; x < lab2DArray[y].length; x++) {
            const L = lab2DArray[y][x];
            if (L > maxL) {
                maxL = L;
                maxColumn = x;
            }
        }
    }

    return {
        maxCol: maxColumn,
        maxAvgL: maxL,
    };
}
