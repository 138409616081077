export const SWITCH_STEP = "SWITCH_STEP"
export const ANALYSE_PHOTO = 'ANALYSE_PHOTO';
export const ANALYSE_UPDATE = 'ANALYSE_UPDATE';
export const ANALYSE_BIOMETRICS_DONE = 'ANALYSE_BIOMETRICS_DONE';
export const ANALYSE_DONE = 'ANALYSE_DONE';
export const RECOMMENDATION_UPDATE = 'RECOMMENDATION_UPDATE';
export const ANALYSE_ERROR = 'ANALYSE_ERROR';
export const NO_WARNING = "NO_WARNING"
export const REMOVE_OTSTC_WARNINGS = "REMOVE_OTSTC_ERROR"
export const SET_PREDICTIONS = "SET_PREDICTIONS"
export const SET_SKIN_TYPE = "SET_SKIN_TYPE"
export const UPDATE_MODEL = "UPDATE_MODEL"
export const UPDATE_PREDICTIONS = "UPDATE_PREDICTIONS"
export const UPDATE_NASCENT_IMAGE = "UPDATE_NASCENT_IMAGE"
export const SET_MICROSITE = "SET_MICROSITE"
export const SET_MARKET = "SET_MARKET"
export const UPDATE_EFFECTS = "UPDATE_EFFECTS"
export const UPDATE_RETAILER = "UPDATE_RETAILER"
export const UPDATE_RECOMMENDATIONS = "UPDATE_RECOMMENDATIONS"
export const UPDATE_ALL_RECOMMENDATION = 'UPDATE_ALL_RECOMMENDATION'
export const UPDATE_SESSION_ID = "UPDATE_SESSION_ID"
export const UPDATE_MINDSTIX_RECO = "UPDATE_MINDSTIX_RECO"
export const SET_ANALYSIS = "SET_ANALYSIS"
export const SET_UV_PROTECTION = "SET_UV_PROTECTION"