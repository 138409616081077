import { MainButton, MainText, OutOfStockText, Price, ProductName, QuantityButton, QuantityButtonContainer, QuantityText, SPPContainer, SPPImagesContainer, SPPImg, SPPTextsContainer, Tag, TagContainer } from '../styles/spp';
import { Category, CliniqueState, Item, Product, Retailer, Status, StepView } from '../type';

import { Topper } from './topper';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { MainButtonText } from '../styles/basics';
import { useSelector } from 'react-redux';
import { getCliniqueWebsiteForImageUrl, translateAnalyseName } from '../constantes/utilitaries';
import Dropdown from 'react-dropdown';
import '../styles/dropdown.css';
import '../styles/carousel.css';
import i18n from '../i18nextInit';
import { addToBagWithIframeResizer } from '../constantes/iFrameResizerTest';

export const SPPage = (props: { product: Product; displaySPP: Function }) => {
	const { t } = useTranslation();
	const [quantity, setQuantity] = useState<number>(1);
	const isMicrosite = useSelector((state: CliniqueState) => state.session.isMicrosite);
	const [images, setImages] = useState<any[]>([]);
	const retailer = useSelector((state: CliniqueState) => state.session.retailer);
	const sephoraProductsMap = require('../assets/sephora/productsMap.json');
	const MSProductsMap = require('../assets/markAndSpencer/productsMap.json');

	const getDefaultActualItem = () => {
		let item: Item | undefined = props.product.items!.find((item) => item.sku_id === props.product.instoreSku);

		if (item) {
			return item;
		} else {
			return props.product.items![0];
		}
	};

	const [actualItem, setActualItem] = useState<Item>(getDefaultActualItem());
	const market = useSelector((state: CliniqueState) => state.session.market);
	const containerRef = useRef<HTMLDivElement>(null);

	let options: any[] = [];

	if (props.product.items![0]) {
		props.product.items!.forEach((item) => {
			if (props.product.category === 'foundation') {
				options.push({ value: item.sku_id, label: item.shadeName });
			} else if (item.quantity) {
				options.push({ value: item.sku_id, label: item.quantity });
			}
		});
	}

	const handleFirstSrc = (): string => {
		if (!actualItem) {
			return '';
		}

		if (props.product.category === 'foundation') {
			return getCliniqueWebsiteForImageUrl(market) + actualItem.image[0].src;
		} else {
			return getCliniqueWebsiteForImageUrl(market) + actualItem.image[0].src;
		}
	};

	useEffect(() => {
		if (!actualItem) {
			return;
		}

		let _images: any[] = [];
		let key: number = 0;
		_images.push(<SPPImg key={key} style={{ objectFit: 'contain' }} src={handleFirstSrc()} />);
		key++;
		props.product.images.forEach((image: any) => {
			_images.push(<SPPImg key={key} style={{ objectFit: 'cover' }} onError={(e) => (e.currentTarget.src = getCliniqueWebsiteForImageUrl(market) + props.product.items![0].image[0].src)} src={getCliniqueWebsiteForImageUrl(market) + image.src} />);
			key++;
		});
		setImages(_images);
		//eslint-disable-next-line
	}, [actualItem]);

	const changeItem = (e: any) => {
		setActualItem(props.product.items!.find((item) => item.sku_id === e.value)!);
		if (props.product.category === 'foundation') {
			containerRef.current!.scrollTo({ behavior: 'smooth', top: 300 });
		}
	};

	const handleQuantity = (adding?: boolean) => {
		let _quantity = quantity;
		if (adding) {
			_quantity++;
			setQuantity(_quantity);
		} else {
			_quantity--;
			setQuantity(_quantity);
		}
	};

	const handlePrice = (): string => {
		let displayedPrice: string = '';
		if (props.product.items) {
			if (props.product.items[0]) {
				displayedPrice = actualItem ? actualItem.price : props.product.price;
			}
		}
		return displayedPrice;
	};

	const handleShortDescription = (): string => {
		let displayedDesc: string = '';
		if (props.product.texts) {
			if (props.product.texts.find((text: { label: string; content: string }) => text.label === 'short_description')!) {
				displayedDesc = props.product.texts.find((text: { label: string; content: string }) => text.label === 'short_description')!.content;
			}
		}
		return displayedDesc;
	};

	const handleWhoIsFor = (): { label: string; content: string } | null => {
		if (props.product.texts) {
			if (props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHO IT IS FOR')) {
				return {
					label: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHO IT IS FOR')!.label!,
					content: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHO IT IS FOR')!.content!,
				};
			}
		}

		return null;
	};

	const handleWhatItIs = (): { label: string; content: string } | null => {
		if (props.product.texts) {
			if (props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_IS')) {
				return {
					label: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_IS')!.label!,
					content: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_IS')!.content!,
				};
			}
		}
		return null;
	};

	const handleWhatItdoes = (): { label: string; content: string } | null => {
		if (props.product.texts) {
			if (props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_DOES')) {
				return {
					label: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_DOES')!.label!,
					content: props.product.texts.find((text: { label: string; content: string }) => text.label === 'WHAT_IT_DOES')!.content!,
				};
			}
		}
		return null;
	};

	const tagHandler = () => {
		let _tags: any[] = [];
		let key = 0;
		props.product.tags.forEach((concern) => {
			_tags.push(<Tag key={key}>{translateAnalyseName(t, concern as string, market)}</Tag>);
			key++;
		});

		return _tags;
	};

	const setDropDownPlaceHolder = (): string => {
		if (actualItem) {
			if (props.product.category === 'foundation' && actualItem.shadeName) {
				return actualItem.shadeName;
			}
			return actualItem.quantity ? actualItem.quantity : actualItem.sku_id;
		}

		return '';
	};

	useEffect(() => {
		if (props.product.category === 'foundation' && actualItem) {
			if (document.getElementById('SHADE_BLOCK')) {
				document.getElementById('SHADE_BLOCK')!.remove();
			}
			let dropdown = document.getElementsByClassName('Dropdown-root container')[0];
			let shadeBlock = document.createElement('div');
			shadeBlock.style.width = '54px';
			shadeBlock.style.height = '12px';
			shadeBlock.style.position = 'absolute';
			shadeBlock.id = 'SHADE_BLOCK';
			shadeBlock.style.pointerEvents = 'none';
			if (i18n.language === 'HeIl') {
				shadeBlock.style.left = '32px';
			} else {
				shadeBlock.style.right = '32px';
			}
			shadeBlock.style.top = '22px';
			shadeBlock.style.backgroundColor = actualItem.shadeCode!;
			if (dropdown) {
				dropdown.appendChild(shadeBlock);
			}
		}
		//eslint-disable-next-line
	}, [actualItem]);

	const generateShades = () => {
		if (props.product.category === 'foundation') {
			setTimeout(() => {
				let options = document.getElementsByClassName('Dropdown-option');
				for (let i = 0; options.length; i++) {
					let shadeBlock = document.createElement('div');
					shadeBlock.style.width = '54px';
					shadeBlock.style.height = '12px';
					shadeBlock.style.position = 'absolute';
					shadeBlock.id = 'SHADE_BLOCK_' + actualItem.shadeName;
					shadeBlock.style.pointerEvents = 'none';
					if (i18n.language === 'HeIl') {
						shadeBlock.style.left = '32px';
					} else {
						shadeBlock.style.right = '32px';
					}
					shadeBlock.style.marginTop = '4px';
					shadeBlock.style.backgroundColor = props.product.items ? props.product.items[i].shadeCode! : '';
					let option = options[i] as HTMLElement;
					option.appendChild(shadeBlock);
				}
			}, 0);
		}
	};

	const checkOOS = (): boolean => {
		if (!actualItem) {
			return false;
		}

		if (!isMicrosite && actualItem.status === Status.oos) {
			return true;
		}

		return false;
	};

	const checkIfDropdown = (): boolean => {
		if (retailer) {
			return false;
		}

		if (!actualItem) {
			return false;
		}

		if (!actualItem.quantity && props.product.category !== Category.foundation) {
			return false;
		}

		return true;
	};

	const handleEnabled = (): boolean => {
		if (!retailer) {
			return true;
		}

		if (sephoraProductsMap[props.product.prod_base_id]) {
			return true;
		}

		return false;
	};

	const handleCTAText = () => {
		if (retailer === Retailer.sephora) {
			return 'Voir produit';
		}

		if (retailer === Retailer.MS) {
			return 'See products';
		}

		return t('add-to-bag');
	};

	const handleCTAOnClick = (e: any) => {
		if (retailer === Retailer.sephora) {
			if (sephoraProductsMap[props.product.prod_base_id]) {
				window.open(sephoraProductsMap[props.product.prod_base_id]['link'], '_blank');
			}
		} else if (retailer === Retailer.MS) {
			if (MSProductsMap[props.product.prod_base_id]) {
				window.open(MSProductsMap[props.product.prod_base_id]['link'], '_blank');
			}
		} else {
			addToBagWithIframeResizer(props.product.skuID);
		}
		e.stopPropagation();
	};

	return (
		<SPPContainer initial={{ x: '100%' }} ref={containerRef} animate={{ x: 0 }} exit={{ x: '100%' }} key='SPP_KEY' transition={{ type: 'spring', duration: 0.3 }}>
			<Topper inSPP onClose={props.displaySPP} step={StepView.results} />

			<SPPImagesContainer>{images}</SPPImagesContainer>

			{window.innerWidth < 768 && (
				<Carousel dynamicHeight={false} showThumbs={false} showStatus={false}>
					{images}
				</Carousel>
			)}

			<SPPTextsContainer>
				<ProductName isWide dangerouslySetInnerHTML={{ __html: props.product.name }}></ProductName>

				<TagContainer>{tagHandler()}</TagContainer>

				{handleShortDescription() && <MainText dangerouslySetInnerHTML={{ __html: handleShortDescription() }}></MainText>}

				{handleWhoIsFor() && (
					<div style={{ gap: '2px' }}>
						<MainText style={{ fontWeight: 700, margin: 0, textTransform: 'capitalize', width: 'initial' }}>{t('skin-type')}</MainText>
						<MainText dangerouslySetInnerHTML={{ __html: handleWhoIsFor()!.content }}></MainText>
					</div>
				)}

				{!isMicrosite && !retailer && <Price>{handlePrice()}</Price>}

				{window.innerWidth < 768 && checkIfDropdown() && <Dropdown arrowClosed={<span className='arrow' />} arrowOpen={<span className='arrow-open' />} menuClassName='dropdown-menu' className='container' options={options} onFocus={() => generateShades()} placeholder={setDropDownPlaceHolder()} onChange={(e) => changeItem(e)} />}

				{!isMicrosite && actualItem && !retailer && (
					<QuantityButtonContainer>
						<QuantityText>{t('quantity')}</QuantityText>
						<QuantityButton disabled={quantity <= 1} onClick={() => handleQuantity()}>
							-
						</QuantityButton>
						<QuantityButton>{quantity}</QuantityButton>
						<QuantityButton onClick={() => handleQuantity(true)}>+</QuantityButton>
					</QuantityButtonContainer>
				)}

				{checkOOS() && <OutOfStockText>In stock soon</OutOfStockText>}

				<div style={{ display: 'flex', gap: '8px', flexDirection: 'row-reverse' }}>
					{!isMicrosite && actualItem && window.innerWidth >= 768 && <Dropdown arrowClosed={<span className='arrow' />} arrowOpen={<span className='arrow-open' />} menuClassName='dropdown-menu' className='container' options={options} placeholder={actualItem.quantity ? actualItem.quantity : actualItem.sku_id} onChange={(e) => changeItem(e)} />}

					{!isMicrosite && (
						<MainButton
							$enabled={handleEnabled()}
							whileTap={{ scale: 1.05 }}
							onClick={(e) => {
								handleCTAOnClick(e);
							}}>
							<MainButtonText>{handleCTAText()}</MainButtonText>
						</MainButton>
					)}
				</div>

				{handleWhatItIs() && <MainText style={{ margin: '24px 0px' }} dangerouslySetInnerHTML={{ __html: handleWhatItIs()!.content }}></MainText>}

				{handleWhatItdoes() && <MainText style={{ margin: '24px 0px' }} dangerouslySetInnerHTML={{ __html: handleWhatItdoes()!.content }}></MainText>}
			</SPPTextsContainer>
		</SPPContainer>
	);
};
