import { motion } from "framer-motion";
import styled, { createGlobalStyle } from "styled-components";
import HelveticaBold from '../assets/fonts/HelveticaNeueLTPro-Bd.woff'
import HelveticaLight from '../assets/fonts/HelveticaNeue_Light.ttf'
import CliniqueHelveticaNeue from '../assets/fonts/CliniqueHelveticaNeue.woff2';
import CliniqueHelveticaNeueLt from '../assets/fonts/CliniqueHelveticaNeue-Lt.woff2';
import CliniqueHelveticaNeueMd from '../assets/fonts/CliniqueHelveticaNeue-Md.woff2';
import CliniqueHelveticaNeueBd from '../assets/fonts/CliniqueHelveticaNeue-Bd.woff2';
import { StepView } from "../type";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Helvetica Bold';
        src: url(${HelveticaBold}) format("woff");
    }

    @font-face {
        font-family: 'Helvetica Light';
        src: url(${HelveticaLight}) format("truetype");
    }

    @font-face {
        font-family: 'Clinique Helvetica Neue';
        src: url(${CliniqueHelveticaNeue}) format("woff");
        font-weight: 400;
    }

    @font-face {
        font-family: 'Clinique Helvetica Neue';
        src: url(${CliniqueHelveticaNeueLt}) format("woff");
        font-weight: 300;
    }

    @font-face {
        font-family: 'Clinique Helvetica Neue';
        src: url(${CliniqueHelveticaNeueMd}) format("woff");
        font-weight: 500;
    }

    @font-face {
        font-family: 'Clinique Helvetica Neue';
        src: url(${CliniqueHelveticaNeueBd}) format("woff");
        font-weight: 700;
    }

    body{
        color: #1A1A1A;
    }
`

export const AppStyled = styled.div<{ step: StepView }>`
    width: 100vw;
    overflow-x: ${props => props.step === StepView.onBoard ? "" : "hidden"};
    background-color: ${props => props.step !== StepView.onBoard && "white"};
`

export const MainBlackButton = styled(motion.button)`
    appearance: none;
    width: 100%;
    border: none;
    background-color: black;
    padding: 20px;
    color: white;
    font: normal normal 18px/18px Helvetica bold;

    @media (max-width: 768px){
        font-size: 14px;
        padding: 14px;
    }
`

export const MainButtonText = styled.p`
    font: normal normal 400 18px/18px 'Clinique Helvetica Neue';
    margin: 0px;
    padding-top: 4px;

    @media (max-width: 768px){
        font-size: 14px;
    }
`

export const MainWhiteButton = styled(motion.button)`
    appearance: none;
    width: 100%;
    border: 1px solid #E6E6E6;
    background-color: white;
    padding: 22px 0px 18px 0px;
    color: black;
    font: normal normal 18px/18px 'Clinique Helvetica Neue';

    @media (max-width: 768px){
        font-size: 14px;
        padding: 8px;
    }
`

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`