import styled from "styled-components";

export const A3CameraRendering = styled.video<{mirrored: number}>`
    height: 100svh;
    width: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${({mirrored}) => mirrored ? 'scaleX(-1)' : 'scaleX(1)'};
    object-fit: cover;
`;

export const CapturedFrame = styled.canvas<{ show: number, mirrored: number }>`
    height: 100svh;
    width: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${({ mirrored }) => mirrored ? 'scaleX(-1)' : 'scaleX(1)'};
    object-fit: cover;
    filter: blur(8px);
    opacity: ${({show}) => show ? 1 : 0};
    transition: opacity 0.3s;
`;