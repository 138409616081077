import { MeshArea } from 'a3camera';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledComponent } from 'styled-components';
import { LAYERS_PER_CONCERN } from '../constantes/layersPerConcern';
import { filterOTSTCConcernImage, getConcernLevel } from '../constantes/utilitaries';
import { ConcernCanvas } from '../styles/concernImageStyle';
import { ConcernImgSkeleton } from '../styles/resultStyle';
import { CliniqueState } from '../type';

interface ConcernImageProps {
	concern: string;
	image: string;
	as?: StyledComponent<'canvas', any>;
}
const ConcernImage = ({ concern, image, as }: ConcernImageProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const { imageUser, predictions, analyses } = useSelector((state: CliniqueState) => state.session);
	const [loading, setLoading] = useState<boolean>(false);
	// Si le concern est présent dans les indications de layers on utilisera la photo de base + layers.
	// Sinon on utilisera la photo OTSTC
	const useLayers = Object.keys(LAYERS_PER_CONCERN).includes(concern);

	const score = analyses.find((a) => a.name === concern)?.score || 0;

	// User default image with layers
	useEffect(() => {
		if (!useLayers) return;
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		if (!ctx) return;
		const img = new Image();
		img.src = imageUser;
		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.drawImage(img, 0, 0);
			ctx.strokeStyle = '#fff';
			ctx.fillStyle = '#fff';
			ctx.lineWidth = 4;

			let pred = analyses.find((analyse) => analyse.name === concern)?.prediction;
			if (pred) {
				// New part for lines and wrinkles
				pred.forEach((p: any) => {
					const pillarboxWidth = (canvas.height - canvas.width) / 2;

					switch (p.class) {
						case 'acne':
							const { topLeft, bottomRight } = p.normalized_mask.reduce(
								(acc: any, point: any) => {
									if (point[0] < acc.topLeft[0]) acc.topLeft[0] = point[0];
									if (point[0] > acc.bottomRight[0]) acc.bottomRight[0] = point[0];
									if (point[1] < acc.topLeft[1]) acc.topLeft[1] = point[1];
									if (point[1] > acc.bottomRight[1]) acc.bottomRight[1] = point[1];
									return acc;
								},
								{ topLeft: [p.normalized_mask[0][0], p.normalized_mask[0][1]], bottomRight: [p.normalized_mask[0][0], p.normalized_mask[0][1]] },
							);
							const center = [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2];
							const radius = Math.max(bottomRight[0] - topLeft[0], bottomRight[1] - topLeft[1]) * 1.4;
							ctx.beginPath();
							ctx.arc(center[0] * canvas.height - pillarboxWidth, center[1] * canvas.height, radius * canvas.height, 0, 2 * Math.PI);
							ctx.stroke();
							break;
						default:
							ctx.beginPath();
							ctx.moveTo(p.normalized_mask[0][0] * canvas.height - pillarboxWidth, p.normalized_mask[0][1] * canvas.height);
							p.normalized_mask.forEach((m: number[]) => {
								ctx.lineTo(m[0] * canvas.height - pillarboxWidth, m[1] * canvas.height);
							});
							ctx.closePath();
							ctx.stroke();
					}
				});
			}

			// Layers
			if (!predictions) return;

			const silhouette = predictions.annotations.silhouette;
			const { lowestX, highestX } = silhouette.reduce(
				(acc: any, point: any) => {
					const pointX = point[0] * img.width;
					if (pointX < acc.lowestX) acc.lowestX = pointX;
					if (pointX > acc.highestX) acc.highestX = pointX;
					return acc;
				},
				{ lowestX: silhouette[0][0] * img.width, highestX: silhouette[0][0] * img.width },
			);

			const faceWidth = highestX - lowestX;
			const faceWidthRatio = faceWidth / img.width;

			const annotations = predictions.annotations;
			const level = getConcernLevel(score);
			(LAYERS_PER_CONCERN[concern]?.[level] || []).forEach((layer) => {
				const layerImg = new Image();
				layerImg.src = layer.image;

				layerImg.onload = () => {
					const imgWidth = canvas.width;
					const imgHeight = canvas.height;
					const zoom = (layer.zoom || 1) * (1 - (0.5 - faceWidthRatio));
					const x1 = annotations[layer.position.topLeft.name as MeshArea][layer.position.topLeft.index][0] * imgWidth + layer.position.topLeft.offsetX;
					const y1 = annotations[layer.position.topLeft.name as MeshArea][layer.position.topLeft.index][1] * imgHeight + layer.position.topLeft.offsetY;
					const x2 = annotations[layer.position.bottomRight.name as MeshArea][layer.position.bottomRight.index][0] * imgWidth + layer.position.bottomRight.offsetX;
					const y2 = annotations[layer.position.bottomRight.name as MeshArea][layer.position.bottomRight.index][1] * imgHeight + layer.position.bottomRight.offsetY;

					if (layer.opacity) {
						ctx.globalAlpha = layer.opacity;
					}

					const width = (x2 - x1) * zoom;
					const height = (y2 - y1) * zoom;
					const hRatio = width / layerImg.width;
					const vRatio = height / layerImg.height;
					const ratio = Math.max(hRatio, vRatio);
					const centerShift_x = (width - layerImg.width * ratio) / 2 + (layer.position.offsetX || 0);
					const centerShift_y = (height - layerImg.height * ratio) / 2 + (layer.position.offsetY || 0);
					console.log(`drawing ${layer.image} for concern ${concern}`);

					ctx.drawImage(layerImg, x1 + centerShift_x, y1 + centerShift_y, layerImg.width * ratio * (layer.position.xMirrored ? -1 : 1), layerImg.height * ratio * (layer.position.yMirrored ? -1 : 1));
					ctx.globalAlpha = 1;
				};
			});
		};
	}, [analyses, concern, imageUser, predictions, score, useLayers]);

	// User OTSTC image with color filter
	useEffect(() => {
		if (useLayers) return;
		if (new URL(window.location.href).searchParams.get('demky')) return;
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const otstcCanvas = document.createElement('canvas');
		const otstcCtx = otstcCanvas.getContext('2d');
		const sourceCanvas = document.createElement('canvas');
		const sourceCtx = sourceCanvas.getContext('2d');
		if (!ctx || !otstcCtx || !sourceCtx) return;
		setLoading(true);
		const img = new Image();
		const sourceImg = new Image();

		img.crossOrigin = 'anonymous';
		img.src = image;

		img.onload = () => {
			canvas.width = img.width / 4;
			canvas.height = img.height / 4;
			otstcCanvas.width = img.width / 4;
			otstcCanvas.height = img.height / 4;
			sourceCanvas.width = img.width / 4;
			sourceCanvas.height = img.height / 4;

			//getting face bounding box
			if (!predictions) return;
			const silhouette = predictions.annotations.silhouette.map((p: number[]) => [p[0] * img.width, p[1] * img.height]);

			const { lowestX, highestX, lowestY, highestY } = silhouette.reduce(
				(acc: any, point: any) => {
					if (point[0] - 20 < acc.lowestX) acc.lowestX = point[0] - 20;
					if (point[0] + 20 > acc.highestX) acc.highestX = point[0] + 20;
					if (point[1] - 20 < acc.lowestY) acc.lowestY = point[1] - 20;
					if (point[1] + 20 > acc.highestY) acc.highestY = point[1] + 20;
					return acc;
				},
				{ lowestX: silhouette[0][0] - 20, highestX: silhouette[0][0] + 20, lowestY: silhouette[0][1] - 20, highestY: silhouette[0][1] + 20 },
			);
			const box = {
				x: lowestX,
				y: lowestY,
				width: highestX - lowestX,
				height: highestY - lowestY,
			};
			sourceImg.src = imageUser;
			sourceImg.onload = () => {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(sourceImg, 0, 0, img.width / 4, img.height / 4);
				otstcCtx.drawImage(img, box.x, box.y, box.width, box.height, box.x / 4, box.y / 4, box.width / 4, box.height / 4);

				sourceCtx.drawImage(sourceImg, 0, 0, img.width / 4, img.height / 4);
				const otstcImgData = otstcCtx.getImageData(0, 0, img.width / 4, img.height / 4);
				const imgData = sourceCtx.getImageData(0, 0, img.width / 4, img.height / 4);
				filterOTSTCConcernImage(imgData, otstcImgData, '#8a009b', '#ffffff', concern === 'lines_wrinkles' ? 25.5 : 19.5).then((res) => {
					ctx.putImageData(res, 0, 0);
					setLoading(false);
				});
			};
		};
	}, [concern, image, imageUser, predictions, useLayers]);

	return (
		<>
			<ConcernCanvas as={as} ref={canvasRef} style={{ display: loading ? 'none' : 'block' }} />
			{/* <ConcernRender style={{ display: loading ? 'none' : 'block' }} /> */}
			{loading && <ConcernImgSkeleton as={as} />}
		</>
	);
};

export default ConcernImage;
