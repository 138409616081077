import "iframe-resizer/js/iframeResizer.contentWindow"

export const addToBagWithIframeResizer = (skuBaseId) => {

    let postMessage = JSON.stringify({
        "_elc_action": "addtobag",
        "skuBaseId": skuBaseId,
        "INCREMENT": 1,
        "QTY": 1
    })
    console.log("[Through iFrame resizer]")
    console.log(postMessage)

    //window.parent.postMessage(postMessage, "*");
    if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage(postMessage)
    } else {
        console.log("No parentIFrame.")
    }
}