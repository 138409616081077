import { useContext, useEffect, useState } from "react";
import { A3CameraContext } from "../contexts";
import { FaceLandmarker } from "@mediapipe/tasks-vision";
import { loadDetectionModel } from "../utils/loadDetectionModel";

export const useDetectionModel = () => {
    const [detectionModel, setDetectionModel] = useState<FaceLandmarker>();
    const [isModelLoading, setIsModelLoading] = useState<boolean>(false);
    const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);

    const a3cContext = useContext(A3CameraContext);

    useEffect(() => {
        if (!a3cContext) {            
            setIsModelLoading(true);
            loadDetectionModel()
                .then((faceLandmarker) => {                    
                    setIsModelLoaded(true);
                    setDetectionModel(faceLandmarker);
                })
                .finally(() => {
                    setIsModelLoading(false);
                });
            return;
        }        
    }, [a3cContext]);

    const onModelLoaded = (callback: () => void) => {
        callback();
    };

    return {
        detectionModel: a3cContext?.detectionModel || detectionModel,
        isModelLoading: a3cContext?.isModelLoading || isModelLoading,
        isModelLoaded: a3cContext?.isModelLoaded || isModelLoaded,
        onModelLoaded: a3cContext?.onModelLoaded || onModelLoaded,
    };
}