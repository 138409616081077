import { FaceLandmarker } from '@mediapipe/tasks-vision';
import { createContext, useEffect, useState } from 'react';
import { loadDetectionModel } from '../utils/loadDetectionModel';
interface A3CameraContextProps {
	detectionModel: FaceLandmarker | undefined;
	onModelLoaded: (callback: () => void) => void;
	isModelLoading: boolean;
	isModelLoaded: boolean;
}

export const A3CameraContext = createContext<A3CameraContextProps>(undefined!);

interface A3CameraContextProviderProps {
	children: React.ReactNode;
}

export const A3CameraContextProvider = ({ children }: A3CameraContextProviderProps) => {
	const [detectionModel, setDetectionModel] = useState<FaceLandmarker>();
	const [isModelLoading, setIsModelLoading] = useState<boolean>(false);
	const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);

	useEffect(() => {
		setIsModelLoading(true);
		loadDetectionModel()
			.then((faceLandmarker) => {
				setIsModelLoaded(true);
				setDetectionModel(faceLandmarker);
			})
			.finally(() => {
				setIsModelLoading(false);
			});
	}, []);

	const onModelLoaded = (callback: () => void) => {
		callback();
	};

	return <A3CameraContext.Provider value={{ detectionModel, onModelLoaded, isModelLoaded, isModelLoading }}>{children}</A3CameraContext.Provider>;
};
