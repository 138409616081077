import { useEffect, useState } from 'react';
import {
	BottomContainer,
	CameraIcon,
	CameraPanel,
	CameraWarningText,
	CheckingButton,
	CheckingContainer,
	CheckingIcon,
	CheckingRoundButton,
	CheckingText,
	CheckingVerticalCenter,
	Component,
	CountDownNumberBis,
	EyeText,
	Oval,
	OvalContainer,
	StyledA3Camera,
	UserImg,
	WarningContainer,
	WarningErrorBackground,
	WarningErrorContainer,
	Wave,
	WaveContainer,
	WhiteText,
} from '../styles/cameraStyle';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import badIcon from '../assets/icons/CameraClose.svg';
import okIcon from '../assets/icons/CameraOK.svg';
import resetIcon from '../assets/icons/Reset.svg';
import tickIcon from '../assets/icons/Tick.svg';

import EnlargedOvalImg from '../assets/icons/Enlarged_Oval.png';
import OvalImg from '../assets/icons/Oval.png';

import { A3CPhotoSettings, FaceLandmarksDetectionResults, FaceLandmarksDetectionStatus } from 'a3camera';
import { useDispatch, useSelector } from 'react-redux';
import { Flash } from '../A3Camera/A3CameraStyle';
import { getAnalysis } from '../api/demky/analysis';
import skinDiagDispatcher from '../api/skinDiag';
import { CBSKError } from '../api/skinDiag/defaults';
import photoTest from '../assets/Images/faceImage2.jpeg';
import * as actionTypes from '../constantes/actionTypes';
import { sendAnalytics, translateError } from '../constantes/utilitaries';
import i18n from '../i18nextInit';
import { CliniqueState, StepView } from '../type';

const DETECTION_CONSTRAINTS = {
	backDistance: window.innerWidth > 768 ? 0.85 : 0.75,
	frontDistance: window.innerWidth > 768 ? 0.22 : 0.4,
	positionRight: 0.1,
	positionLeft: 0.1,
	positionTop: 0.45,
	positionBottom: 0.7,
	rotation: 4,
	brightnessDiff: 25,
	brightnessLevel: 45,
};

const VIDEO_CONSTRAINTS = {
	width: {
		ideal: 2305,
	},
	height: {
		ideal: 3072,
	},
	aspectRatio: 0.75,
};

const PHOTO_SETTINGS: A3CPhotoSettings = {
	width: 1152,
	height: 1536,
	quality: 0.92,
	type: 'jpeg',
};

const Camera = () => {
	const [height, setHeight] = useState<number>(window.innerHeight);
	const userImg = useSelector((state: CliniqueState) => state.session.imageUser);
	const storeErrors = useSelector((state: CliniqueState) => state.session.error);
	const [isUserImgDisplayed, displayUserImg] = useState<boolean>(false);
	//eslint-disable-next-line
	const [isCheckingDisplayed, displayChecking] = useState<boolean>(false);
	const [isButtonViewDisplayed, displayButtonView] = useState<boolean>(false);
	const sessionID = useSelector((state: CliniqueState) => state.session.sessionID);
	const [isLoadingViewDisplayed, displayLoadingView] = useState<boolean>(false);
	const [isWavesDisplayed, displayWaves] = useState<boolean>(false);
	const [isFlashDisplayed, displayFlash] = useState<boolean>(false);
	const [status, setStatus] = useState<FaceLandmarksDetectionStatus>({
		good: false,
		loading: true,
		modelError: false,
		noFace: false,
		badPositionBottom: false,
		badPositionLeft: false,
		badPositionRight: false,
		badPositionTop: false,
		badDistanceFar: false,
		badDistanceClose: false,
		badOrientation: false,
		badBrightness: false,
	});
	const [showCamera, setShowCamera] = useState<boolean>(true);
	const [showCountdown, setShowCountdown] = useState<boolean>(false);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		window.addEventListener('resize', () => {
			setHeight(window.innerHeight);
		});

		setTimeout(() => {
			displayWaves(true);
		}, 500);
	}, []);

	useEffect(() => {
		if (isCheckingDisplayed) {
			setTimeout(() => {
				displayUserImg(true);
			}, 200);
		} else {
			displayUserImg(false);
		}
	}, [isCheckingDisplayed]);

	// Cas ou il y a une erreur.
	useEffect(() => {
		if (storeErrors.length > 0 && !storeErrors.includes(CBSKError.no_error)) {
			displayWaves(false);
			sendAnalytics('take photo validation', 'error taking | ' + storeErrors[0], sessionID);
			setTimeout(() => {
				dispatch({ type: actionTypes.REMOVE_OTSTC_WARNINGS });
				displayChecking(false);
				displayLoadingView(false);
				displayWaves(true);
				setShowCamera(true);
			}, 3000);
		} else if (storeErrors.length > 0 && storeErrors.includes(CBSKError.no_error)) {
			displayWaves(false);
			displayLoadingView(false);
			setTimeout(() => {
				displayButtonView(true);
			}, 1000);
		}
		//eslint-disable-next-line
	}, [storeErrors]);

	const setWhiteText = (): string => {
		if (storeErrors.length > 0 && !storeErrors.includes(CBSKError.no_error)) {
			return '';
		}

		if (status.loading || isLoadingViewDisplayed) {
			return t('loading');
		}

		if (status.good) {
			return t('hold-still', 'Hold still');
		} else {
			return t('finding-your-face', 'Finding your face...');
		}
	};

	const setEyeLevelValidation = (): boolean => {
		if (status.loading) {
			return false;
		}
		if (status.noFace) {
			return false;
		}
		if (!status.badOrientation && !status.badPositionBottom && !status.badPositionLeft && !status.badPositionRight && !status.badPositionTop) {
			return true;
		} else {
			return false;
		}
	};

	const setDistanceValidation = (): boolean => {
		if (status.loading || status.noFace) {
			return false;
		}

		if (status.badDistanceClose || status.badDistanceFar) {
			return false;
		}

		return true;
	};

	const setDistanceText = (): string => {
		if (status.badDistanceClose) {
			return t('move-farther', 'move away');
		}

		if (status.badDistanceFar) {
			return t('move-closer', 'Move closer');
		}

		return t('move-closer', 'Move closer');
	};

	const setLightningValidation = (): boolean => {
		if (status.loading) {
			return false;
		}

		if (status.badBrightness) {
			return false;
		}
		return true;
	};

	const sendImageToOTSTC = (image: any) => {
		// dispatch({ type: actionTypes.SET_PREDICTIONS, value: status.predictions })
		let isDemkyMode = new URL(window.location.href).searchParams.get('demky');

		if (isDemkyMode) {
			getAnalysis(image, dispatch);
		} else {
			dispatch(skinDiagDispatcher);
		}
	};

	const handlePhotoTaken = (res: FaceLandmarksDetectionResults) => {
		displayFlash(true);
		setTimeout(() => {
			displayFlash(false);
		}, 500);

		dispatch({
			type: actionTypes.SET_PREDICTIONS,
			value: res.predictions,
		});

		if (res.image) {
			displayCheckingView(res.image);
		}
	};

	const displayCheckingView = (image: string) => {
		dispatch({ type: actionTypes.ANALYSE_PHOTO, value: image });
		sendImageToOTSTC(image);
		displayChecking(true);

		// Display Loading view
		displayLoadingView(true);

		setTimeout(() => {
			setShowCamera(false);
		}, 1000);

		sendAnalytics('photo upload or take', 'take photo', sessionID);
	};

	const retakePhoto = () => {
		setShowCamera(true);
		displayChecking(false);
		displayButtonView(false);
		displayWaves(true);
		status!.good = false;
		dispatch({ type: actionTypes.REMOVE_OTSTC_WARNINGS });
	};

	const setPhoto = () => {
		dispatch({ type: actionTypes.SWITCH_STEP, value: StepView.quests });
		sendAnalytics('photo ready confirmation', 'start scan', sessionID);
	};

	const handleStatusChange = (status: FaceLandmarksDetectionStatus) => {
		if (isCheckingDisplayed) {
			setStatus({
				good: true,
			});
		}
		setStatus(status);
	};

	return (
		<Component height={height} initial={{ x: i18n.language === 'HeIl' || i18n.language === 'ArAr' ? '-100%' : '100%' }} animate={{ x: 0 }} exit={{ x: i18n.language === 'HeIl' || i18n.language === 'ArAr' ? '100%' : '-100%' }} transition={{ type: 'spring', duration: 0.5 }}>
			{showCamera && (
				<StyledA3Camera useGrabFrame={true} onPhotoTaken={handlePhotoTaken} detectionInterval={400} countdown={2600} videoConstraints={VIDEO_CONSTRAINTS} detectionConstraints={DETECTION_CONSTRAINTS} photoSettings={PHOTO_SETTINGS} onStatusChange={handleStatusChange} onCountdownStart={() => setShowCountdown(true)} onCountdownStop={() => setShowCountdown(false)} />
			)}

			{!isUserImgDisplayed && (
				<OvalContainer
					$isDisplayed={!setEyeLevelValidation()}
					onClick={() => {
						if (new URLSearchParams(window.location.search).get('debug') === 'true' || new URLSearchParams(window.location.search).get('useResizer') === 'true') {
							displayCheckingView(photoTest);
						}
					}}>
					<EyeText $isGood={status.good}>{t('eye-level')}</EyeText>
					{showCountdown && !isCheckingDisplayed && <OtherAnimatedCoolDown />}
					<Oval $isGood={status.good} src={window.innerWidth < 391 ? EnlargedOvalImg : OvalImg} />
				</OvalContainer>
			)}

			<AnimatePresence>{isUserImgDisplayed && <UserImg initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} src={userImg} />}</AnimatePresence>

			<AnimatePresence>
				{!isUserImgDisplayed && status.loading !== true && !status.good && (
					<CameraPanel initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
						<WarningContainer>
							<CameraIcon isValidate={setEyeLevelValidation()} src={setEyeLevelValidation() ? okIcon : badIcon} />
							<CameraWarningText isValidate={setEyeLevelValidation()}>{t('adjust-eye-level', 'Adjust eye level')}</CameraWarningText>
						</WarningContainer>

						<WarningContainer>
							<CameraIcon isValidate={setDistanceValidation()} src={setDistanceValidation() ? okIcon : badIcon} />
							<CameraWarningText isValidate={setDistanceValidation()}>{setDistanceText()}</CameraWarningText>
						</WarningContainer>

						<WarningContainer>
							<CameraIcon isValidate={setLightningValidation()} src={setLightningValidation() ? okIcon : badIcon} />
							<CameraWarningText isValidate={setLightningValidation()}>{t('adjust-lighting', 'Adjust lightning')}</CameraWarningText>
						</WarningContainer>
					</CameraPanel>
				)}

				{/* {status.good && !isCheckingDisplayed && <FakeAnimatedCoolDown />} */}
			</AnimatePresence>

			<BottomContainer>
				{!storeErrors.includes(CBSKError.no_error) && storeErrors.length > 0 && (
					<WarningErrorBackground>
						<WarningErrorContainer>{storeErrors!.length > 0 && !storeErrors!.includes(CBSKError.no_error) ? translateError(t, storeErrors![0]) : ''}</WarningErrorContainer>
					</WarningErrorBackground>
				)}

				{isUserImgDisplayed && storeErrors!.includes(CBSKError.no_error) && isButtonViewDisplayed && (
					<CheckingContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
						<CheckingVerticalCenter>
							<CheckingRoundButton onClick={() => retakePhoto()}>
								<CheckingButton whileTap={{ scale: 1.05 }}>
									<CheckingIcon src={resetIcon} />
								</CheckingButton>
							</CheckingRoundButton>
							<CheckingText>{t('retake-photo', 'Retake photo')}</CheckingText>
						</CheckingVerticalCenter>

						<CheckingVerticalCenter>
							<CheckingRoundButton onClick={() => setPhoto()}>
								<CheckingButton whileTap={{ scale: 1.05 }}>
									<CheckingIcon src={tickIcon} />
								</CheckingButton>
							</CheckingRoundButton>
							<CheckingText>{t('use-photo', 'Use photo')}</CheckingText>
						</CheckingVerticalCenter>
					</CheckingContainer>
				)}

				<AnimatePresence>
					{isWavesDisplayed && !status.good && (
						<WaveContainer isDisplayed={true} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
							<Wave initial={{ width: '32px', height: '32px', opacity: 0 }} animate={{ width: '120px', height: '120px', opacity: [0, 0.6, 0] }} exit={{ opacity: 0 }} transition={{ repeat: !status.good ? Infinity : 0, duration: 3, times: [0, 0.5, 0.8], type: 'tween' }} />

							<Wave initial={{ width: '32px', height: '32px', opacity: 0 }} animate={{ width: '120px', height: '120px', opacity: [0, 0.6, 0] }} transition={{ repeat: !status.good ? Infinity : 0, duration: 3, times: [0, 0.5, 0.8], type: 'tween', delay: 1 }} />

							<Wave initial={{ width: '32px', height: '32px', opacity: 0 }} animate={{ width: '120px', height: '120px', opacity: [0, 0.6, 0] }} transition={{ repeat: !status.good ? Infinity : 0, duration: 3, times: [0, 0.5, 0.8], type: 'tween', delay: 2 }} />
						</WaveContainer>
					)}
				</AnimatePresence>

				<AnimatePresence>{(!status.good || isLoadingViewDisplayed) && !isButtonViewDisplayed && <WhiteText isDisplayed={true}>{setWhiteText()}</WhiteText>}</AnimatePresence>
					{/* {showCountdown && !isCheckingDisplayed && <OtherAnimatedCoolDown />} */}
				<AnimatePresence>{isFlashDisplayed && <Flash initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.1 }} />}</AnimatePresence>
			</BottomContainer>
		</Component>
	);
};

// eslint-disable-next-line
const OtherAnimatedCoolDown = () => {
	const [number, setNumber] = useState<number | null>(null);
	//const [isTickShown, showTick] = useState<boolean>(true)

	useEffect(() => {
		setTimeout(() => {
			setNumber(3);
			setTimeout(() => {
				setNumber(2);
				setTimeout(() => {
					setNumber(1);
				}, 500);
			}, 500);
		}, 1500);

		//eslint-disable-next-line
	}, []);

	return (
		<>
			<CountDownNumberBis>{number}</CountDownNumberBis>
		</>
	);
};

const CameraComponent = () => {
	return <Camera />;
};

export default CameraComponent;
