import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";

export const loadDetectionModel = async (): Promise<FaceLandmarker> => {
    return FilesetResolver.forVisionTasks('https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm')
        .then(async (vision) => {
            return FaceLandmarker.createFromModelPath(vision, 'https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task').then((faceLandmarker) => {
                return faceLandmarker.setOptions({
                    runningMode: 'IMAGE',
                })
                    .then(() => {
                        return faceLandmarker;
                    })
            });
        })
}
