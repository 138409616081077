import React from 'react';
import styled, { keyframes } from 'styled-components';

interface CheckboxProps {
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

const Input = styled.input`
	height: 24px;
	width: 24px;
	opacity: 0;
	z-index: -1;
	margin: 0;
`;

const Label = styled.label<{ disabled?: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const rotate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Indicator = styled.div`
	width: 12px;
	height: 12px;
	position: absolute;
	border: 1px solid #1a1a1a;

	${Input}:not(:disabled):checked & {
		background: #1a1a1a;
	}

	${Label}:hover & {
	}

	&::after {
		content: '';
		position: absolute;
		display: none;
	}

	${Input}:checked + &::after {
		display: block;
		width: 100%;
		height: 100%;
		background: #1a1a1a;
		animation-name: ${rotate};
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
	}

	&:disabled {
		cursor: not-allowed;
	}
`;

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, disabled = false }) => {
	return (
		<Label disabled={disabled}>
			<Input type='checkbox' disabled={disabled} checked={checked} onChange={onChange} />
			<Indicator />
		</Label>
	);
};

export default Checkbox;
