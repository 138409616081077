
export interface Dictionary<T> {
    [Key: string]: T;
}

export enum CBSKError {

    // Error
    no_photo = "CBSKERR_NO_PHOTO",
    no_face = "CBSKERR_NO_FACE",
    no_biometrics = "CBSKERR_NO_BIOMETRIE",
    no_analyse = "CBSKERR_ANALYSE_BAD_FORMAT",
    // Warnings
    too_far = "CBSKWARN_TOO_FAR",
    too_close = "CBSKWARN_TOO_CLOSE",
    high_light = "CBSKWARN_HIGHT_LIGHT",
    low_light = "CBSKWARN_LOW_LIGHT",
    too_left = "CBSKWARN_TOO_LEFT",
    too_right = "CBSKWARN_TOO_RIGHT",
    too_up = "CBSKWARN_TOO_UP",
    too_down = "CBSKWARN_TOO_DOWN",
    no_error = "NO_ERROR"
}

export enum AnalyseStep {
    start = "START_QUALITY_ESTIMATION",
    // warningCalculation,
    // estimateBiometrie,
    analysingFace = "WARNING_OK_ANALYSING_FACE",
    biometricsDone = "BIOMETRICS_DONE",
    done = "ANALYSE_DONE"
}

export interface Range {
    min: number
    max: number
}

export enum CBSKEndPoint {
    warnings = "/warnings",
    biometrics = "/biometrics",
    analyze = "/analyze",
    effect = "/effect",
}


export interface SkinDiagApiConfig {
    url: string
    apiKey?: string,
    warnings: Dictionary<Range>
}

export interface Point {
    x: number,
    y: number
}
export interface Biometrics {
    zones: [[[number, number]]]
    contour: [[number, number]]
}
export function defaultCBSKConfig() {
    let lightning: Range = new URLSearchParams(window.location.search).get('debug') ? { min: 0, max: 1.0 } : { min: 0.2, max: 1.0 }
    let offset: Range = { min: 0, max: 1.0 }
    let rotation: Range = { min: 0.4, max: 1.0 }
    let cameraAngle: Range = { min: 0.4, max: 1.0 }
    let config: SkinDiagApiConfig = {
        url: 'https://api-clinique-prod.otstc.net', // https://apiprod.otstc.net/demo
        // apiKey: '42ab5247e529a707d5560124fada1738rSMq1TubKLpSo5xbT1jNyq/yC5z/xgTmnQuAdND8LP0GI2ezIWdnFJKkPgjbeXid',
        apiKey: 'f15ac5dd598e2234f17e12cd8b72535dxhQjYc9+Rm7OeIJ7yn0z1/uo19wnYNRA1RfD47vZ4MHeEULWlUBUTFREYmKkKMNL',
        warnings: {
            Lighting: lightning,
            Offset: offset,
            Rotation: rotation,
            'Camera angle': cameraAngle,
        },
    };

    return config
}

