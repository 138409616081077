import { useRef } from 'react';
import { DateText, ResultH1, Selector, StepBox, StepsContainer, TopperComponent, YourSkinContainer } from '../../styles/newResultsStyle';
import { ResultStep } from '../../type';
import { useTranslation } from 'react-i18next';

interface TopperI {
	resultStep: ResultStep;
	setResultStep: Function;
}

export const ResultTopper = (props: TopperI) => {
	const resultsStep = useRef<HTMLDivElement | null>(null);
	const UVDamageStep = useRef<HTMLDivElement | null>(null);
	const recoStep = useRef<HTMLDivElement | null>(null);
	const date = new Date();
	const displayDate = useRef<string>(
		date.toLocaleDateString('en-US', {
			dateStyle: 'medium',
		}) +
			', ' +
			Intl.DateTimeFormat('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			}).format(date),
	);

	const { t } = useTranslation();

	const handleSelectorLeft = (): number => {
		if (!resultsStep.current) {
			return 0;
		}

		switch (props.resultStep) {
			case ResultStep.score:
				return resultsStep.current!.offsetLeft;
			case ResultStep.uvProtection:
				return UVDamageStep.current!.offsetLeft;
			case ResultStep.reco:
				return recoStep.current!.offsetLeft;
			default:
				return 0;
		}
	};

	const handleSelectorWidth = (): number => {
		if (!resultsStep.current) {
			return 0;
		}

		switch (props.resultStep) {
			case ResultStep.score:
				return resultsStep.current!.clientWidth;
			case ResultStep.uvProtection:
				return UVDamageStep.current!.clientWidth;
			case ResultStep.reco:
				return recoStep.current!.clientWidth;
			default:
				return 0;
		}
	};

	return (
		<TopperComponent>
			<DateText>{displayDate.current}</DateText>

			<YourSkinContainer>
				<ResultH1 style={{ font: "normal normal 300 36px/36px 'Clinique Helvetica Neue'" }}>{t('skin-today')}</ResultH1>
			</YourSkinContainer>

			<StepsContainer>
				<Selector style={{ left: handleSelectorLeft() + 'px', width: handleSelectorWidth() + 'px' }} />
				<StepBox onClick={() => props.setResultStep(ResultStep.score)} ref={resultsStep} $isSelected={props.resultStep === ResultStep.score}>
					{t('results')}
				</StepBox>

				<StepBox onClick={() => props.setResultStep(ResultStep.uvProtection)} ref={UVDamageStep} $isSelected={props.resultStep === ResultStep.uvProtection}>
					{t('uv-damage')}
				</StepBox>

				<StepBox onClick={() => props.setResultStep(ResultStep.reco)} ref={recoStep} $isSelected={props.resultStep === ResultStep.reco}>
					{t('recommendation')}
				</StepBox>
			</StepsContainer>
		</TopperComponent>
	);
};
