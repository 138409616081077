import { useEffect, useRef, useState } from "react"
import { Component } from "../../styles/newResultsStyle"
import { CliniqueState, ConcernName, Product, Recommendation, ResultStep } from "../../type"
import { ResultTopper } from "./topper"
import { Score } from "./scores"
import { UVDamages } from "./uvDamages"
import { PopUpHealthySkin } from "./detailedSkin"
import { AnimatePresence } from "framer-motion"
import { ScoreTab } from "./scoresTab"
import { UVDamagesTab } from "./uvDamagesTab"
import { RecoView } from "./reco"
import { useDispatch, useSelector } from "react-redux"
import { getProductsId } from "../../api/mindstix/products"
import { getELCInfosProducts } from "../../api/elc/elcProducts"
import * as actionTypes from '../../constantes/actionTypes'
import { sendAnalytics, sendAnalyticsComplete, sendAnalyticsPageView, sendProductsAnalytics, sendQuestAnalytics, sendRecommendationAnalytics } from "../../constantes/utilitaries"


const Results = () => {
    const [resultStep, setResultStep] = useState<ResultStep>(ResultStep.score)
    const [isPopUpDisplayed, displayPopUp] = useState<boolean>(false)
    const [forcedAnalysisName, setForcedAnalysisName] = useState<ConcernName | undefined>()
    const resultsFlag = useRef<boolean>(false)
    const analyticsFlag = useRef<boolean>(false)

    const { recommendations, market, analyses, skinType, uvProtection, isMicrosite, MindStixReco, sessionID, imageUser } = useSelector((state: CliniqueState) => state.session);
    const dispatch = useDispatch()
    // const analyticsFlag = useRef<boolean>(false)

    useEffect(() => {
        document.body.style.backgroundColor = "rgb(250,250,250)"
    }, [])

    useEffect(() => {
        if (forcedAnalysisName) {
            setResultStep(ResultStep.reco)
        }
    }, [forcedAnalysisName])

    useEffect(() => {
        sendRecommendationAnalytics(analyses, sessionID)
        sendAnalyticsComplete(market, sessionID)
        sendQuestAnalytics(skinType, uvProtection, sessionID)
        sendAnalyticsPageView(4, market, sessionID)
        sendAnalytics("get recommendation", "click", sessionID)

        // Update le store avec les 8 concerns.
        if (recommendations.length < 9) {
            analyses.forEach((analyse) => {
                let mainProducts: Product[] = []
                let otherProducts: Product[] = []

                getProductsId(market, analyse.name as ConcernName, analyse.score, skinType, dispatch)
                    .then(res => {
                        getELCInfosProducts(res.mainProducts, market)
                            .then(results => mainProducts = results)
                            .then(() => {
                                getELCInfosProducts(res.otherProducts, market)
                                    .then(results => otherProducts = results)
                                    .then(() => {
                                        getELCInfosProducts(res.uvProducts, market)
                                            .then(resultSteps => {
                                                let _recommendation: Recommendation = {
                                                    concern: analyse.name as ConcernName,
                                                    mainProducts: mainProducts,
                                                    otherProducts: otherProducts,
                                                    uvProducts: resultSteps
                                                }
                                                dispatch({ type: actionTypes.UPDATE_RECOMMENDATIONS, value: _recommendation })
                                            })
                                    }
                                    )
                            })
                    })
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMicrosite && MindStixReco && !resultsFlag.current) {
            console.warn("[SENDING RESULT AS JAVASCRIPT EVENT:")
            let event = {
                messageType: "Loyalty Event",
                sessionID: sessionID,
                concerns: analyses,
                images: {
                    user: imageUser
                },
                questions: [
                    {
                        question: [skinType]
                    },
                    {
                        question2: [uvProtection]
                    }
                ],
                recommendation: {
                    concern: analyses[0].name,
                    products: MindStixReco
                }
            }
            console.warn(event)
            resultsFlag.current = true
            window.parent.postMessage(event, "*")
        }
        //eslint-disable-next-line
    }, [MindStixReco])

    useEffect(() => {
        if (recommendations.length === 8) {
            if (!analyticsFlag.current) {
                recommendations.forEach((reco) => {
                    sendProductsAnalytics(reco, sessionID)
                });
                analyticsFlag.current = true
            }
        }
    }, [recommendations, sessionID])

    return (
        <>
            <ResultTopper resultStep={resultStep} setResultStep={setResultStep} />
            <Component $resultStep={resultStep}>

                {window.innerWidth <= 768 ? <Score setForcedConcern={setForcedAnalysisName} resultStep={resultStep} openPopUp={displayPopUp} />
                    : <ScoreTab setForcedConcern={setForcedAnalysisName} resultStep={resultStep} openPopUp={displayPopUp} />}

                {window.innerWidth <= 768 ? <UVDamages resultStep={resultStep} setResultStep={setResultStep} /> : <UVDamagesTab resultStep={resultStep} />}

                <RecoView forcedConcern={forcedAnalysisName} resultStep={resultStep} />
            </Component>

            <AnimatePresence>
                {isPopUpDisplayed && <PopUpHealthySkin onClose={displayPopUp} />}
            </AnimatePresence>
        </>
    )
}

export const ResultsComp = () => {
    return <Results />
}

export default ResultsComp