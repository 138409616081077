import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import i18n from '../i18nextInit';

export const Component = styled(motion.div)`
	flex-direction: column;
	display: flex;
	position: absolute;
	background-color: white;
	width: calc(100vw - 32px);
	height: auto;
	min-height: 70%;
	z-index: 100;
	box-sizing: border-box;

	@media (max-width: 768px) {
		gap: 16px;
		padding: 56px 26px;
		border-radius: 10px;
	}
`;

export const ComponentBackground = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	top: 0px;
`;

export const ImgsContainer = styled.div`
	flex: 4 4 100%;
	display: flex;
	gap: 8px;
	max-height: 380px;

	@media (max-width: 768px) {
		flex: initial;
		gap: 6px;
		height: 140px;
	}
`;

export const HyperRealImg = styled.div<{$url: string}>`
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-image: url(${props => props.$url});
	background-size: 150%;
    background-position-x: center;
    background-repeat: no-repeat;
`;

export const ItemTest = styled.div`
	flex: 1;
	background-color: blue;
`;

export const HyperrealContainer = styled.div`
	flex: 1;
`;

export const TextContainer = styled.div`
	padding: 24px 12px 24px;
	display: flex;
	gap: 16px;
	flex-direction: column;

	@media (max-width: 768px) {
		gap: 12px;
		padding: 0px;
	}
`;

export const HyperRealTitle = styled.p`
	font: normal normal 700 24px/110% 'Clinique Helvetica Neue';
	color: black;
	margin: 0px;

	@media (max-width: 768px) {
		color: #1a1a1a;
		font: normal normal 300 30px/30px 'Clinique Helvetica Neue';
	}
`;

export const HyperRealText = styled.p`
	font: normal normal 700 18px/100% 'Clinique Helvetica Neue';
	color: black;
	margin: 0px;

	@media (max-width: 768px) {
		font: normal normal 400 16px/24px 'Clinique Helvetica Neue';
	}
`;

export const HyperRealVideo = styled.video`
	width: 100%;
	position: relative;
`;
const handlingLeft = (): string => {
	if (i18n.language === 'HeIl') {
		return '';
	}

	return '16px';
};

const handlingRight = (): string => {
	if (i18n.language === 'HeIl') {
		return '16px';
	}

	return '';
};

export const BackButton = styled(motion.button)<{ enabled: boolean }>`
	background-color: black;
	padding: 12px 16px;
	color: white;
	position: absolute;
	opacity: ${(props) => (props.enabled ? 1 : 0)};
	transition: all 0.3s;
	pointer-events: ${(props) => (props.enabled ? 'all' : 'none')};
	top: 16px;
	left: ${(props) => handlingLeft()};
	right: ${(props) => handlingRight()};
	border: none;
	font: normal normal 700 18px/12px 'Clinique Helvetica Neue';
	z-index: 5;
`;

const handlingRightCross = (isBig?: boolean): string => {
	if (i18n.language === 'HeIl') {
		return '';
	}

	return isBig ? '-18px' : '20px';
};

const handlingLeftCross = (isBig?: boolean): string => {
	if (i18n.language === 'HeIl' && !isBig) {
		return isBig ? '-18px' : '20px';
	}

	if (i18n.language === 'HeIl' && isBig) {
		return '-18px';
	}

	return '';
};

export const CrossButton = styled(motion.img)<{ big?: boolean }>`
	width: 22px;
	position: absolute;
	top: ${(props) => (props.big ? '-42px' : '20px')};
	right: ${(props) => handlingRightCross(props.big)};
	left: ${(props) => handlingLeftCross(props.big)};

	@media (max-width: 768px) {
		width: 20px;
		top: 16px;
		right: 18px;
		filter: brightness(0);
	}
`;

export const HyperRealVideoContainer = styled(motion.div)`
	position: absolute;
	display: flex;
	justify-content: center;
	width: 101%;
	height: auto;
	top: 0px;
	left: 0px;
`;

export const EyesButton = styled(motion.button)`
	position: absolute;
	opacity: 0.3;
	width: 48px;
	height: 48px;
	left: 18%;
	top: 35%;
	border-radius: 30px;
	background-color: transparent;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ForeHeadButton = styled(motion.button)`
	position: absolute;
	opacity: 0.3;
	width: 48px;
	height: 48px;
	left: 45%;
	top: 22%;
	border-radius: 30px;
	background-color: transparent;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NoseButton = styled(motion.button)`
	position: absolute;
	opacity: 0.3;
	width: 48px;
	height: 48px;
	left: 36%;
	top: 47%;
	border-radius: 30px;
	background-color: transparent;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const SndRound = styled.div<{ small?: boolean }>`
	position: absolute;
	border-radius: 30px;
	background-color: transparent;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: ${(props) => (props.small ? 0.9 : 0.6)};
	width: ${(props) => (props.small ? '16px' : '32px')};
	height: ${(props) => (props.small ? '16px' : '32px')};
`;

export const WaitingScreen = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 100;
	position: fixed;
`;

export const Shadow = styled.div`
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.6;
	position: absolute;
`;

export const TapToZoom = styled.p`
	position: absolute;
	font: normal normal 400 10px/16px 'Clinique Helvetica Neue';
	color: white;
	top: -30px;
	width: 140px;
`;

export const EmptyBar = styled(motion.div)`
	position: absolute;
	width: 85%;
	display: flex;
	background-color: lightgray;
	height: 12px;
	bottom: 108px;
`;

export const loadingBar = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%
    }
`;

export const Bar = styled.div<{ short?: boolean }>`
	position: relative;
	width: 5%;
	background-color: rgb(173, 292, 183);
	height: 100%;
	animation: 3s linear ${loadingBar} infinite;
`;

export const TapToSelect = styled.p`
	color: #666666;
	font: normal normal 400 14px/18px 'Clinique Helvetica Neue';
	padding: 0px;
	margin: 0px;
	text-align: center;
`;
