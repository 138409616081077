import { FastAverageColor } from 'fast-average-color';
import { FaceAreaColor, SkinColor } from '../types/faceAreaColor';
import { rgb2lab } from './rgb2lab';

/*
    Find the average color of the face area and return its rgb, lab, ita values and skin color type in function of Fitzpatrick scale.
*/
const foundationMatch = (buffer: Buffer): SkinColor => {
    const skinColor: SkinColor = {
        rgb: [0, 0, 0],
        lab: [0, 0, 0],
        ita: 0,
        fitzpatrick: 0,
    };
    const fac = new FastAverageColor();
    const arr = new Uint8Array(buffer);

    [skinColor.rgb[0], skinColor.rgb[1], skinColor.rgb[2]] = fac.getColorFromArray4(arr);
    [skinColor.lab[0], skinColor.lab[1], skinColor.lab[2]] = rgb2lab(skinColor.rgb);
    skinColor.ita = (Math.atan((skinColor.lab[0] - 50) / skinColor.lab[2]) * 180) / Math.PI;
    skinColor.fitzpatrick = getSkinColor(skinColor.ita);
    return skinColor;
};

export const labDetection = (buffer: Buffer): number[] => {
    const arr = new Uint8Array(buffer);
    const fac = new FastAverageColor();
    const rgb = fac.getColorFromArray4(arr);
    const lab = rgb2lab(rgb);
    return lab;
};

// Return Fitzpatrick type skin color in function of ita
const getSkinColor = (ita: number): number => {
    if (ita >= 55) {
        return 1;
    } else if (ita >= 41) {
        return 2;
    } else if (ita >= 28) {
        return 3;
    } else if (ita >= 10) {
        return 4;
    } else if (ita >= -30) {
        return 5;
    } else {
        return 6;
    }
};

export const computeAverageColor = (faceAreas: FaceAreaColor[]): SkinColor => {
    const itas = faceAreas.map((faceArea: FaceAreaColor) => {
        return faceArea.skinColor.ita;
    });
    const average = itas.reduce((a, b) => a + b, 0) / itas.length;
    const nearest = itas.reduce((a, b) => (Math.abs(a - average) < Math.abs(b - average) ? a : b));
    return faceAreas.filter((faceArea: FaceAreaColor) => faceArea.skinColor.ita === nearest)[0].skinColor;
};

/* 
    Retrieve the most present skin color among the different areas of the face
    Take the most present color skin (in function of number of areas with this color)
    and take the SkinColor where ita is the nearest of the average
*/
export const computeMainColor = (faceAreas: FaceAreaColor[]): SkinColor => {
    const colors = [1, 2, 3, 4, 5, 6];
    const faceAreasColors = faceAreas.map((faceArea: FaceAreaColor) => {
        return faceArea.skinColor.fitzpatrick;
    });
    const counts: number[] = [0, 0, 0, 0, 0, 0];

    faceAreasColors.forEach((color) => {
        counts[colors.indexOf(color)]++;
    });

    const mainSkinColor =
        colors[
        counts.indexOf(
            counts.reduce((a: number, b: number) => {
                return a > b ? a : b;
            }),
        )
        ];

    const faceAreasMainSkinColor: FaceAreaColor[] = faceAreas.filter((faceArea: FaceAreaColor) => faceArea.skinColor.fitzpatrick === mainSkinColor);
    return computeAverageColor(faceAreasMainSkinColor);
};

/* 
    Retrieve the second most present skin color among the different areas of the face
    Take the most second present color skin (in function of number of areas with this color)
    and take the SkinColor where ita is the nearest of the average
*/
export const computeSecondaryColor = (faceAreas: FaceAreaColor[]): SkinColor => {
    const colors = [1, 2, 3, 4, 5, 6];
    const faceAreasColors = faceAreas.map((faceArea: FaceAreaColor) => {
        return faceArea.skinColor.fitzpatrick;
    });
    const counts: number[] = [0, 0, 0, 0, 0, 0];

    faceAreasColors.forEach((color) => {
        counts[colors.indexOf(color)]++;
    });

    const mainSkinColor =
        colors[
        counts.slice().indexOf(
            counts.sort((a: number, b: number) => {
                return b - a;
            })[1],
        )
        ];
    const faceAreasMainSkinColor: FaceAreaColor[] = faceAreas.filter((faceArea: FaceAreaColor) => faceArea.skinColor.fitzpatrick === mainSkinColor);
    return computeAverageColor(faceAreasMainSkinColor);
};

export default foundationMatch;