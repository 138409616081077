import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from '../../i18nextInit';
import { BlackButton, DayNightContainer, NameAndPriceSkeletonContainer, ProductImg, ProductSkeletonImg, ProductSkeletonTitle } from '../../styles/recoStyle';
import { CliniqueState, Product, Retailer } from '../../type';
import { PriceAndQuantityContainer, ProductCellContainer, ResultH25, ResultH28, WhiteButton } from '../../styles/newResultsStyle';
import { handlePrice, handleQuantity, handleShortDescription, handleSrc, handleStepText } from './utils';
// import { addToBagWithIframeResizer } from '../constantes/iFrameResizerTest'

import dayLogo from '../../assets/icons/sunny.svg';
import nightLogo from '../../assets/icons/mode_night.svg';

interface ProductCellProps {
	product: Product;
	mainProduct?: boolean;
	routineStep: number | null;
	onClick?: Function;
	hasBeforeAfter?: boolean;
	setBeforeAfterProduct?: Function;
	displayHyperReal?: Function;
}

export const ProductCell = (props: ProductCellProps) => {
	const { retailer, effects, isMicrosite } = useSelector((state: CliniqueState) => state.session);
	const sephoraProductsMap = require('../../assets/sephora/productsMap.json');
	const MSProductsMap = require('../../assets/markAndSpencer/productsMap.json');
	const FORBIDDEN_HYPERREAL_MARKET = ['JP'];

	const market = useSelector((state: CliniqueState) => state.session.market);

	const { t } = useTranslation();

	const checkIfEffect = (): boolean => {
		if (i18n.language === 'JaJp') {
			return false;
		}

		if (effects.find((effect: any) => effect.sku === props.product.prod_base_id)) {
			return true;
		}

		return false;
	};

	const handleEnabled = (): boolean => {
		if (!retailer) {
			return true;
		}

		if (sephoraProductsMap[props.product.prod_base_id] && retailer === Retailer.sephora) {
			return true;
		}

		if (MSProductsMap[props.product.prod_base_id] && retailer === Retailer.MS) {
			return true;
		}

		return false;
	};

	const handleCellClick = (e: any) => {
		if (retailer === Retailer.sephora) {
			if (sephoraProductsMap[props.product.prod_base_id]) {
				window.open(sephoraProductsMap[props.product.prod_base_id]['link'], '_blank');
				e.stopPropagation();
			}
		}

		if (retailer === Retailer.MS) {
			if (MSProductsMap[props.product.prod_base_id]) {
				window.open(MSProductsMap[props.product.prod_base_id]['link'], '_blank');
				e.stopPropagation();
			}
		}
	};

	const handleCTAText = () => {
		if (retailer === Retailer.sephora) {
			return 'Voir produit';
		}

		if (retailer === Retailer.MS) {
			return 'See product';
		}

		return t('shop-now');
	};

	const handleDayNightDisplay = (checkingDay: boolean) => {
		if (!props.product.dayNightValue) {
			return false;
		}

		if (props.product.dayNightValue === 'both') {
			return true;
		}

		if (checkingDay) {
			if (props.product.dayNightValue === 'day') return true;
		} else {
			if (props.product.dayNightValue === 'night') return true;
		}
	}

	
	return (
		<ProductCellContainer onClick={() => props.onClick!(props.product)}>
			<ProductImg $main={props.mainProduct} src={handleSrc(props.product, market)} />

			<DayNightContainer>
				{handleDayNightDisplay(false) && <img alt='nightlogo' src={nightLogo} style={{ width: '20px' }} />}
				{handleDayNightDisplay(true) && <img alt='daylogo' src={dayLogo} style={{ width: '20px' }} />}
			</DayNightContainer>

			{/* {<TagContainer inRecoPage>
                {<TagContainer inRecoPage>
                    {props.product?.tags.map((tag, index) => (
                        <TagDiv key={"TAG_" + index}>{translateAnalyseName(t, tag as string)}</TagDiv>
                    ))}
                </TagContainer>}
            </TagContainer>} */}

			{props.routineStep !== null && <ResultH28 style={{ color: '#666666', lineHeight: '18px', marginBottom: '8px' }}>{handleStepText(t, props.routineStep)}</ResultH28>}

			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingBottom: '8px', gap: '8px' }}>
				<ResultH25 style={{ color: '#1A1A1A' }} dangerouslySetInnerHTML={{ __html: props.product.name }}></ResultH25>

				{props.mainProduct && <ResultH28 style={{ color: '#666666', lineHeight: '18px', margin: '8px 0px 8px' }} dangerouslySetInnerHTML={{ __html: handleShortDescription(props.product) }}></ResultH28>}

				<PriceAndQuantityContainer>
					{!isMicrosite && !retailer && <ResultH28 style={{ fontWeight: 700, width: 'initial' }}>{handlePrice(props.product)}</ResultH28>}

					{!isMicrosite && !retailer && <ResultH28 style={{ fontWeight: 400, color: '#666666', width: 'initial' }}>{handleQuantity(props.product)}</ResultH28>}
				</PriceAndQuantityContainer>
			</div>

			{checkIfEffect() && !isMicrosite && props.product.items![0] && (
				<WhiteButton
					style={{ position: 'relative', minHeight: '42px', marginBottom: '8px', color: '#1A1A1A', font: "normal normal 400 14px/18px 'Clinique Helvetica Neue'" }}
					whileTap={{ scale: 1.05 }}
					onClick={(e) => {
						props.setBeforeAfterProduct!(props.product);
						e.stopPropagation();
					}}>
					{t('see-on-your-skin')}
				</WhiteButton>
			)}

			{checkIfEffect() && isMicrosite && props.product.items![0] && (
				<BlackButton
					enabled={true}
					style={{ position: 'relative', minHeight: '42px', marginBottom: '12px' }}
					whileTap={{ scale: 1.05 }}
					onClick={(e) => {
						props.setBeforeAfterProduct!(props.product);
						e.stopPropagation();
					}}>
					{t('see-on-your-skin')}
				</BlackButton>
			)}

			{props.displayHyperReal && !isMicrosite && !FORBIDDEN_HYPERREAL_MARKET.includes(market) && (
				<WhiteButton
					style={{ position: 'relative', minHeight: '42px', marginBottom: '8px', color: '#1A1A1A', font: "normal normal 400 14px/18px 'Clinique Helvetica Neue'" }}
					whileTap={{ scale: 1.05 }}
					onClick={(e) => {
						props.displayHyperReal!(true);
						e.stopPropagation();
					}}>
					{t('see-clinical-results')}
				</WhiteButton>
			)}

			{props.displayHyperReal && isMicrosite && !FORBIDDEN_HYPERREAL_MARKET.includes(market) && (
				<BlackButton
					enabled={true}
					style={{ position: 'relative', minHeight: '42px', marginBottom: '12px' }}
					whileTap={{ scale: 1.05 }}
					onClick={(e) => {
						props.displayHyperReal!(true);
						e.stopPropagation();
					}}>
					{t('see-clinical-results')}
				</BlackButton>
			)}

			{!isMicrosite && (
				<BlackButton
					style={{ position: 'relative' }}
					enabled={handleEnabled()}
					whileTap={{ scale: 1.05 }}
					onClick={(e) => {
						handleCellClick(e);
					}}>
					{handleCTAText()}
				</BlackButton>
			)}
		</ProductCellContainer>
	);
};

interface ProductCellSkeletonProps {
	isSmall?: boolean;
}

export const ProductSkeletonCell = (props: ProductCellSkeletonProps) => {
	return (
		<ProductCellContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
			<ProductSkeletonImg />

			<NameAndPriceSkeletonContainer isSmall={props.isSmall}>
				<ProductSkeletonTitle isSmall={props.isSmall} />
				<ProductSkeletonTitle isSmall={props.isSmall} />
			</NameAndPriceSkeletonContainer>
		</ProductCellContainer>
	);
};
