import { Controlbutton, ControlsContainer, ResultH1, ResultH25, ResultH28, SubComponent, UVPicture, UVTextContainer, WhiteButton } from '../../styles/newResultsStyle';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import minus from '../../assets/icons/minus.svg';
import plus from '../../assets/icons/plus.svg';
import { CliniqueState, ResultStep } from '../../type';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface UVDamagesI {
	setResultStep: Function;
	resultStep: ResultStep;
}

export const UVDamages = (props: UVDamagesI) => {
	const { nascentImage } = useSelector((state: CliniqueState) => state.session);
	const { t } = useTranslation();

	return (
		<SubComponent style={{ backgroundColor: '#FAFAFA', justifyContent: 'initial' }} $height={'calc(100dvh)'}>
			<div style={{ position: 'relative', maxHeight: window.innerWidth > 768 ? '800px' : 'initial', overflow: 'hidden' }}>
				<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
					{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
						<>
							<Controls />
							<TransformComponent>
								<UVPicture src={nascentImage} />
							</TransformComponent>
						</>
					)}
				</TransformWrapper>
			</div>

			<UVTextContainer>
				<div>
					<ResultH1 style={{ textAlign: 'start' }}>{t('your-uv-damage')}</ResultH1>

					<ResultH25>{t('uvdamage-our-tech')}</ResultH25>
				</div>

				<ResultH25 style={{ color: '#666666' }}>{t('uvdamage-to-help')}</ResultH25>

				<WhiteButton onClick={() => {
					props.setResultStep(ResultStep.reco)
					setTimeout(() => {
						window.scrollTo({top: 700, behavior: "smooth"})
					}, 500);
				}}>
					<ResultH28>{t('uvdamage-seeSPF')}</ResultH28>
				</WhiteButton>
			</UVTextContainer>
		</SubComponent>
	);
};

export const Controls = () => {
	const { zoomIn, zoomOut } = useControls();

	return (
		<ControlsContainer>
			<Controlbutton onClick={() => zoomIn()}>
				<img alt='zoom in' src={plus} />
			</Controlbutton>
			<Controlbutton onClick={() => zoomOut()}>
				<img alt='zoom out' src={minus} />
			</Controlbutton>
		</ControlsContainer>
	);
};
