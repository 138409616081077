import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { isLongLanguage } from "../constantes/utilitaries";
import i18n from "../i18nextInit";
import { Center } from "./basics";
import { A3Camera } from "a3camera";

export const Component = styled(motion.div) <{ height: number }>`
	display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.height}px;
	width: 100%;
    position: fixed;
`

export const StyledA3Camera = styled(A3Camera)`
	left: 50%;
	height: 100vh;
	min-width: 100vw;
	position: absolute;
	/* background-color: #ffffff;
	min-width: 100vw;
	height: 100dvh; */
	/* position: absolute; */
	/* top: 0; */
	/* left: 50%; */
	/* transform: translateX(0%)!important; */
`;

export const CameraContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
`;

export const CameraPanel = styled(motion.div)`
	position: fixed;
	height: 70px;
	width: 100%;
	top: 84px;
	display: flex;
	z-index: 1;
	justify-content: space-evenly;
	align-items: center;

	@media (max-width: 768px){
		top: 64px;
		flex-wrap: wrap;
		row-gap: 12px;
	}
`;

export const WarningContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	min-width: 160px;
`;

export const CameraWarningText = styled.p<{ isValidate?: boolean }>`
	font: normal normal 400 16px/100% 'Clinique Helvetica Neue';
	margin: 0;
	color: white;
	opacity: ${props => props.isValidate ? "0.3" : ""};
	transition: all 0.3s;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);

	@media (max-width: 768px){
		max-width: 120px;
	}
`;

const rotateAnimation = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
	display: inline-block;
	width: 34px;
	height: 34px;

	&:after {
		content: ' ';
		display: block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: 2.5px solid black;
		animation: ${rotateAnimation} 1.2s linear infinite;
	}
`;

export const CameraWarnings = styled.span`
	color: #ffffff;
	text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
	font-size: 1.4em;
	font-weight: 500;
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	top: 90px;
	z-index: 2;
	max-width: 100vw;
	width: 100vw;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 1.2em;
	}
`;

export const Flash = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: white;
	opacity: 0;
`;

export const WhiteText = styled(motion.p) <{ isDisplayed: boolean }>`
	font: normal normal 400 18px/100% 'Clinique Helvetica Neue';
	color: white;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
	opacity: ${props => props.isDisplayed ? 1 : 0};
	transition: 1s;
	position: absolute;
	margin: 0px;

	@media (max-width: 768px){
		font-size: 16px;
	}
`

export const BottomContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	@media (max-width: 768px){
		bottom: 13%;
	}
`

export const CameraIcon = styled.img<{ isValidate: boolean }>`
	opacity: ${props => props.isValidate ? "0.3" : ""};
	transition: all 0.3s;
	width: 24px;
	height: 24px;
`

export const Wave = styled(motion.div)`
	width: 62px;
	height: 62px;
	border: 2px solid white;
	position: absolute;
	transition: opacity 0.5s;
	border-radius: 50%;
`

export const WaveContainer = styled(motion.div) <{ isDisplayed: boolean }>`
	pointer-events: none;
	display: flex;
	opacity: ${props => props.isDisplayed ? 1 : 0};
	transition: all 1s;
	align-items: center;
	justify-content: center;
`

export const CountDownNumber = styled(motion.p)`
	position: absolute;
	color: white;
	margin: 0px;
	font: normal normal 700 100px/100% 'Clinique Helvetica Neue';
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
`

export const CountDownNumberBis = styled(motion.p)`
	position: absolute;
	top: 0px;
	color: white;
	margin: 0px;
	font: normal normal 700 80px/100% 'Clinique Helvetica Neue';
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
`

export const Oval = styled.img<{ $isGood: boolean | undefined }>`
	height: calc(100vh * 4 / 8);
	position: absolute;
	opacity: ${props => props.$isGood ? 0.2 : 1};
	transition: 0.3s;

	@media (min-width: 768px){
		height: calc(115vh * 4 / 8);
	}
`

export const OvalContainer = styled.div<{ $isDisplayed: boolean }>`
	height: calc(100vh * 4 / 8);
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transition: all 0.6s;
	transition-delay: 1.3s;

	@media (max-width: 768px){
		height: calc(115vh * 4 / 8);
	}
`

export const WavesImg = styled.img<{ isDisplayed: boolean }>`
	position: absolute;
	mix-blend-mode: color-dodge;
	opacity: ${props => props.isDisplayed ? 1 : 0};
	transition: all 0.5s;
	width: 180px;
`

export const CheckingContainer = styled(motion.div)`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 270px;

	@media (max-width: 768px){
		width: ${props => isLongLanguage() ? "258px" : "208px"};
	}
`

export const CheckingVerticalCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const CheckingRoundButton = styled(Center)`
	background-color: rgba(255, 255, 255, 0.2);
	margin-bottom: 12px;
	width: 94px;
	height: 94px;
	border-radius: 100%;
	color: white;

	@media (max-width: 768px){
		width: 60px;
		height: 60px;
	}
`

export const CheckingButton = styled(motion.button)`
	background-color: rgba(255, 255, 255, 0.4);
	width: 54px;
	height: 54px;
	border-radius: 100%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;

	@media (max-width: 768px){
		width: 34.5px;
		height: 34.5px;
	}
`

export const CheckingText = styled.p`
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
	font: normal normal 700 18px/100% 'Clinique Helvetica Neue';
	margin: 0px;
	color: white;
	text-align: center;

	@media (max-width: 768px){
		font-size: 14px;
	}
`

export const CheckingIcon = styled.img<{ isBig?: boolean }>`
	width: 32px;

	@media (max-width: 768px){
		width: ${props => props.isBig ? "32px" : "16px"};
	}
`

export const UserImg = styled(motion.img)`
	height: 100dvh;
	width: auto;
	min-width: 100vw;
	object-fit: cover;
	z-index: 5;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
`

export const WarningErrorContainer = styled.div`
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 24px;
	padding: 12px 24px;
	box-sizing: border-box;
	color: black;
	font: normal normal 400 18px/100% 'Clinique Helvetica Neue'; 
`

export const WarningErrorBackground = styled.div`
	background-color: rgba(255, 255, 255, 0.2);
	display: flex;
	box-sizing: border-box;
	padding: 8px;
	border-radius: 25px;
	position: absolute;
`

export const EyeText = styled.p<{ $isGood: boolean | undefined }>`
	font: normal normal 400 11px/11px 'Clinique Helvetica Neue';
    width: 65px;
    text-align: center;
    position: absolute;
	opacity: ${props => props.$isGood ? 0.4 : 1};
	transition: 0.3s;
    color: white;
    text-shadow: 0px 1px 2.5px rgb(0 0 0 / 40%);
    top: 40%;
	font-size: ${props => i18n.language === "HuHu" && "8px"};
	top: ${props => i18n.language === "HuHu" && "41%"};
`

export const TickImg = styled.img`
	width: 32px;
	position: absolute;
`

