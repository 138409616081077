import { AdviceBox, CloseButton, GreyBar, HealthyContainer, HealthyLi, HealthyTextsContainer, PopUpContainer, PopUpTop, ResultH1, ResultH2, ResultH25, ResultH28, Shadow, TopWrapper, VideoHealth, WhiteButton } from '../../styles/newResultsStyle';
import { translateAnalyseName } from '../../constantes/utilitaries';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Controls } from './uvDamages';
import CloseIcon from '../../assets/icons/CloseButton.png';
import { useEffect } from 'react';
import { CliniqueState, ConcernName, SkinAnalyse } from '../../type';
import ConcernImage from '../ConcernImage';
import { ChosenConcernImgSkeletom } from '../../styles/recoStyle';
import { ChosenConcernCanvas } from '../../styles/concernImageStyle';
import { handleVideoConcern } from './utils';
import { useSelector } from 'react-redux';

interface DetailedConcernPageI {
	concern: SkinAnalyse | null;
	isPopUp?: boolean;
	displayPopUp?: Function;
	setForcedConcern?: Function;
}

export const DetailedConcernPage = (props: DetailedConcernPageI) => {
	const { t } = useTranslation();
	const market = useSelector((state: CliniqueState) => state.session.market);

	return (
		<HealthyContainer
			id='HEALTHY_CONTAINER'
			$isPopUp={props.isPopUp}
			style={{ backgroundColor: props.isPopUp ? '#FAFAFA' : 'white' }}
			key={props.concern ? props.concern.name + '_CONTAINER' : 'EMPTY_CONTAINER'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ delay: props.isPopUp ? 0 : 0.3, duration: props.isPopUp ? '' : 0.5 }}>
			{props.concern && window.innerWidth < 768 && (
				<div style={{ position: 'relative' }}>
					<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
						{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
							<>
								<Controls />
								<TransformComponent>{props.concern!.imageURL ? <ConcernImage as={ChosenConcernCanvas} concern={props.concern!.name} image={props.concern!.imageURL} /> : <ChosenConcernImgSkeletom />}</TransformComponent>
							</>
						)}
					</TransformWrapper>
				</div>
			)}

			{!props.isPopUp && <ResultH1 style={{ textAlign: 'center', font: window.innerWidth > 768 ? "normal normal 700 24px/28px 'Clinique Helvetica Neue'" : '' }}>{props.concern ? translateAnalyseName(t, props.concern.name, market) : t('healthy-skin-routine')}</ResultH1>}

			{props.concern && <ResultH25 style={{ textAlign: 'center' }}>{t('we-are-seeing').replace('[X]', translateAnalyseName(t, props.concern.name, market))}</ResultH25>}

			<VideoHealth src={handleVideoConcern(props.concern ? (props.concern.name as ConcernName) : ConcernName.NONE)} playsInline loop autoPlay muted />

			{props.isPopUp && <ResultH2 style={{ textAlign: 'start', fontWeight: 700 }}>{t('healthy-skin-routine')}</ResultH2>}

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('what-it-is')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					<HealthyLi>
						<ResultH25 style={{ color: '#666666' }}>{t((props.concern ? props.concern.name : 'healthy_skin') + '-what-it-is')}</ResultH25>
					</HealthyLi>
				</ul>
			</HealthyTextsContainer>

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('why-it-happens')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					{t(props.concern!.name + '-why-it-happens')
						.split('\n')
						.map((text, i) => {
							if (text) {
								return (
									<HealthyLi key={'why-it-happens_' + i}>
										<ResultH25 style={{ color: '#666666' }}>{text}</ResultH25>
									</HealthyLi>
								);
							} else return null;
						})}
				</ul>
			</HealthyTextsContainer>

			{props.concern!.name !== 'irritation' && (
				<AdviceBox $isPopUp={props.isPopUp}>
					<ResultH25 style={{ fontWeight: 700 }}>{t('did-you-know')}</ResultH25>

					<ResultH28>{t(props.concern!.name + '-did-you-know')}</ResultH28>
				</AdviceBox>
			)}

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('how-clinique-helps')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					{t(props.concern!.name + '-how-clinique-helps')
						.split('\n')
						.map((text, i) => {
							if (text) {
								return (
									<HealthyLi key={'how-clinique-helps_' + i}>
										<ResultH25 style={{ color: '#666666' }}>{text}</ResultH25>
									</HealthyLi>
								);
							} else return null;
						})}
				</ul>
			</HealthyTextsContainer>

			{props.concern!.name === 'irritation' && (
				<HealthyTextsContainer>
					<ResultH25 style={{ fontWeight: 700 }}>{t('other-factor')}</ResultH25>

					<ul style={{ color: '#666666' }}>
						{t(props.concern!.name + '-other-factor')
							.split('\n')
							.map((text, i) => {
								if (text) {
									return (
										<HealthyLi key={'other-factor_' + i}>
											<ResultH25 style={{ color: '#666666' }}>{text}</ResultH25>
										</HealthyLi>
									);
								} else return null;
							})}
					</ul>
				</HealthyTextsContainer>
			)}

			<div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
				{/* {props.concern && <MainBlackButton onClick={() => props.setForcedConcern(props.concern?.name)}>
                    <ResultH28 style={{ color: "white" }}>
                        Concern recommendation
                    </ResultH28>
                </MainBlackButton>} */}

				{props.concern && (
					<WhiteButton onClick={() => props.displayPopUp!(true)} style={{ padding: '12px 16px' }}>
						<ResultH28>{t('healthy_skin-CTA')}</ResultH28>
					</WhiteButton>
				)}
			</div>
		</HealthyContainer>
	);
};

export const DetailedHealthyPage = (props: DetailedConcernPageI) => {
	const { t } = useTranslation();
	const market = useSelector((state: CliniqueState) => state.session.market);

	return (
		<HealthyContainer id='HEALTHY_CONTAINER' $isPopUp={props.isPopUp} style={{ backgroundColor: props.isPopUp ? '#FAFAFA' : 'white' }} layout key={props.concern ? props.concern.name + '_CONTAINER' : 'EMPTY_CONTAINER'}>
			{props.concern && window.innerWidth < 768 && (
				<div style={{ position: 'relative' }}>
					<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
						{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
							<>
								<Controls />
								<TransformComponent>{props.concern!.imageURL ? <ConcernImage as={ChosenConcernCanvas} concern={props.concern!.name} image={props.concern!.imageURL} /> : <ChosenConcernImgSkeletom />}</TransformComponent>
							</>
						)}
					</TransformWrapper>
				</div>
			)}

			{!props.isPopUp && <ResultH1 style={{ textAlign: 'center', font: window.innerWidth > 768 ? "normal normal 700 24px/28px 'Clinique Helvetica Neue'" : '' }}>{props.concern ? translateAnalyseName(t, props.concern.name, market) : t('healthy-skin-routine')}</ResultH1>}

			{props.concern && <ResultH25 style={{ textAlign: 'center' }}>{t('we-are-seeing').replace('[X]', translateAnalyseName(t, props.concern.name, market))}</ResultH25>}

			<VideoHealth src={handleVideoConcern(props.concern ? (props.concern.name as ConcernName) : ConcernName.NONE)} playsInline loop autoPlay muted />

			{props.isPopUp && <ResultH2 style={{ textAlign: 'start', fontWeight: 700 }}>{t('healthy-skin-routine')}</ResultH2>}

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('what-it-is')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					<HealthyLi>
						<ResultH25 style={{ color: '#666666' }}>{t((props.concern ? props.concern.name : 'healthy_skin') + '-what-it-is')}</ResultH25>
					</HealthyLi>
				</ul>
			</HealthyTextsContainer>

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('the-5-components')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					{t('healthy_skin-how-clinique-helps')
						.split('\n')
						.map((text, i) => {
							if (text) {
								return (
									<HealthyLi key={'how_clinique_helps' + i}>
										<ResultH25 style={{ color: '#666666' }}>{text}</ResultH25>
									</HealthyLi>
								);
							} else return null;
						})}
				</ul>
			</HealthyTextsContainer>

			<AdviceBox $isPopUp={props.isPopUp}>
				<ResultH25 style={{ fontWeight: 700 }}>{t('did-you-know')}</ResultH25>

				<ResultH28>{t('healthy_skin-did-you-know')}</ResultH28>
			</AdviceBox>

			<HealthyTextsContainer>
				<ResultH25 style={{ fontWeight: 700 }}>{t('how-to-maintain')}</ResultH25>

				<ul style={{ color: '#666666' }}>
					{t('healthy_skin-why-it-happens')
						.split('\n')
						.map((text, i) => {
							if (text) {
								return (
									<HealthyLi key={'why_it_happens_' + i}>
										<ResultH25 style={{ color: '#666666' }}>{text}</ResultH25>
									</HealthyLi>
								);
							} else return null;
						})}
				</ul>
			</HealthyTextsContainer>

			<div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
				{/* {props.concern && <MainBlackButton onClick={() => props.setForcedConcern(props.concern?.name)}>
                    <ResultH28 style={{ color: "white" }}>
                        Concern recommendation
                    </ResultH28>
                </MainBlackButton>} */}

				{props.concern && (
					<WhiteButton onClick={() => props.displayPopUp!(true)} style={{ padding: '12px 16px' }}>
						<ResultH28>{t('healthy_skin-CTA')}</ResultH28>
					</WhiteButton>
				)}
			</div>
		</HealthyContainer>
	);
};

interface PopUpHealthyI {
	onClose: Function;
}

export const PopUpHealthySkin = (props: PopUpHealthyI) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, []);

	return (
		<>
			<Shadow key={'POP_UP_SHADOW'} initial={{ opacity: 0 }} animate={{ opacity: 0.2 }} exit={{ opacity: 0 }} />

			<PopUpContainer key={'POP_UP_HEALTHY'} initial={{ y: '100%' }} animate={{ y: 0 }} exit={{ y: '100%' }} transition={{ type: 'spring', damping: 18 }}>
				<TopWrapper>
					<GreyBar onClick={() => props.onClose()} />
					<CloseButton onClick={() => props.onClose()} src={CloseIcon}></CloseButton>
					<PopUpTop initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 20 }} transition={{ duration: 0.5 }} />
				</TopWrapper>

				<DetailedHealthyPage isPopUp concern={null} />
			</PopUpContainer>
		</>
	);
};
