import styled from "styled-components";

export const ConcernCanvas = styled.canvas`
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;

    @media (max-width: 768px){
        aspect-ratio: 340/265;
    }
`;

export const ConcernRender = styled.img`
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;

    @media (max-width: 768px){
        aspect-ratio: 340/265;
    }
`;

export const ChosenConcernCanvas = styled.canvas`
    width: 45%;
    height: auto;
    object-fit: cover;
    
    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`;

export const UVConcernCanvas = styled.canvas`
    width: 45%;
    height: auto;
    object-fit: cover;

    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`;