import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import i18n from "../i18nextInit"
import { MainBlackButton } from "./basics";
import { ResultStep } from "../type";

export const shine = keyframes`
    to {
        background-position-x: -200%;
    }
`

const setComponentLeft = (resultStep: ResultStep): string => {

    if (i18n.language === "HeIl" || i18n.language === "ArAr") {
        switch (resultStep) {
            case ResultStep.score:
                return "0%"
            case ResultStep.reco:
                return "200%"
            case ResultStep.uvProtection:
                return "100%"
            default:
                return "0%"
        }
    } else {
        switch (resultStep) {
            case ResultStep.score:
                return "0%"
            case ResultStep.reco:
                return "-200%"
            case ResultStep.uvProtection:
                return "-100%"
            default:
                return "0%"
        }
    }

}

export const Component = styled(motion.div) <{ $resultStep: ResultStep }>`
    width: 300vw;
    display: -webkit-inline-box;
    position: relative;
    overflow-x: hidden;
    left: ${props => setComponentLeft(props.$resultStep)};
    transition: left 0.5s;
`

export const SubComponent = styled(motion.div) <{ $height: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 40px 64px;
    box-sizing: border-box;
    position: relative;
    width: 33.333%;
    height: ${props => props.$height};
    overflow: hidden;
    box-sizing: border-box;
    
    @media (max-width: 768px){
        padding: initial;
    }
`

export const TopperComponent = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 40px;
    margin-top: 80px;
    box-sizing: border-box;

    @media (max-width: 768px){
        padding: 0px 16px;
        justify-content: center;
        margin-top: initial;
    }
`

export const DateText = styled.p`
    font: normal normal 400 12px/16px 'Clinique Helvetica Neue';
    color: #666666;
    width: 50.1%;
    margin: 0px;
    margin-bottom: 4px;

    @media (max-width: 768px){
        width: 100%;
        text-align: center;
    }
`

export const YourSkinContainer = styled.div`
    width: 51%;
    display: flex;

    @media (max-width: 768px){
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const ResultH1 = styled.p`
    font: normal normal 700 36px/36px 'Clinique Helvetica Neue';
    color: black;
    margin: 0px;

    @media (max-width: 768px){
        font: normal normal 700 24px/28px 'Clinique Helvetica Neue';
    }
`

export const ResultH15 = styled.p`
    font: normal normal 700 24px/28px 'Clinique Helvetica Neue';
    color: #1A1A1A;
    margin: 0px;

    @media (max-width: 768px){
        font: normal normal 400 20px/24px 'Clinique Helvetica Neue';
    }
`

export const ResultH2 = styled.p`
    font: normal normal 400 20px/24px 'Clinique Helvetica Neue';
    color: black;
    width: 100%;
    margin: 0px;
    text-align: center;
`

export const ResultH25 = styled.p`
    font: normal normal 400 16px/24px 'Clinique Helvetica Neue';
    color: black;
    width: 100%;
    margin: 0px;
`

export const ResultH28 = styled(ResultH25)`
    font: normal normal 400 14px/18px 'Clinique Helvetica Neue';
    color: #1A1A1A;
    `

export const ResultH3 = styled(motion.p)`
    font: normal normal 400 14px/16px 'Clinique Helvetica Neue';
    color: black;
    width: 100%;
    margin: 0px;
    text-align: center;
`

export const StepsContainer = styled.div`
    display: flex;
    width: 49%;
    position: relative;
    gap: 12px;
    justify-content: center;

    @media (max-width: 768px){
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const StepBox = styled.div<{ $isSelected: boolean }>`
    font: normal normal 400 14px/18px 'Clinique Helvetica Neue';
    padding: 12px;
    color: ${props => props.$isSelected ? "#1A1A1A" : "#999999"};
`

export const Selector = styled.div`
    position: absolute;
    bottom: 0px;
    height: 1px;
    background-color: black;
    width: 60px;
    left: 0px;
    transition: all 0.3s;
`

export const HydrationContainer = styled(motion.div)`
    transition: opacity 0.4s;
    border-radius: 4px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    padding: 0px 16px;
    box-sizing: border-box;

    @media (max-width: 768px){
        margin-bottom: 16px;
    }
`

export const HydrationContainerTab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 60px;
`

export const HydrationBox = styled.div<{ $selected: boolean, $isRight?: boolean }>`
    flex: 1;
    background-color: ${props => props.$selected ? "#ADDDB7" : "white"};
    color: ${props => props.$selected ? "#1A1A1A" : "#CCCCCC"};
    text-align: center;
    padding: 8px;
    font: normal normal 400 12px/12px 'Clinique Helvetica Neue';
    border-radius: ${props => props.$isRight ? "0px 4px 4px 0px" : "4px 0px 0px 4px"};
`

export const ConcernBoxContainer = styled(motion.div) <{ $isAbsolute: boolean }>`
    position: ${props => props.$isAbsolute ? "absolute" : "relative"};
    display: flex;
    top: 0px;
    width: 100%;
    background-color: #FAFAFA;
    z-index: 2;
    flex-direction: column;
    height: 500px;
    padding-top: 12px;

    @media (max-width: 768px){
        height: initial;
        flex: initial;
    }
`

export const ConcernBox = styled(motion.div) <{ $selected: boolean }>`
    width: 100%;
    display: flex;
    flex: 1;
    box-sizing: border-box;
    background-color: ${props => props.$selected ? "black" : "initial"};
    transition: background-color 0.3s;

    @media (max-width: 768px){
        height: 60px;
        flex: initial;
    }
`

export const ConcernBoxTextAndScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
`

export const HamburgerBox = styled(motion.div)`
    width: 60px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    border-left: solid 1px white;
`

export const HamburgerIcon = styled.img<{ $isRotate: boolean }>`
    width: 32px;
    transform: rotate(${props => props.$isRotate ? "180deg" : "0deg"});
    transition: transform 0.3s;
`

export const ScoreBoxEmpty = styled.div`
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #FAFAFA;
    display: flex;
    width: 100%;
    height: 8px;
    gap: 1px;
`

export const Scorebox = styled.div<{ $isDisplayed: boolean }>`
    background-color: ${props => props.$isDisplayed ? "#ADDDB7" : "#FFFFFF"};
    flex: 1 1 20%;
    max-width: 20%;
    height: 8px;
`

export const VideoHealth = styled.video`
    border-radius: 8px;
    width: 100%;
`

export const HealthyContainer = styled(motion.div)<{$isPopUp?: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 24px;
    width: 100%;
    padding: 16px;
    padding-top: 16px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: ${props => props.$isPopUp ? "auto" : "intital"};
    margin-top: auto;
`

export const HealthyTextsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const HealthyLi = styled.li`
    ::marker {
        font-size: 20px;
    }
`

export const AdviceBox = styled.div<{ $isPopUp?: boolean }>`
    background-color: ${props => props.$isPopUp ? "white" : "#FAFAFA"};
    padding: 24px;
    gap: 8px;
    display: flex;
    flex-direction: column;
`

export const UVTextContainer = styled.div`
    padding: 40px 104px;
    box-sizing: border-box;
    display: flex;
    align-items: start;
    width: 100%;
    gap: 40px;

    @media (max-width: 768px){
        padding: 40px 16px 16px 16px;
        flex-direction: column;
        gap: 24px;
    }
`

export const WhiteButton = styled(MainBlackButton)`
    border: solid 1px black;
    background-color: white;
    padding: 8px 16px;
    width: initial;
`

export const UVPicture = styled.img`
    width: 100%;
    touch-action: manipulation;
    object-fit: cover;

    @media (max-width: 768px){
        aspect-ratio: 1.34;
    }
`

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: transparent;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    bottom: 32px;
    left: 32px;
    z-index: 1;
`

export const Controlbutton = styled.button`
    background-color: white;
    color: black;
    width: 32px;
    height: 32px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PopUpContainer = styled(motion.div)`
    position: fixed;
    bottom: 0px;
    background-color: #FAFAFA;
    z-index: 7;
    height: 90svh;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
`

export const PopUpTop = styled(motion.div)`
    position: absolute;
    width: calc(100% - 24px);
    left: 12px;
    height: 20px;
    background: linear-gradient(to bottom, #FAFAFA, transparent);
    z-index: 40;
    top: 38px;
`;

export const TopWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 42px;
    left: 0;
    top: 0;
`;

export const Shadow = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.2;
    z-index: 6;
`

export const CloseButton = styled(motion.img)`
    width: 24px;
    height: 24px;
    top: 14px;
    right: 16px;
    position: absolute;
    z-index: 20;
    opacity: 0.55;
`

export const GreyBar = styled(motion.div)`
    width: 36px;
    height: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #7F7F7F66;
    border-radius: 24px;
    top: 6px;
    z-index: 20;
`

export const ImageAndAnalysisContainer = styled.div`
    display: flex;
`

export const AnalysisContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 210px;
    background-color: #FAFAFA;
`

export const MainProductsComponent = styled.div`
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    padding: 40px 0px 54px;

    @media (max-width: 768px){
        padding: 24px 0px;
    }
`

export const ConcernVerticalScrollContainer = styled.div`
    padding: 8px 40px 32px;
    width: 100%;
    gap: 8px;
    display: flex;
    overflow: auto;
    box-sizing: border-box;

    @media (max-width: 768px){
        padding: 8px 16px 32px;
    }
`

export const RecoConcernBubble = styled.button<{ $isSelected: boolean }>`
    padding: 8px 12px;
    border: ${props => props.$isSelected ? "none" : "solid 1px #CCCCCC"};
    background-color: ${props => props.$isSelected ? "#ADDDB7" : "transparent"};
    color: ${props => props.$isSelected ? "#1A1A1A" : "#999999"};
    font: normal normal 400 12px/16px 'Clinique Helvetica Neue';
    border-radius: 24px;
    flex: 1 0 auto;
    transition: all 0.3s;

    @media (max-width: 768px){
        font-size: 11px;
    }
`

export const MainProductsSlider = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 0px 40px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 32px;

    @media (max-width: 768px){
        padding: 0px 16px;
        padding-bottom: 32px;
    }
`

export const ProductCellContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 0 auto;
    width: 33%;
    max-width: 33%;

    @media (max-width: 768px){
        width: 70%;
        max-width: 70%;
    }
`

export const MainProductImg = styled.img`
    aspect-ratio: 1 / 1;
    background-color: white;
`

export const MainProductSkeletonImg = styled.div`
    aspect-ratio: 1 / 1;
    position: relative;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const ProductSkeletonText = styled.div`
    width: 100%;
    height: 20px;
    background-color: lightgray;
    margin: 8px 0px 0px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const UVprotectionContainer = styled.div`
    background-color: white;
    padding: 40px 104px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px){
        padding: 40px 16px;
    }
`

export const UVprotectionProductCell = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
`

export const UVprotectionProductTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`

export const UVprotectionProductImg = styled.img`
    max-width: 45%;
    aspect-ratio: 1 / 1;
`

export const UVprotectionSkeletonImg = styled.div`
    max-width: 45%;
    min-height: 240px;
    aspect-ratio: 1 / 1;
    background-color: lightgray;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const UVprotectionSkeletonText = styled.div`
    width: 100%;
    height: 24px;
    background-color: lightgray;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const HealthySkinRoutineContainer = styled.div`
    padding: 84px 40px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px){
        padding: 76px 40px;
    }
`

export const HealthySkinText = styled.p`
    font: normal normal 300 30px/30px 'Clinique Helvetica Neue';
    color: black;
    margin: 0px;
    padding: 0px;
    z-index: 1;

    @media (max-width: 768px){
        padding: 16px 16px 0px;
        box-sizing: border-box;
    }
`

export const HealthySkinBG = styled.img`
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    opacity: 0.7;

    @media (max-width: 768px){
        width: initial;
        height: 100%;
    }
`

export const WhyCliniqueContainer = styled.div`
    padding: 48px 40px;
    box-sizing: border-box;
    align-items: start;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    position: relative;
    background: linear-gradient(123.01deg, rgba(0, 0, 0, 0.2) -3.49%, rgba(131, 131, 131, 0.2) 16.32%, rgba(242, 242, 242, 0.2) 32.81%, rgba(212, 212, 212, 0.2) 82.52%);

    @media (max-width: 768px){
        background: initial;
        padding: 0px;
        align-items: initial;
        gap: 12px;
    }
`

export const WhyCliniqueBG = styled.img`
    object-fit: cover;
    width: 80%;
    position: absolute;
    top: -50px;
    right: -100px;
    z-index: 0;

    @media (max-width: 768px){
        position: relative;
        width: 100%;
        top: initial;
        right: initial;
        background: linear-gradient(123.01deg, rgba(0, 0, 0, 0.2) -3.49%, rgba(131, 131, 131, 0.2) 16.32%, rgba(242, 242, 242, 0.2) 32.81%, rgba(212, 212, 212, 0.2) 82.52%);

    }
`

export const PriceAndQuantityContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: end;
    gap: 4px;
`

export const TagDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(232, 243, 228, 1);
    padding: 8px 16px;
    border-radius: 16px;
    font: normal normal 400 14px/100% 'Clinique Helvetica Neue';
    color: black;
`