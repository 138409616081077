import { KoreanContainer, KoreanItem } from '../../styles/onBoardStyle'

interface chinaPrivacyProps {
    isFirstChecked: boolean
    checkFirst: Function
    isScndChecked: boolean
    checkScnd: Function
}

const ChinaPP = (props: chinaPrivacyProps) => {

    return (
        <KoreanContainer style={{ padding: "0px 0px 24px" }}>

            <KoreanItem style={{ marginLeft: "initial" }}>
                <label>
                    当您使用Clinique Clinical Reality时，我们将收集、分析、缓存您的脸部图片及脸部几何信息以为您提供皮肤分析及产品推荐的服务。这些数据有可能根据当地的法律构成个人敏感信息。倩碧将仅在您体验、使用Clinique Clinical Reality的过程中处理您的脸部图片及脸部几何信息，但在结束使用本应用后不会被继续存储。若需要查看更多关于倩碧如何保护您的个人信息，请阅读我们的<a style={{ textDecoration: "underline" }} target={"_blank"} rel="noreferrer" href='https://www.clinique.com.cn/customer-care-privacy-policy'>隐私政策</a>.
                </label>
            </KoreanItem>

            <KoreanItem>
                <input type="checkbox" style={{ marginRight: "12px", marginTop: "3px", minWidth: "16px" }} onClick={() => {
                    props.checkFirst(!props.isFirstChecked)
                    window.scrollTo({ behavior: "smooth", top: 170 }) //300
                }} checked={props.isFirstChecked} />
                <label>
                    勾选同意我们的<a style={{ textDecoration: "underline" }} target={"_blank"} rel="noreferrer" href='https://www.clinique.com.cn/customer-care-privacy-policy'>隐私政策</a>
                </label>
            </KoreanItem>

            <KoreanItem>
                <input type="checkbox" style={{ marginRight: "12px", marginTop: "3px", minWidth: "16px" }} onClick={() => props.checkScnd(!props.isScndChecked)} checked={props.isScndChecked} />
                <label>
                    勾选同意我们处理您的敏感个人信息
                </label>
            </KoreanItem>

        </KoreanContainer>
    )
}

export default ChinaPP