import { filterOTSTCConcernImage } from "../constantes/utilitaries";

export const nascentImageMiddleware = (store: any) => (next: any) => async (action: any) => {
    if (action.type === "UPDATE_NASCENT_IMAGE") {
        const image = action.value;
        const { predictions, imageUser } = store.getState().session;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const otstcCanvas = document.createElement('canvas');
        const otstcCtx = otstcCanvas.getContext('2d');
        const sourceCanvas = document.createElement('canvas');
        const sourceCtx = sourceCanvas.getContext('2d');
        if (!ctx || !otstcCtx || !sourceCtx) return next(action);
        const img = new Image();
        const sourceImg = new Image();

        img.crossOrigin = 'anonymous';
        img.src = image;

        img.onload = async () => {
            canvas.width = img.width / 4;
            canvas.height = img.height / 4;
            otstcCanvas.width = img.width / 4;
            otstcCanvas.height = img.height / 4;
            sourceCanvas.width = img.width / 4;
            sourceCanvas.height = img.height / 4;

            if (!predictions) return next(action);
            const silhouette = predictions.annotations.silhouette.map((p: number[]) => [p[0] * img.width, p[1] * img.height]);

            const { lowestX, highestX, lowestY, highestY } = silhouette.reduce(
                (acc: any, point: any) => {
                    if (point[0] - 20 < acc.lowestX) acc.lowestX = point[0] - 20;
                    if (point[0] + 20 > acc.highestX) acc.highestX = point[0] + 20;
                    if (point[1] - 20 < acc.lowestY) acc.lowestY = point[1] - 20;
                    if (point[1] + 20 > acc.highestY) acc.highestY = point[1] + 20;
                    return acc;
                },
                { lowestX: silhouette[0][0] - 20, highestX: silhouette[0][0] + 20, lowestY: silhouette[0][1] - 20, highestY: silhouette[0][1] + 20 },
            );
            const box = {
                x: lowestX,
                y: lowestY,
                width: highestX - lowestX,
                height: highestY - lowestY,
            };
            sourceImg.src = imageUser;
            sourceImg.onload = async () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(sourceImg, 0, 0, img.width / 4, img.height / 4);
                otstcCtx.drawImage(img, box.x, box.y, box.width, box.height, box.x / 4, box.y / 4, box.width / 4, box.height / 4);

                sourceCtx.drawImage(sourceImg, 0, 0, img.width / 4, img.height / 4);
                const otstcImgData = otstcCtx.getImageData(0, 0, img.width / 4, img.height / 4);
                const imgData = sourceCtx.getImageData(0, 0, img.width / 4, img.height / 4);
                const res = await filterOTSTCConcernImage(imgData, otstcImgData, '#8a009b', '#ffffff', 19.5);
                ctx.putImageData(res, 0, 0);
                const base64 = canvas.toDataURL(`image/${action.value.type}`, action.value.quality);
                next({ ...action, value: base64 });
            };
        }
    } else {
        return next(action);
    }
};
