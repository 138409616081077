import { useDispatch, useSelector } from 'react-redux';
import { ConcernVerticalScrollContainer, HealthySkinBG, HealthySkinRoutineContainer, HealthySkinText, MainProductsComponent, MainProductsSlider, RecoConcernBubble, ResultH15, ResultH25, ResultH28, SubComponent, UVprotectionContainer, WhiteButton, WhyCliniqueBG, WhyCliniqueContainer } from '../../styles/newResultsStyle';
import { Category, CliniqueState, ConcernName, Product, ResultStep, Retailer, UVprotection } from '../../type';
import { castConcernToAnalyticsConcern, getPhilosophyLink, sendAnalytics, translateAnalyseName } from '../../constantes/utilitaries';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ProductCell, ProductSkeletonCell } from './productCell';
import { UVProductCell } from './uvProductCell';
import healthyBG from '../../assets/Images/GreenGlass.jpg';
import drPompa from '../../assets/Images/PompaMD.png';
import { AnimatePresence } from 'framer-motion';
import { SPPage } from '../spp';
import { checkIfHyperReal, handleSkinTypetext } from './utils';
import { getEffectsFromOTSTC } from '../../api/skinDiag/effects';
import BeforeAfterView from '../beforeAfterView';
import HyperrealView from '../hyperrealView';

interface RecoI {
	resultStep: ResultStep;
	forcedConcern?: ConcernName;
}

export const RecoView = (props: RecoI) => {
	const { imageUser, recommendations, market, analyses, skinType, uvProtection, isMicrosite, sessionID, retailer } = useSelector((state: CliniqueState) => state.session);
	const { t } = useTranslation();

	const [heroProducts, setHeroProducts] = useState<Product[]>([]);
	const [otherProducts, setOtherProducts] = useState<Product[]>([]);

	const [uvProduct, setUVProduct] = useState<Product | undefined>();
	const [stepsProduct, setStepsProduct] = useState<Product[]>([]);

	const [isHyperrealDisplayed, displayHyperreal] = useState<boolean>(false);
	const [beforeAfterProduct, setbeforeAfterProduct] = useState<Product | null>(null);
	const [selectedConcern, setSelectedConcern] = useState<string>(props.forcedConcern ? props.forcedConcern : analyses[0].name);
	const [SPPProduct, displaySPP] = useState<null | Product>(null);
	const dispatch = useDispatch();
	const effectsJSON = require('../../assets/Data/Effect.json');

	// Tableau définissant l'ordre des catégories des produits.
	const heroOrderCategories = ['serum', 'id', 'cream'];
	const otherOrderCategories = [Category.makeupRemover, Category.cleanser, Category.toner, Category.wateryLotion, Category.eyecream, Category.dailySpfProtection, Category.foundation, Category];
	const sephoraProductsMap = require('../../assets/sephora/productsMap.json');
	const MSProductsMap = require('../../assets/markAndSpencer/productsMap.json');

	useEffect(() => {
		let actualReco = recommendations.find((recommendation) => recommendation.concern === selectedConcern);

		// cas des retailers
		if (retailer === Retailer.sephora && actualReco) {
			actualReco.mainProducts = actualReco.mainProducts.filter((product) => sephoraProductsMap[product.prod_base_id] !== undefined);
			actualReco.otherProducts = actualReco.otherProducts.filter((product) => sephoraProductsMap[product.prod_base_id] !== undefined);
			actualReco.uvProducts = actualReco.uvProducts.filter((product) => sephoraProductsMap[product.prod_base_id] !== undefined);
		}

		if (retailer === Retailer.MS && actualReco) {
			actualReco.mainProducts = actualReco.mainProducts.filter((product) => MSProductsMap[product.prod_base_id] !== undefined);
			actualReco.otherProducts = actualReco.otherProducts.filter((product) => MSProductsMap[product.prod_base_id] !== undefined);
			actualReco.uvProducts = actualReco.uvProducts.filter((product) => MSProductsMap[product.prod_base_id] !== undefined);
		}

		if (actualReco) {
			setHeroProducts(
				actualReco.mainProducts.sort((a, b) => {
					// Trier par ordre de catégorie personnalisé
					const indexCategorieA = heroOrderCategories.indexOf(a.category!);
					const indexCategorieB = heroOrderCategories.indexOf(b.category!);

					if (indexCategorieA !== -1 && indexCategorieB !== -1) {
						return indexCategorieA - indexCategorieB;
					} else if (indexCategorieA === -1) {
						return 1;
					} else {
						return -1;
					}
				}),
			);

			// Oui je sais c'est dégueulasse mais j'ai pas trouvé ou il faisait un distinct...
			if (actualReco.uvProducts.length === 1) {
				setUVProduct(actualReco.uvProducts[0]);
			} else if (actualReco.uvProducts.length < 4) {
				switch (uvProtection) {
					case UVprotection.builtIn:
					case UVprotection.protectionBase:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection1));
						break;
					case UVprotection.mineral:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection3));
						break;
					case UVprotection.highSPF:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection4));
						break;
					default:
						// setUVProduct(actualReco.uvProducts[3])
						break;
				}
			} else {
				switch (uvProtection) {
					case UVprotection.builtIn:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection1));
						break;
					case UVprotection.protectionBase:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection2));
						break;
					case UVprotection.mineral:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection3));
						break;
					case UVprotection.highSPF:
						setUVProduct(actualReco.uvProducts.find((product) => product.category === Category.dailySpfProtection4));
						break;
					default:
						// setUVProduct(actualReco.uvProducts[3])
						break;
				}
			}

			let _stepsProduct = [];
			let cleanser = actualReco.otherProducts.find((product) => product.category! === Category.cleanser);

			if (cleanser) {
				_stepsProduct.push(cleanser);
			}

			let toner = actualReco.otherProducts.find((product) => product.category! === Category.toner);
			if (toner) {
				_stepsProduct.push(toner);
			}

			let moisturizer3 = actualReco.otherProducts.find((product) => product.category! === Category.moisturizer3);
			if (moisturizer3) {
				_stepsProduct.push(moisturizer3);
			}

			setStepsProduct(_stepsProduct);

			setOtherProducts(
				actualReco.otherProducts
					.filter((product) => product.category !== Category.moisturizer3 && product.category !== Category.cleanser && product.category !== Category.toner && product.category !== Category.dailySpfProtection)
					.sort((a, b) => {
						// Trier par ordre de catégorie personnalisé
						const indexCategorieA = otherOrderCategories.indexOf(a.category!);
						const indexCategorieB = otherOrderCategories.indexOf(b.category!);

						if (indexCategorieA !== -1 && indexCategorieB !== -1) {
							return indexCategorieA - indexCategorieB;
						} else if (indexCategorieA === -1) {
							return 1;
						} else {
							return -1;
						}
					})
					.map((product) => {
						return {
							...product,
							dayNightValue: product.category === Category.foundation ? 'both' : product.dayNightValue,
						};
					}),
			);
		}

		//eslint-disable-next-line
	}, [recommendations, selectedConcern]);

	useEffect(() => {
		let _baseIds: string[] = [];
		heroProducts.forEach((product: Product) => {
			if (Object.keys(effectsJSON).includes(product.prod_base_id)) {
				_baseIds.push(product.prod_base_id);
			}
		});

		fetch(imageUser)
			.then((r) => r.blob())
			.then((blob: any) => {
				getEffectsFromOTSTC(blob, _baseIds, dispatch);
			});
	}, [heroProducts, dispatch, imageUser, effectsJSON]);

	useEffect(() => {
		if (props.forcedConcern) {
			setSelectedConcern(props.forcedConcern);
			window.scrollTo(0, 0);
		}
	}, [props.forcedConcern]);

	useEffect(() => {
		if (selectedConcern) {
			sendAnalytics('solve instead', castConcernToAnalyticsConcern(selectedConcern as ConcernName), sessionID);
			sendAnalytics('concern recognition result', 'solve | ' + castConcernToAnalyticsConcern(selectedConcern as ConcernName), sessionID);
		}
	}, [selectedConcern, sessionID]);

	return (
		<SubComponent $height={props.resultStep === ResultStep.reco ? 'auto' : 'calc(100svh - 92px - 80px)'} style={{ padding: '0px 0px 32px', justifyContent: 'initial' }}>
			<MainProductsComponent>
				<AnimatePresence>{SPPProduct && <SPPage displaySPP={displaySPP} product={SPPProduct} />}</AnimatePresence>
				<AnimatePresence>{beforeAfterProduct && <BeforeAfterView selectProduct={setbeforeAfterProduct} product={beforeAfterProduct} />}</AnimatePresence>
				<AnimatePresence>{isHyperrealDisplayed && <HyperrealView displayHyperReal={displayHyperreal} />}</AnimatePresence>

				<ResultH15 style={{ textAlign: 'center', padding: '0px 16px' }}>{t('recommendation-header')}</ResultH15>

				<ConcernVerticalScrollContainer>
					{analyses.map((analyse: any, index: any) => (
						<RecoConcernBubble key={index} onClick={() => setSelectedConcern(analyse.name)} $isSelected={analyse.name === selectedConcern}>
							{translateAnalyseName(t, analyse.name, market)}
						</RecoConcernBubble>
					))}
				</ConcernVerticalScrollContainer>

				<MainProductsSlider>
					{heroProducts.length === 0 && (
						<>
							<ProductSkeletonCell />
							<ProductSkeletonCell />
						</>
					)}

					{heroProducts.map((product, index) => (
						<ProductCell mainProduct onClick={() => displaySPP(product)} setBeforeAfterProduct={setbeforeAfterProduct} displayHyperReal={checkIfHyperReal(market, product) ? displayHyperreal : undefined} routineStep={null} key={index} product={product} />
					))}
				</MainProductsSlider>
			</MainProductsComponent>

			<UVprotectionContainer>
				<ResultH15 style={{ width: '100%' }}>{t('uv-protection')}</ResultH15>

				<ResultH28 style={{ padding: '4px 0px 24px', color: '#666666' }}>{t('uv-protection-content')}</ResultH28>

				<UVProductCell onClick={displaySPP} product={uvProduct} />
			</UVprotectionContainer>

			<HealthySkinRoutineContainer>
				<HealthySkinBG src={healthyBG} />
				<ResultH28 style={{ zIndex: 1 }}>{handleSkinTypetext(skinType, t)}</ResultH28>

				<HealthySkinText style={{ paddingLeft: '0px' }}>{t('healthy-skin-routine')}.</HealthySkinText>

				<ResultH28 style={{ color: '#666666', zIndex: 1 }}>{t('healthy-skin-content')}</ResultH28>
			</HealthySkinRoutineContainer>

			<MainProductsSlider style={{ margin: '16px 0px 64px' }}>
				{stepsProduct.length === 0 && (
					<>
						<ProductSkeletonCell />
						<ProductSkeletonCell />
					</>
				)}

				{stepsProduct.map((product, index) => (
					<ProductCell key={index} setBeforeAfterProduct={setbeforeAfterProduct} onClick={() => displaySPP(product)} routineStep={index + 1} product={product} />
				))}
			</MainProductsSlider>

			{!isMicrosite && (
				<WhyCliniqueContainer>
					<WhyCliniqueBG src={drPompa} />

					<HealthySkinText>{t('why-clinique')}</HealthySkinText>

					<ResultH25 style={{ maxWidth: window.innerWidth > 768 ? '50%' : 'initial', boxSizing: 'border-box', padding: window.innerWidth < 768 ? '0px 16px' : '' }}>{t('why-clinique-content')}</ResultH25>

					<WhiteButton style={{ color: 'black', backgroundColor: 'transparent', font: "normal normal 400 16px/24px 'Clinique Helvetica Neue'", marginTop: '8px', margin: window.innerWidth < 768 ? '0px 16px' : '' }} onClick={() => window.open(getPhilosophyLink(market), '_blank')}>
						{t('learn-more')}
					</WhiteButton>
				</WhyCliniqueContainer>
			)}

			<ResultH15 style={{ color: '#1A1A1A', fontWeight: 400, margin: '40px 0px 8px', textAlign: 'center' }}>{t('more-picks-for-you')}</ResultH15>

			<MainProductsSlider style={{ margin: '16px 0px 64px' }}>
				{otherProducts.length === 0 && (
					<>
						<ProductSkeletonCell />
						<ProductSkeletonCell />
					</>
				)}

				{otherProducts.map((product, index) => (
					<ProductCell onClick={() => displaySPP(product)} routineStep={null} key={index} product={product} />
				))}
			</MainProductsSlider>
		</SubComponent>
	);
};
