import dehydrationForehead from '../assets/Images/resultLayers/dehydration/forehead.png';
import dehydrationLeftCheek from '../assets/Images/resultLayers/dehydration/left-cheek.png';
import dehydrationRightCheek from '../assets/Images/resultLayers/dehydration/right-cheek.png';
import fatigueHighLeftEye from '../assets/Images/resultLayers/fatigue/high-left-eye.png';
import fatigueHighRightEye from '../assets/Images/resultLayers/fatigue/high-right-eye.png';
import fatigueLowLeftEye from '../assets/Images/resultLayers/fatigue/low-left-eye.png';
import fatigueLowRightEye from '../assets/Images/resultLayers/fatigue/low-right-eye.png';
import fatigueMediumLeftEye from '../assets/Images/resultLayers/fatigue/medium-left-eye.png';
import fatigueMediumRightEye from '../assets/Images/resultLayers/fatigue/medium-right-eye.png';
import lossOfVolumeHighLeftCheek from '../assets/Images/resultLayers/loss-of-volume/high-left-cheek.png';
import lossOfVolumeHighRightCheek from '../assets/Images/resultLayers/loss-of-volume/high-right-cheek.png';
import lossOfVolumeLowLeftCheek from '../assets/Images/resultLayers/loss-of-volume/low-left-cheek.png';
import lossOfVolumeLowRightCheek from '../assets/Images/resultLayers/loss-of-volume/low-right-cheek.png';
import lossOfVolumeMediumLeftCheek from '../assets/Images/resultLayers/loss-of-volume/medium-left-cheek.png';
import lossOfVolumeMediumRightCheek from '../assets/Images/resultLayers/loss-of-volume/medium-right-cheek.png';
import poresUnevenStructureHighCheek from '../assets/Images/resultLayers/pores-uneven-structure/high-cheek.png';
import poresUnevenStructureHighForehead from '../assets/Images/resultLayers/pores-uneven-structure/high-forehead.png';
import poresUnevenStuctureLowCheek from '../assets/Images/resultLayers/pores-uneven-structure/low-cheek.png';
import poresUnvevenStructureLowForehead from '../assets/Images/resultLayers/pores-uneven-structure/low-forehead.png';
import poresUnevenStructureMediumCheek from '../assets/Images/resultLayers/pores-uneven-structure/medium-cheek.png';
import poresUnevenStructureMediumForehead from '../assets/Images/resultLayers/pores-uneven-structure/medium-forehead.png';
import unevenSkinToneHighForehead from '../assets/Images/resultLayers/uneven-skintone/high-forehead.png';
import unevenSkinToneHighLeftCheek from '../assets/Images/resultLayers/uneven-skintone/high-left-cheek.png';
import unevenSkinToneHighRightCheek from '../assets/Images/resultLayers/uneven-skintone/high-right-cheek.png';
import unevenSkinToneLowForehead from '../assets/Images/resultLayers/uneven-skintone/low-forehead.png';
import unevenSkinToneLowLeftCheek from '../assets/Images/resultLayers/uneven-skintone/low-left-cheek.png';
import unevenSkinToneLowRightCheek from '../assets/Images/resultLayers/uneven-skintone/low-right-cheek.png';
import unevenSkinToneMediumForehead from '../assets/Images/resultLayers/uneven-skintone/medium-forehead.png';
import unevenSkinToneMediumLeftCheek from '../assets/Images/resultLayers/uneven-skintone/medium-left-cheek.png';
import unevenSkinToneMediumRightCheek from '../assets/Images/resultLayers/uneven-skintone/medium-right-cheek.png';
import irritationHighRightCheek from '../assets/Images/resultLayers/irritation/high-right-cheek.png';
import irritationHighLeftCheek from '../assets/Images/resultLayers/irritation/high-left-cheek.png';
import irritationLowRightCheek from '../assets/Images/resultLayers/irritation/low-right-cheek.png';
import irritationLowLeftCheek from '../assets/Images/resultLayers/irritation/low-left-cheek.png';
import irritationMediumRightCheek from '../assets/Images/resultLayers/irritation/medium-right-cheek.png';
import irritationMediumLeftCheek from '../assets/Images/resultLayers/irritation/medium-left-cheek.png';
import { Layer } from './../type';

export const LAYERS_PER_CONCERN: { [concern: string]: { [level: string]: Layer[] } } = {
    'blemishes': {
    },
    'uneven_skintone':
    {
        'veryLow': [{
            image: unevenSkinToneLowRightCheek,
            position: {
                topLeft: {
                    name: 'rightEyeLower2',
                    index: 2,
                    offsetX: 0,
                    offsetY: 40
                },
                bottomRight: {
                    name: 'rightEyeLower3',
                    index: 6,
                    offsetX: 0,
                    offsetY: 40
                },
                offsetX: -20,
                offsetY: 30
            },
        }, {
            image: unevenSkinToneLowLeftCheek,
            position: {
                topLeft: {
                    name: 'leftEyeLower2',
                    index: 6,
                    offsetX: 0,
                    offsetY: 35
                },
                bottomRight: {
                    name: 'leftEyeLower3',
                    index: 2,
                    offsetX: 0,
                    offsetY: 35
                },
                offsetX: 20,
                offsetY: 30
            },
        },
        {
            image: unevenSkinToneLowForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -80
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -40
                }
            },
            zoom: 1.2
        },
        ],
        'low': [{
            image: unevenSkinToneLowRightCheek,
            position: {
                topLeft: {
                    name: 'rightEyeLower2',
                    index: 2,
                    offsetX: -10,
                    offsetY: 50
                },
                bottomRight: {
                    name: 'rightEyeLower3',
                    index: 6,
                    offsetX: -10,
                    offsetY: 50
                },
                offsetX: -20,
                offsetY: 30
            },
        }, {
            image: unevenSkinToneLowLeftCheek,
            position: {
                topLeft: {
                    name: 'leftEyeLower2',
                    index: 6,
                    offsetX: 10,
                    offsetY: 50
                },
                bottomRight: {
                    name: 'leftEyeLower3',
                    index: 2,
                    offsetX: 10,
                    offsetY: 50
                },
                offsetX: 20,
                offsetY: 30
            },
        },
        {
            image: unevenSkinToneLowForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -80
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -40
                }
            },
            zoom: 1.2
        },
        ],
        'medium': [{
            image: unevenSkinToneMediumRightCheek,
            position: {
                topLeft: {
                    name: 'rightEyeLower2',
                    index: 2,
                    offsetX: -10,
                    offsetY: 40
                },
                bottomRight: {
                    name: 'rightEyeLower3',
                    index: 6,
                    offsetX: -10,
                    offsetY: 60
                },
                offsetX: -20,
                offsetY: 30
            },
        }, {
            image: unevenSkinToneMediumLeftCheek,
            position: {
                topLeft: {
                    name: 'leftEyeLower2',
                    index: 6,
                    offsetX: 10,
                    offsetY: 55
                },
                bottomRight: {
                    name: 'leftEyeLower3',
                    index: 2,
                    offsetX: 10,
                    offsetY: 55
                },
                offsetX: 20,
                offsetY: 30
            },
        },
        {
            image: unevenSkinToneMediumForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -80
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -40
                }
            },
            zoom: 1.2
        },
        ],
        'high': [{
            image: unevenSkinToneHighRightCheek,
            position: {
                topLeft: {
                    name: 'rightEyeLower2',
                    index: 2,
                    offsetX: -10,
                    offsetY: 35
                },
                bottomRight: {
                    name: 'rightEyeLower3',
                    index: 6,
                    offsetX: -10,
                    offsetY: 60
                },
                offsetX: -20,
                offsetY: 30
            },
        }, {
            image: unevenSkinToneHighLeftCheek,
            position: {
                topLeft: {
                    name: 'leftEyeLower2',
                    index: 6,
                    offsetX: 0,
                    offsetY: 35
                },
                bottomRight: {
                    name: 'leftEyeLower3',
                    index: 2,
                    offsetX: 0,
                    offsetY: 55
                },
                offsetX: 20,
                offsetY: 30
            },
        },
        {
            image: unevenSkinToneHighForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -80
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 5,
                    offsetX: 0,
                    offsetY: -40
                }
            },
            zoom: 1.2
        },
        ],
    },
    'irritation':
    {
        'veryLow': [{
            image: irritationLowRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -50,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 10,
                    offsetY: 30
                }
            },
            zoom: 1.8
        }, {
            image: irritationLowLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 30,
                    offsetY: 30
                }
            },
            zoom: 1.8
        },
        ],
        'low': [{
            image: irritationLowRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -50,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 10,
                    offsetY: 30
                }
            },
            zoom: 1.8
        }, {
            image: irritationLowLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 30,
                    offsetY: 30
                }
            },
            zoom: 1.8
        },
        ],
        'medium': [{
            image: irritationMediumRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -50,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 10,
                    offsetY: 30
                }
            },
            zoom: 1.8
        }, {
            image: irritationMediumLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 30,
                    offsetY: 30
                }
            },
            zoom: 1.8
        },
        ],
        'high': [{
            image: irritationHighRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -50,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 10,
                    offsetY: 30
                },
                offsetX: -20,
            },
            zoom: 1.8
        }, {
            image: irritationHighLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 30,
                    offsetY: 30
                }
            },
            zoom: 1.8
        },
        ],
    },
    'pores_uneven_structure':
    {
        'veryLow': [{
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 15
                },
                offsetY: -20,
                offsetX: -40
            },
            zoom: 2
        }, {
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 15
                },
                offsetY: -20,
                offsetX: -20
            },
            zoom: 2
        },
        {
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 20,
                    offsetX: 0,
                    offsetY: -20
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 16,
                    offsetX: 0,
                    offsetY: 0
                },
                offsetY: 12
            }
        },
        {
            image: poresUnvevenStructureLowForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 15,
                    offsetY: -50
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: -15,
                    offsetY: 0
                },
                offsetY: -20
            }
        },
        ],
        'low': [{
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 15
                },
                offsetY: -20,
                offsetX: -40
            },
            zoom: 2
        }, {
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 15
                },
                offsetY: -20,
                offsetX: -20
            },
            zoom: 2
        },
        {
            image: poresUnevenStuctureLowCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 20,
                    offsetX: 0,
                    offsetY: -20
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 16,
                    offsetX: 0,
                    offsetY: 0
                },
                offsetY: 12
            }
        },
        {
            image: poresUnvevenStructureLowForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 15,
                    offsetY: -50
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: -15,
                    offsetY: 0
                },
                offsetY: -20
            }
        },
        ],
        'medium': [{
            image: poresUnevenStructureMediumCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 25
                },
                offsetY: -20,
                offsetX: -40
            },
            zoom: 2
        }, {
            image: poresUnevenStructureMediumCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -25,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 30,
                    offsetY: 25
                },
                offsetY: -20,
                offsetX: -20
            },
            zoom: 2
        },
        {
            image: poresUnevenStructureMediumCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 20,
                    offsetX: 0,
                    offsetY: -20
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 16,
                    offsetX: 0,
                    offsetY: 0
                },
                offsetY: 12
            }
        },
        {
            image: poresUnevenStructureMediumForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 15,
                    offsetY: -50
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: -15,
                    offsetY: 0
                },
                offsetY: -20
            }
        },
        ],
        'high2': [{
            image: poresUnevenStructureHighCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 40,
                    offsetY: 25
                },
                offsetY: -20,
                offsetX: -40
            },
            zoom: 2
        }, {
            image: poresUnevenStructureHighCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -25,
                    offsetY: -25
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 35,
                    offsetY: 25
                },
                offsetY: -20,
                offsetX: -20
            },
            zoom: 2
        },
        {
            image: poresUnevenStructureHighCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 20,
                    offsetX: 0,
                    offsetY: -20
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 16,
                    offsetX: 0,
                    offsetY: 0
                },
                offsetY: 12
            }
        },
        {
            image: poresUnevenStructureHighForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 15,
                    offsetY: -50
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: -15,
                    offsetY: 0
                },
                offsetY: -20
            }
        },
        ],
    }, 'loss_of_volume':
    {
        'veryLow': [
            {
                image: lossOfVolumeLowRightCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 25,
                        offsetX: 0,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 19,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: -20,
                    offsetY: 10
                }
            },
            {
                image: lossOfVolumeLowLeftCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 11,
                        offsetX: 20,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 17,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: 10,
                    offsetY: 20
                }
            },
        ],
        'low': [
            {
                image: lossOfVolumeLowRightCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 25,
                        offsetX: 0,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 19,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: -20,
                    offsetY: 10
                }
            },
            {
                image: lossOfVolumeLowLeftCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 11,
                        offsetX: 20,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 17,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: 10,
                    offsetY: 20
                }
            },
        ],
        'medium': [
            {
                image: lossOfVolumeMediumRightCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 25,
                        offsetX: -20,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 19,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: -20,
                    offsetY: 10
                }
            },
            {
                image: lossOfVolumeMediumLeftCheek,
                position: {
                    topLeft: {
                        name: 'silhouette',
                        index: 11,
                        offsetX: 20,
                        offsetY: -10
                    },
                    bottomRight: {
                        name: 'silhouette',
                        index: 17,
                        offsetX: 0,
                        offsetY: 30
                    },
                    offsetX: 10,
                    offsetY: 20
                }
            },],
        'high': [{
            image: lossOfVolumeHighRightCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 25,
                    offsetX: -20,
                    offsetY: -10
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 19,
                    offsetX: 0,
                    offsetY: 30
                },
                offsetX: -20,
                offsetY: 10
            }
        },
        {
            image: lossOfVolumeHighLeftCheek,
            position: {
                topLeft: {
                    name: 'silhouette',
                    index: 11,
                    offsetX: 20,
                    offsetY: -10
                },
                bottomRight: {
                    name: 'silhouette',
                    index: 17,
                    offsetX: 0,
                    offsetY: 30
                },
                offsetX: 10,
                offsetY: 20
            }
        },],
    },
    'dehydration':
    {
        'veryLow': [{
            image: dehydrationRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -30,
                offsetY: -10
            },
            opacity: 0.4,
            zoom: 1.5
        },
        {
            image: dehydrationLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -20,
                offsetY: -10
            },
            opacity: 0.4,
            zoom: 1.5
        },
        {
            image: dehydrationForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: -40
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: 20
                }
            },
            opacity: 0.4
        },
        ],
        'low': [{
            image: dehydrationRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -30,
                offsetY: -10
            },
            opacity: 0.4,
            zoom: 1.5
        },
        {
            image: dehydrationLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -20,
                offsetY: -10
            },
            opacity: 0.4,
            zoom: 1.5
        },
        {
            image: dehydrationForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: -40
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: 20
                }
            },
            opacity: 0.4
        },
        ],
        'medium': [{
            image: dehydrationRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -30,
                offsetY: -10
            },
            opacity: 0.7,
            zoom: 1.5
        },
        {
            image: dehydrationLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -20,
                offsetY: -10
            },
            opacity: 0.7,
            zoom: 1.5
        },
        {
            image: dehydrationForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: -40
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: 20
                }
            },
            opacity: 0.7
        },
        ],
        'high': [{
            image: dehydrationRightCheek,
            position: {
                topLeft: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: -30,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'rightCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -30,
                offsetY: -10
            },
            zoom: 1.5
        },
        {
            image: dehydrationLeftCheek,
            position: {
                topLeft: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: -20,
                    offsetY: -30
                },
                bottomRight: {
                    name: 'leftCheek',
                    index: 0,
                    offsetX: 50,
                    offsetY: 50
                },
                offsetX: -20,
                offsetY: -10
            },
            zoom: 1.5
        },
        {
            image: dehydrationForehead,
            position: {
                topLeft: {
                    name: 'rightEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: -40
                },
                bottomRight: {
                    name: 'leftEyebrowUpper',
                    index: 4,
                    offsetX: 0,
                    offsetY: 20
                }
            }
        },
        ],
    },
    'fatigue':
    {
        'veryLow': [
            {
                image: fatigueLowRightEye,
                position: {
                    topLeft: {
                        name: 'rightEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'rightEyeLower2',
                        index: 8,
                        offsetX: -40,
                        offsetY: 70
                    },
                    offsetY: 0,
                },
            },
            {
                image: fatigueLowLeftEye,
                position: {
                    topLeft: {
                        name: 'leftEyeLower2',
                        index: 8,
                        offsetX: 20,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'leftEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
        ],
        'low': [
            {
                image: fatigueLowRightEye,
                position: {
                    topLeft: {
                        name: 'rightEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'rightEyeLower2',
                        index: 8,
                        offsetX: -40,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
            {
                image: fatigueLowLeftEye,
                position: {
                    topLeft: {
                        name: 'leftEyeLower2',
                        index: 8,
                        offsetX: 20,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'leftEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
        ],
        'medium': [
            {
                image: fatigueMediumRightEye,
                position: {
                    topLeft: {
                        name: 'rightEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'rightEyeLower2',
                        index: 8,
                        offsetX: -40,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
            {
                image: fatigueMediumLeftEye,
                position: {
                    topLeft: {
                        name: 'leftEyeLower2',
                        index: 8,
                        offsetX: 20,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'leftEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
        ],
        'high': [
            {
                image: fatigueHighRightEye,
                position: {
                    topLeft: {
                        name: 'rightEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'rightEyeLower2',
                        index: 8,
                        offsetX: -40,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
            {
                image: fatigueHighLeftEye,
                position: {
                    topLeft: {
                        name: 'leftEyeLower2',
                        index: 8,
                        offsetX: 20,
                        offsetY: 50
                    },
                    bottomRight: {
                        name: 'leftEyeLower2',
                        index: 0,
                        offsetX: 0,
                        offsetY: 70
                    },
                    offsetY: 0
                }
            },
        ],
    },
}