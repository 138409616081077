import { AdviceBox, CloseButton, GreyBar, HealthyContainer, HealthyLi, HealthyTextsContainer, PopUpContainer, ResultH1, ResultH2, ResultH25, ResultH28, Shadow, VideoHealth, WhiteButton } from '../../styles/newResultsStyle';
import healthyVid from '../../assets/videos/healthyVideo.mp4';
import { translateAnalyseName } from '../../constantes/utilitaries';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../assets/icons/CloseButton.png';
import { useEffect } from 'react';
import { DetailedConcernPage } from './detailedSkin';
import { useSelector } from 'react-redux';
import { CliniqueState } from '../../type';

interface DetailedConcernPageI {
	concernName: string | null;
	isPopUp?: boolean;
	displayPopUp?: Function;
	setForcedConcern: Function;
}

export const DetailedConcernPageTab = (props: DetailedConcernPageI) => {
	const { t } = useTranslation();
	const market = useSelector((state: CliniqueState) => state.session.market);

	return (
		<HealthyContainer style={{ backgroundColor: props.isPopUp ? '#FAFAFA' : 'white', padding: '40px 100px' }} layout key={props.concernName + '_CONTAINER'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: props.isPopUp ? 0 : 0.3, duration: props.isPopUp ? '' : 0.5 }}>
			<div style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
				{!props.isPopUp && <ResultH1 style={{ textAlign: 'center', font: window.innerWidth > 768 ? "normal normal 700 24px/28px 'Clinique Helvetica Neue'" : '' }}>{props.concernName ? translateAnalyseName(t, props.concernName, market) : 'Healthy Skin'}</ResultH1>}

				{props.concernName && <ResultH25 style={{ textAlign: 'center' }}>We are seeing signs of Hyperpigmentation on your skin</ResultH25>}
			</div>

			<VideoHealth src={healthyVid} playsInline loop autoPlay muted />

			{props.isPopUp && <ResultH2 style={{ textAlign: 'start', fontWeight: 700 }}>{t('healthy-skin-routine')}</ResultH2>}

			<div style={{ display: 'flex', gap: '24px', alignItems: 'start' }}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
					<HealthyTextsContainer>
						<ResultH25 style={{ fontWeight: 700 }}>What it is:</ResultH25>

						<ul style={{ color: '#666666' }}>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Comfortable, Radiant, Smooth, Firm and Even-Toned</ResultH25>
							</HealthyLi>
						</ul>
					</HealthyTextsContainer>

					<HealthyTextsContainer>
						<ResultH25 style={{ fontWeight: 700 }}>The 5 components of healthy skin:</ResultH25>

						<ul style={{ color: '#666666' }}>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Well-hydrated, moisturized skin for surface comfort</ResultH25>
							</HealthyLi>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Even/consistent cell turnover for smooth texture and radiant looking skin</ResultH25>
							</HealthyLi>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Intact moisture barrier for long-term skin comfort and less irritation</ResultH25>
							</HealthyLi>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Strong support structure for skin that feels smoother and firmer</ResultH25>
							</HealthyLi>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Normal production of melanin for an even looking skin tone</ResultH25>
							</HealthyLi>
						</ul>
					</HealthyTextsContainer>

					<HealthyTextsContainer>
						<ResultH25 style={{ fontWeight: 700 }}>How to maintain it:</ResultH25>

						<ul style={{ color: '#666666' }}>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Twice daily, custom-fit cleansing, exfoliation and moisturization</ResultH25>
							</HealthyLi>
							<HealthyLi>
								<ResultH25 style={{ color: '#666666' }}>Protect from sun and other types of environmental damage</ResultH25>
							</HealthyLi>
						</ul>
					</HealthyTextsContainer>

					<div style={{ display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'flex-start' }}>
						{props.concernName && (
							<WhiteButton onClick={() => props.displayPopUp!(true)} style={{ padding: '12px 16px' }}>
								<ResultH28>See how your skin should behave</ResultH28>
							</WhiteButton>
						)}
					</div>
				</div>

				<AdviceBox style={{ maxWidth: '180px' }} $isPopUp={props.isPopUp}>
					<ResultH25 style={{ fontWeight: 700 }}>Did you know...</ResultH25>

					<ResultH28>Healthy skin renews itself every 28 to 30 days on average.</ResultH28>
				</AdviceBox>
			</div>
		</HealthyContainer>
	);
};

interface PopUpHealthyI {
	onClose: Function;
}

export const PopUpHealthySkin = (props: PopUpHealthyI) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, []);

	return (
		<>
			<Shadow key={'POP_UP_SHADOW'} initial={{ opacity: 0 }} animate={{ opacity: 0.2 }} exit={{ opacity: 0 }} />

			<PopUpContainer key={'POP_UP_HEALTHY'} initial={{ y: '100%' }} animate={{ y: 0 }} exit={{ y: '100%' }} transition={{ type: 'spring', damping: 18 }}>
				<GreyBar />

				<CloseButton onClick={() => props.onClose()} src={CloseIcon}></CloseButton>

				<DetailedConcernPage setForcedConcern={() => {}} isPopUp concern={null} />
			</PopUpContainer>
		</>
	);
};
