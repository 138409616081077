import { FaceAreaPosition } from "../types/faceAreaPosition";

export const FACE_AREA_POSITIONS: {[area: string]: FaceAreaPosition} = {
    leftForehead: {
        origin: 299,
        abs: 333,
        ord: 297,
    },
    middleForehead: {
        origin: 109,
        abs: 338,
        ord: 108,
    },
    rightForehead: {
        origin: 69,
        abs: 104,
        ord: 67,
    },
    nose: {
        origin: 45,
        abs: 275,
        ord: 8,
    },
    leftCheek: {
        origin: 422,
        abs: 364,
        ord: 330,
    },
    rightCheek: {
        origin: 202,
        abs: 135,
        ord: 119,
    },
    chin: {
        origin: 194,
        abs: 418,
        ord: 176,
    },
};