import 'image-capture';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { DEFAULT_COUNTDOWN, DEFAULT_DETECTION_CONSTRAINTS, DEFAULT_DETECTION_INTERVAL, DEFAULT_PHOTO_SETTINGS, DEFAULT_VIDEO_CONSTRAINTS, DEFAULT_WARNINGS } from '../../constants/camera';
import { useDetectionModel } from '../../hooks/useDetectionModel';
import { FaceLandmarksDetectionResults, FaceLandmarksDetectionStatus } from '../../types';
import { CameraError } from '../../types/cameraError';
import { DetectionConstraints } from '../../types/detectionConstraints';
import { EnabledWarnings } from '../../types/enabledWarnings';
import { A3CPhotoSettings } from '../../types/photoSettings';
import { faceDetection } from '../../utils/faceDetection';
import { A3CameraRendering, CapturedFrame } from './style';

const stopStream = (stream: MediaStream) => {
	stream.getTracks().forEach((track) => {
		track.stop();
	});
};

export interface A3CameraProps {
	videoConstraints?: MediaTrackConstraints;
	detectionConstraints?: DetectionConstraints;
	photoSettings?: A3CPhotoSettings;
	countdown?: number; // in milliseconds
	detectionInterval?: number; // in milliseconds
	warnings?: EnabledWarnings;
	mirrored?: boolean;
	className?: string;
	style?: React.CSSProperties;
	pauseDetection?: boolean;
	pauseCamera?: boolean;
	useGrabFrame?: boolean;
	onCameraLoaded?: () => HTMLVideoElement;
	onPhotoTaken?: (res: FaceLandmarksDetectionResults) => void;
	onStatusChange?: (status: FaceLandmarksDetectionStatus) => void;
	onError?: (error: CameraError) => void;
	onCountdownStart?: () => void;
	onCountdownStop?: () => void;
	onMirrorChange?: (mirrored: boolean) => void;
}

const A3Camera = (props: A3CameraProps) => {
	const {
		videoConstraints: propsVideoContraints,
		detectionConstraints: propsDetectionConstraints,
		photoSettings: propsPhotoSettings,
		warnings: propsWarnings,
		countdown = DEFAULT_COUNTDOWN,
		detectionInterval = DEFAULT_DETECTION_INTERVAL,
		mirrored: propsMirrored,
		pauseDetection: propsPauseDetection = false,
		pauseCamera = false,
		useGrabFrame = false,
		onCameraLoaded,
		onPhotoTaken,
		onStatusChange,
		onCountdownStart,
		onCountdownStop,
		onError,
		onMirrorChange,
		className,
		style,
		...rest
	} = props;
	const { detectionModel } = useDetectionModel();
	const [cameraActive, setCameraActive] = useState<boolean>(true);
	const [pauseDetection, setPauseDetection] = useState(propsPauseDetection);
	const videoRef = useRef<HTMLVideoElement>(null);
	const intervalRef = useRef<ReturnType<typeof setInterval>>();
	const countdownRef = useRef<ReturnType<typeof setTimeout>>();
	const [cameraReady, setCameraReady] = useState<boolean>(false);
	const capturedFrameRef = useRef<HTMLCanvasElement>(null);
	const [showCapturedFrame, setShowCapturedFrame] = useState<boolean>(false);
	const [stream, setStream] = useState<MediaStream>();

	const photoSettings = useMemo(
		() => ({
			...DEFAULT_PHOTO_SETTINGS,
			...propsPhotoSettings,
		}),
		[propsPhotoSettings],
	);

	const detectionConstraints = useMemo(
		() => ({
			...DEFAULT_DETECTION_CONSTRAINTS,
			...propsDetectionConstraints,
		}),
		[propsDetectionConstraints],
	);

	const videoConstraints = useMemo(
		() => ({
			...DEFAULT_VIDEO_CONSTRAINTS,
			...propsVideoContraints,

			// reverse width and height for mobile devices
			width: propsVideoContraints?.height || DEFAULT_VIDEO_CONSTRAINTS.height,
			height: isTablet ? propsVideoContraints?.height || DEFAULT_VIDEO_CONSTRAINTS.height : propsVideoContraints?.width || DEFAULT_VIDEO_CONSTRAINTS.width,
		}),
		[propsVideoContraints],
	);

	const warnings = useMemo(
		() => ({
			...DEFAULT_WARNINGS,
			...propsWarnings,
		}),
		[propsWarnings],
	);

	const [oldMirroredValue, setOldMirroredValue] = useState(propsMirrored || videoConstraints.facingMode === 'user');
	const [mirrored, setMirrored] = useState(propsMirrored || videoConstraints.facingMode === 'user');

	const handleVisibilityChange = () => {
		setCameraActive(document.visibilityState === 'visible');
	};

	useEffect(() => {
		window.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			window.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		return () => {
			if (stream) {
				stopStream(stream);
			}
		};
	}, [stream]);

	useEffect(() => {
		if (onMirrorChange) onMirrorChange(mirrored);
	}, [mirrored, onMirrorChange]);

	useEffect(() => {
		if (detectionModel && videoRef.current && cameraActive && !pauseDetection && !pauseCamera && cameraReady) {
			intervalRef.current = setInterval(() => {
				faceDetection(videoRef.current, detectionConstraints, warnings, detectionModel, mirrored)
					.then((res) => {
						handleDetectionResChange(res);
					})
					.catch((error) => {
						handleDetectionResChange({
							status: {
								loading: true,
							},
						});
					});
			}, detectionInterval);
		} else {
			handleDetectionResChange({
				status: {
					loading: true,
				},
			});
		}
		return () => {
			clearInterval(intervalRef.current);

			resetCountdown();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detectionModel, cameraActive, pauseCamera, pauseDetection, detectionInterval, detectionConstraints, warnings, mirrored, cameraReady]);

	const resetCountdown = useCallback(() => {
		if (countdownRef.current) {
			clearTimeout(countdownRef.current);
			countdownRef.current = undefined;
		}
		if (onCountdownStop) onCountdownStop();
	}, [onCountdownStop]);

	const loadCamera = useCallback(() => {
		setCameraReady(false);
		if (videoRef.current && capturedFrameRef.current) {
			videoRef.current.pause();
			const canvas = capturedFrameRef.current;
			canvas.width = videoRef.current.clientWidth;
			canvas.height = videoRef.current.clientHeight;
			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
				setShowCapturedFrame(true);
			}
		}

		navigator.mediaDevices
			.getUserMedia({
				video: {
					...videoConstraints,
				},
				audio: false,
			})
			.then((stream) => {
				setStream(stream);
				if (!videoRef.current) return;
				videoRef.current.srcObject = stream;

				videoRef.current.onloadedmetadata = () => {
					setTimeout(() => {
						videoRef.current?.play().then(() => {
							setShowCapturedFrame(false);
							setMirrored(propsMirrored || videoConstraints.facingMode === 'user');
							setOldMirroredValue(propsMirrored || videoConstraints.facingMode === 'user');
							setCameraReady(true);
							if (onCameraLoaded) onCameraLoaded();
						});
					}, 600);
				};
			})
			.catch(() => {
				if (onError) onError('access-denied');
			});
	}, [onCameraLoaded, onError, propsMirrored, videoConstraints]);

	const takePhoto = useCallback(() => {
		if (!videoRef.current) return;
		const stream = videoRef.current.srcObject as MediaStream;
		if (!stream) return;
		const track = stream.getVideoTracks()[0];
		const imageCapture = new ImageCapture(track);
		const imageWidth = photoSettings?.width || (typeof videoConstraints.width === 'number' ? videoConstraints.width : videoConstraints.width?.ideal || videoConstraints.width?.exact || undefined);
		const imageHeight = photoSettings?.height || (typeof videoConstraints.height === 'number' ? videoConstraints.height : videoConstraints.height?.ideal || videoConstraints.height?.exact || undefined);

		if (useGrabFrame) {
			imageCapture.grabFrame().then((imageBitmap) => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				canvas.width = imageWidth || imageBitmap.width;
				canvas.height = imageHeight || imageBitmap.height;

				if (mirrored) {
					ctx?.translate(canvas.width, 0);
					ctx?.scale(-1, 1);
				}

				const targetRatio = canvas.width / canvas.height;
				
				const targetWidth = imageBitmap.height * targetRatio;
				const widthDiff = Math.abs(targetWidth - imageBitmap.width);

				ctx?.drawImage(imageBitmap, widthDiff / 2, 0, targetWidth, imageBitmap.height , 0, 0, canvas.width, canvas.height);
				
				const base64 = canvas.toDataURL(`image/${photoSettings.type}`, photoSettings.quality);
				faceDetection(canvas, detectionConstraints, warnings, detectionModel, mirrored, true).then((faceDetectionRes) => {
					if (onPhotoTaken) {
						onPhotoTaken({
							...faceDetectionRes,
							image: base64,
						});
					}
					setPauseDetection(true);
				});
			});
		} else {
			imageCapture.takePhoto().then((blob) => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				const img = new Image();

				img.src = URL.createObjectURL(blob);
				img.onload = async () => {
					canvas.width = imageWidth || img.width;
					canvas.height = imageHeight || img.height;

					if (mirrored) {
						ctx?.translate(canvas.width, 0);
						ctx?.scale(-1, 1);
					}
					const widthDiff = Math.abs(canvas.width - img.width);
					const heightDiff = Math.abs(canvas.height - img.height);
					ctx?.drawImage(img, widthDiff / 2, heightDiff / 2, canvas.width, canvas.height - heightDiff, 0, 0, canvas.width, canvas.height);
				
					const base64 = canvas.toDataURL(`image/${photoSettings.type}`, photoSettings.quality);
					const faceDetectionRes = await faceDetection(canvas, detectionConstraints, warnings, detectionModel, mirrored, true);
					if (onPhotoTaken)
						onPhotoTaken({
							...faceDetectionRes,
							image: base64,
						});
					setPauseDetection(true);
				};
			});
		}
	}, [detectionConstraints, detectionModel, mirrored, onPhotoTaken, photoSettings?.height, photoSettings.quality, photoSettings.type, photoSettings?.width, useGrabFrame, videoConstraints.height, videoConstraints.width, warnings]);

	useEffect(() => {
		if (!cameraActive) return;
		loadCamera();

		return () => {
			// stopMediaTracks();
		};
	}, [cameraActive, loadCamera]);

	const handleDetectionResChange = useCallback(
		(res: FaceLandmarksDetectionResults) => {
			if (onStatusChange) onStatusChange(res.status);

			if (!res.status?.good) {
				resetCountdown();
				return;
			}

			if (countdownRef.current) return;
			if (onCountdownStart) onCountdownStart();
			countdownRef.current = setTimeout(() => {
				takePhoto();
			}, countdown);
		},
		[onStatusChange, onCountdownStart, countdown, resetCountdown, takePhoto],
	);

	return (
		<>
			<A3CameraRendering className={className} style={style} playsInline autoPlay mirrored={+mirrored} ref={videoRef} {...rest} />
			<CapturedFrame className={className} style={style} show={+showCapturedFrame} mirrored={+oldMirroredValue} ref={capturedFrameRef} />
		</>
	);
};

export default A3Camera;
