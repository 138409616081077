import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { CliniqueState, Product, Retailer } from "../../type"
import { PriceAndQuantityContainer, ResultH28, UVprotectionProductCell, UVprotectionProductImg, UVprotectionProductTextContainer, UVprotectionSkeletonImg, UVprotectionSkeletonText } from "../../styles/newResultsStyle"
import { handlePrice, handleQuantity, handleShortDescription, handleSrc } from "./utils"
import { BlackButton } from "../../styles/recoStyle"
// import { addToBagWithIframeResizer } from '../constantes/iFrameResizerTest'

interface ProductCellProps {
    product: Product | undefined,
    onClick?: Function,
    // selectProduct?: Function
}

export const UVProductCell = (props: ProductCellProps) => {
    const sephoraProductsMap = require("../../assets/sephora/productsMap.json")
    const MSProductsMap = require("../../assets/markAndSpencer/productsMap.json")

    const { market, retailer, isMicrosite } = useSelector((state: CliniqueState) => state.session)

    const { t } = useTranslation()

    const handleEnabled = (): boolean => {
        if (!retailer) {
            return true
        }

        if (!props.product) {
            return false
        }

        if (sephoraProductsMap[props.product.prod_base_id] && retailer === Retailer.sephora) {
            return true
        }

        if (MSProductsMap[props.product.prod_base_id] && retailer === Retailer.MS) {
            return true
        }

        return false
    }

    const handleCellClick = (e: any) => {

        if (!props.product) {
            return
        }

        if (retailer === Retailer.sephora) {
            if (sephoraProductsMap[props.product.prod_base_id]) {
                window.open(sephoraProductsMap[props.product.prod_base_id]["link"], "_blank")
                e.stopPropagation()
            }
        }

        if (retailer === Retailer.MS) {
            if (MSProductsMap[props.product.prod_base_id]) {
                window.open(MSProductsMap[props.product.prod_base_id]["link"], "_blank")
                e.stopPropagation()
            }
        }
    }

    const handleCTAText = () => {
        if (retailer === Retailer.sephora) {
            return "Voir produit"
        }

        if (retailer === Retailer.MS) {
            return "See product"
        }

        return t("shop-now")
    }

    return (
        <UVprotectionProductCell onClick={() => props.onClick!(props.product)}>
            {props.product ? <UVprotectionProductImg src={handleSrc(props.product, market)} /> :
                <UVprotectionSkeletonImg />}

            <UVprotectionProductTextContainer>
                {!props.product ? <UVprotectionSkeletonText /> : <ResultH28 dangerouslySetInnerHTML={{ __html: props.product!.name }} />}
                {!props.product ? <UVprotectionSkeletonText /> : window.innerWidth > 768 && <ResultH28 style={{ color: "#666666" }} dangerouslySetInnerHTML={{ __html: handleShortDescription(props.product) }} />}

                {/* {<TagContainer inRecoPage>
                    {props.product?.tags.map((tag, index) => (
                        <TagDiv key={"TAG_" + index}>{translateAnalyseName(t, tag as string)}</TagDiv>
                    ))}
                </TagContainer>} */}

                <PriceAndQuantityContainer style={{ alignItems: window.innerWidth > 768 ? "start" : "end" }}>
                    {!isMicrosite && !retailer && <ResultH28 style={{ fontWeight: 700, width: "initial" }}>
                        {handlePrice(props.product)}
                    </ResultH28>}

                    {!isMicrosite && !retailer && <ResultH28 style={{ fontWeight: 400, color: "#666666", width: "initial" }}>
                        {handleQuantity(props.product)}
                    </ResultH28>}
                </PriceAndQuantityContainer>

                {!isMicrosite && props.product && <BlackButton
                    style={{ position: "relative" }}
                    enabled={handleEnabled()}
                    whileTap={{ scale: 1.05 }}
                    onClick={(e) => {
                        handleCellClick(e)
                    }}>
                    {handleCTAText()}
                </BlackButton>}
            </UVprotectionProductTextContainer>

        </UVprotectionProductCell>
    )
}