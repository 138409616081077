import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { MainBlackButton } from "./basics";

export const SPPContainer = styled(motion.div)`
    display: flex;
    position: fixed;
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0px;
    left: 0px;
    padding: 132px 40px 40px;
    box-sizing: border-box;
    overflow-y: auto;

    @media (max-width: 768px){
        flex-direction: column;
        padding: 0px 16px 72px 16px;
    }
`

export const SPPImagesContainer = styled.div`
    height: 100%;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px){
        display: none;
    }
`

export const SPPSmallImagesContainer = styled.div`
    height: 150px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
`

export const SPPTextsContainer = styled.div`
    flex: 2;
    position: relative;
    padding-left: 20px;
    box-sizing: border-box;

    @media (max-width: 768px){
        padding-left: initial;
        margin-top: 36px;
    }
`
const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const SPPImg = styled(motion.img) <{ isIncarousel?: boolean }>`
    width: 100%;
    object-fit: contain;
    animation: ${fade} 2s forwards;
    background-color: #FAFAFA;

    @media (max-width: 768px){
        height: 350px;
        object-fit: ${props => props.isIncarousel ? "contain" : 'cover'};
    }
`

export const ProductName = styled.p<{ isWide?: boolean }>`
    margin: 0px;
    font: normal normal 700 18px/130% 'Clinique Helvetica Neue';
    padding: 0px 0px 12px 0px;

    @media (max-width: 768px){
        font: ${props => props.isWide ? "normal normal 700 24px/120% 'Clinique Helvetica Neue'" : "normal normal 400 14px/148% 'Clinique Helvetica Neue'"};
    }
`

export const TagContainer = styled.div<{ inRecoPage?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 8px 0px;
`

export const Tag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(232, 243, 228, 1);
    padding: 8px 16px;
    border-radius: 16px;
    font: normal normal 400 14px/100% 'Clinique Helvetica Neue';
    color: black;
`

export const MainText = styled.p<{ isTitle?: boolean }>`
    font: normal normal 400 14px/140% 'Clinique Helvetica Neue';
    font-weight: ${props => props.isTitle && "700"};
    margin: 0px;
    margin-bottom: ${props => props.isTitle ? "4px" : "24px"};

    @media (max-width: 768px){
        
    }
`

export const Price = styled.p`
    margin: 0px;
    margin-bottom: 12px;
    font: normal normal 700 24px/100% 'Clinique Helvetica Neue';
    color: black;
`

export const MainButton = styled(MainBlackButton) <{ $enabled: boolean }>`
    max-width: 240px;
    max-height: 62px;
    background-color: ${props => props.$enabled ? "black" : "lightgrey"};
    pointer-events:  ${props => props.$enabled ? "all" : "none"};
    @media (max-width: 768px){
        max-width: initial;
    }
`

export const QuantityButtonContainer = styled.div`
    display: flex;
    align-items: center;
`

export const OutOfStockText = styled.p`
    margin: 16px 0px;
    color: black;
    font: normal normal 400 15px/100% 'Clinique Helvetica Neue';
`

export const QuantityText = styled.p`
    margin: 0px;
    margin-right: 12px;
    font: normal normal 700 16px/100% 'Clinique Helvetica Neue';
    text-transform: uppercase;
`

export const QuantityButton = styled.p<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal 700 18px/100% 'Clinique Helvetica Neue';
    border: 0.8px solid rgb(230,230,230);
    width: 40px;
    height: 40px;
    pointer-events: ${props => props.disabled && "none"};
    color: ${props => props.disabled ? "lightgrey" : "black"};
`

export const OverViewText = styled.p`
    margin: 24px 0px;
    font: normal normal 700 18px/100% 'Clinique Helvetica Neue';
    text-transform: capitalize;
`

export const ShadeBlock = styled.div<{ shade: string }>`
    width: 54px;
    height: 12px;
    margin-right: 12px;
    background-color: ${props => props.shade};
    pointer-events: none;
`