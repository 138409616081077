import { demkyParser } from "./parser";
import * as ActionTypes from "../../constantes/actionTypes"
import { AnalyseStep } from "../skinDiag/defaults";
import { SkinDiagApi } from "../skinDiag";

export const getAnalysis = async (image: any, dispatch: any) => {

    let skinDiag = new SkinDiagApi();
    let blobFace = await fetch(image).then((r) => r.blob());
    let count: number = 0

    skinDiag
        .getWarning(blobFace)
        .then((warnings) => {
            let url = new URL(window.location.href)
            if (warnings.length !== 0 && url.searchParams.get("debug") !== "true") {
                // Error in warnings
                dispatch({ type: ActionTypes.ANALYSE_ERROR, value: warnings });
                throw new Error('==> [SkinDiag] Warning Fail');
            } else {
                dispatch({ type: ActionTypes.NO_WARNING });
                getDemkyAnalysis(blobFace, dispatch, count)
            }

        })
        .catch((error) => {
            console.error(error);
        });
}

const getDemkyAnalysis = async (blob: Blob, dispatch: any, count: number) => {
    var formdata = new FormData();
    var myHeaders = new Headers();
    myHeaders.append("X-API-Key", "6a460ac328d2b2a26696b4c66885b5a259bc694e844c92bdef437e5909a5e601");

    formdata.append("image", blob, "file");
    formdata.append("concerns", "[\"DARK_CIRCLES\", \"ACNE\", \"WRINKLES\", \"EYE_BAGS\", \"REDNESS\"]");

    await fetch("https://demky.imaaagery.app/demky", {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: myHeaders

    })
        .then(response => response.json())
        .then(result => dispatch({ type: ActionTypes.SET_ANALYSIS, value: demkyParser(result) }))
        .catch((error) => {
            count++
            if (count < 3) {
                setTimeout(() => {
                    getDemkyAnalysis(blob, dispatch, count)
                }, 1000);
            } else {
                alert("Please try later.")
            }
            throw new Error(error)
        });

    dispatch({ type: ActionTypes.ANALYSE_UPDATE, value: AnalyseStep.analysingFace });
}

