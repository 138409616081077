import { Controlbutton, ControlsContainer, ResultH1, ResultH25, ResultH28, SubComponent, UVPicture, UVTextContainer, WhiteButton } from '../../styles/newResultsStyle';
import testImage from '../../assets/Images/AdamWithInv.jpg';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import minus from '../../assets/icons/minus.svg';
import plus from '../../assets/icons/plus.svg';
import { ResultStep } from '../../type';
import { useTranslation } from 'react-i18next';

interface UVDamagesI {
	resultStep: ResultStep;
}

export const UVDamagesTab = (props: UVDamagesI) => {
	const { t } = useTranslation();

	return (
		<SubComponent style={{ backgroundColor: '#FAFAFA', justifyContent: 'initial', padding: '16px 0px' }} $height={'initial'}>
			<div style={{ position: 'relative', maxHeight: '700px', overflow: 'hidden' }}>
				<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
					{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
						<>
							<Controls />
							<TransformComponent>
								<UVPicture src={testImage} />
							</TransformComponent>
						</>
					)}
				</TransformWrapper>
			</div>

			<UVTextContainer>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '4px' }}>
					<ResultH1 style={{ textAlign: 'start', font: "normal normal 700 24px/28px 'Clinique Helvetica Neue'" }}>{t('your-uv-damage')}</ResultH1>

					<ResultH25>{t('uvdamage-our-tech')}</ResultH25>
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '16px', alignItems: 'start' }}>
					<ResultH25 style={{ color: '#666666' }}>{t('uvdamage-to-help')}</ResultH25>

					<WhiteButton>
						<ResultH28>{t('uvdamage-seeSPF')}</ResultH28>
					</WhiteButton>
				</div>
			</UVTextContainer>
		</SubComponent>
	);
};

export const Controls = () => {
	const { zoomIn, zoomOut } = useControls();

	return (
		<ControlsContainer>
			<Controlbutton onClick={() => zoomIn()}>
				<img alt='zoom in' src={plus} />
			</Controlbutton>
			<Controlbutton onClick={() => zoomOut()}>
				<img alt='zoom out' src={minus} />
			</Controlbutton>
		</ControlsContainer>
	);
};
