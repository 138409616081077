import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

export const CameraContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
`;

export const CameraPanel = styled.div`
	position: fixed;
	height: 70px;
	width: 100%;
	bottom: 0;
	background-color: black;
	display: flex;
	z-index: 3;
	justify-content: space-evenly;
	align-items: center;
`;

export const WarningContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;
`;

export const CameraWarningText = styled.p`
	font: normal normal normal 11px/34px Helvetica Neue;
	text-transform: uppercase;
	margin: 0;
`;

const rotateAnimation = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
	display: inline-block;
	width: 34px;
	height: 34px;

	&:after {
		content: ' ';
		display: block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: 2.5px solid black;
		animation: ${rotateAnimation} 1.2s linear infinite;
	}
`;

export const CameraWarnings = styled.span`
	color: #ffffff;
	text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
	font-size: 1.4em;
	font-weight: 500;
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	top: 90px;
	z-index: 40;
	max-width: 100vw;
	width: 100vw;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 1.2em;
	}
`;

export const Flash = styled(motion.div)`
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: white;
	transition: 0.3s;
	opacity: 0;
	top: 0px;
	left: 0px;
	z-index: 100;
`;

