import { motion } from "framer-motion";
import styled from "styled-components/macro";
import i18n from "../i18nextInit";
import { MainBlackButton, MainButtonText } from "./basics";

export const BiometricAndConsentText = styled(motion.p)`
    padding: 0px;
    position: relative;
    text-align: start;
    font: normal normal 400 24px/100% 'Clinique Helvetica Neue';
    max-width: 540px;
    box-sizing: border-box;
    color: #000;

    @media (max-width: 768px){
        font-size: 24px;
    }
`

export const BottomContainer = styled(motion.div)`
    width: 100%;
    position: relative;
    box-sizing: border-box;
    margin-top: 84px;
    @media (max-width: 768px){
        margin-top: 8px;
    }
`

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;

    @media (max-width: 768px){
        flex-direction: column;
        gap: 12px;
    }

`

export const Component = styled(motion.div)`
    box-sizing: border-box;
    padding: 156px 42px 24px 42px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: visible;
    min-height: 95vh;

    @media (max-width: 768px){
        padding: 86px 16px 48px 16px;
        overflow: hidden;
    }
`

export const LockIcon = styled.img`
    width: 72px;
    @media (max-width: 768px){
        width: 64px;
    }
`

export const MainText = styled(motion.p) <{ margintop: number }>`
    margin: 0px;
    margin-top: ${props => props.margintop}px;
    padding-right: ${(i18n.language === "HeIl" || i18n.language === "ArAr") && "100px"};
    padding-left: ${(i18n.language === "HeIl" || i18n.language === "ArAr") && "100px"};
    max-width: 540px;
    margin-bottom: 24px;
    font: normal normal 300 16px/160% "Clinique Helvetica Neue";
    font: ${props => (i18n.language === "DaDk" || i18n.language === "NoNo" || i18n.language === "ElGr") && "normal normal 300 16px/160% 'Clinique Helvetica Neue'"};
    text-align: start;
    flex: 1;

    @media (max-width: 768px){
        padding-right: initial;
        font-size: 14px;
        margin-bottom: 16px;
        padding-right: ${props => (i18n.language !== "HeIl" && i18n.language !== "ArAr") && "38px"};
        padding-left: ${props => (i18n.language === "HeIl" || i18n.language === "ArAr") && "38px"};
    }
`

export const DeclineText = styled(MainText)`
    color: #999999;
    margin-top: 28px;

    @media (max-width: 768px){
        font-size: 14px;
        margin-top: 12px;
    }
`

export const BackgroundImage = styled.img`
    z-index: -1;
    position: fixed;
    min-width: 100%;
    height: 105vh;
    left: 0px;
    top: 0px;
    transform: ${props => (i18n.language === "HeIl" || i18n.language === "ArAr") ? "scaleX(-1)" : ""};

    @media (max-width: 768px){
        opacity: 0.8;
        width: auto;
        height: 100vh;
    }
`

export const KoreanContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 0px;
    gap: 24px;
`

const handleMarginLeft = (isFirst?: boolean, isPoint?: boolean) => {
    if (isFirst) {
        return ""
    }

    if (isPoint) {
        return "16px"
    }

    return ""
}

export const KoreanItem = styled.div<{ $isFirst?: boolean, $isPoint?: boolean }>`
    display: flex;
    align-items: start;
    margin-left: ${props => handleMarginLeft(props.$isFirst, props.$isPoint)};
    font: normal normal 400 16px/160% "Clinique Helvetica Neue";
    gap: 8px;
`

export const KoreanPoint = styled.p`
    padding: 0px 8px;
    margin: 0px;
`

export const BottomButton = styled(MainBlackButton)<{$isEnabled: boolean}>`
    background-color: ${props => props.$isEnabled ? "black" : "#E6E6E6"};
    pointer-events: ${props => props.$isEnabled ? "all" : "none"};
    transition: all 0.7s;
`

export const BottomText = styled(MainButtonText)<{$isEnabled: boolean}>`
    color: ${props => props.$isEnabled ? "" : "#999999"};
    transition: all 0.7s;
`