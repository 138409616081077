import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import i18n from "../i18nextInit"
import { MainBlackButton } from "./basics";

export const shine = keyframes`
    to {
        background-position-x: -200%;
    }
`

const setComponentLeft = (isRecoDisplayed: boolean): string => {
    if (i18n.language === "HeIl" || i18n.language === "ArAr") {
        return isRecoDisplayed ? "100%" : "0%"
    } else {
        return isRecoDisplayed ? "-100%" : "0%"
    }
}

export const Component = styled(motion.div) <{ isRecoDisplayed: boolean, isRetailer?: boolean }>`
    width: 200vw;
    display: -webkit-inline-box;
    position: relative;
    overflow-x: hidden;
    margin-top: ${props => props.isRetailer ? "64px" : "132px"};
    left: ${props => setComponentLeft(props.isRecoDisplayed)};
    transition: left 0.5s;
`

export const LeftComponent = styled(motion.div) <{ height: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 40px 64px;
    box-sizing: border-box;
    position: relative;
    width: 50%;
    height: ${props => props.height !== 0 ? props.height + "px" : ""};
    overflow: hidden;
    
    @media (max-width: 768px){
        padding: 12px 16px 54px;
    }
`

export const AnalyseRecoContainer = styled.div<{ isFixed: boolean }>`
    width: calc(100% - 80px);
    background-color: white;
    z-index: 5;
    display: flex;
    align-items: center;
    position: fixed;
    left: 40px;
    top: 64px;

    @media (max-width: 768px){
        width: calc(100% - 32px);
        left: 16px;
    }
`

export const AnalyseRecoCell = styled.div<{ isSelected?: boolean }>`
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
    flex: 1;
    flex-direction: column;
    position: relative;
    font: normal normal 700 12px/100% 'Clinique Helvetica Neue';
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${props => props.isSelected ? "black" : "#999999"};
`

const setBottomLeft = (isSelector?: boolean, isRecoDisplayed?: boolean): string => {
    if (i18n.language === "HeIl" || i18n.language === "ArAr") {
        return ""
    } else {
        return isSelector && isRecoDisplayed ? "50%" : "0%"
    }
}

const setBottomRight = (isSelector?: boolean, isRecoDisplayed?: boolean): string => {
    if (i18n.language === "HeIl" || i18n.language === "ArAr") {
        return isSelector && isRecoDisplayed ? "50%" : "0%"
    } else {
        return ""
    }
}

export const BottomLine = styled.div<{ isSelector?: boolean, isRecoDisplayed?: boolean }>`
    position: absolute;
    height: 1px;
    background-color: ${props => props.isSelector ? "black" : "#E6E6E6"};
    width: ${props => props.isSelector ? "50%" : "100%"};
    bottom: 0px;
    left: ${props => setBottomLeft(props.isSelector, props.isRecoDisplayed)};
    right: ${props => setBottomRight(props.isSelector, props.isRecoDisplayed)};
    transition: all 0.5s;
`

export const BigTitles = styled.p<{ first?: boolean }>`
    margin: 72px 0px 38px;
    margin-top: ${props => props.first ? "48px" : ""};
    font: normal normal 700 48px/100% 'Clinique Helvetica Neue';
    color: black;
    width: 100%;

    @media (max-width: 768px){
        font-size: 32px;
        margin: 48px 0px 20px;
        margin-top: ${props => props.first ? "8px" : ""};
    }
`

export const ConcernBoxContainer = styled.div<{ isSmall?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 3vw;
    scrollbar-width: 0px;

    @media (max-width: 768px){
        gap: 20px;
        overflow-x: ${props => props.isSmall && "auto"};
        flex-wrap: ${props => props.isSmall && "initial"};

        ::-webkit-scrollbar{
            width: 0px;
            height: 0px;
        }
    }


`

export const ConcernBoxCell = styled.div<{ isSmall: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    position: relative;
    width: 28vw;

    @media (max-width: 768px){
        width: ${props => !props.isSmall && "100%"};
        flex: 1 0 60%;
    }
`

export const ConcernImg = styled.img`
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;

    @media (max-width: 768px){
        aspect-ratio: 340/265;
    }
`

export const ConcernImgSkeleton = styled.div`
    width: 100%;
    aspect-ratio: 1;
    border: none;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;

    @media (max-width: 768px){
        aspect-ratio: 340/265;
    }
`

export const ConcernText = styled.p<{ isSmall?: boolean }>`
    margin: 24px 0px 14px;
    width: 100%;
    font: normal normal 700 18px/100% 'Clinique Helvetica Neue';
    text-align: center;

    @media (max-width: 768px){
        margin: 20px 0px 8px;
        padding: ${props => props.isSmall && "0px 12px"};
        box-sizing: border-box;
    }
`

export const ConcernNumber = styled.div`
    width: 32px;
    height: 32px;
    background-color: #FAFAFA;
    font: normal normal 700 20px/100% 'Clinique Helvetica Neue';
    color: black;
    position: absolute;
    left: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:768px) {
        width: 48px;
        height: 48px;
        font-size: 28px;
    }
`

export const ReadMore = styled.p`
    font: normal normal 400 14px/100% 'Clinique Helvetica Neue';
    text-decoration: underline;
    margin: 0px 0px 36px; 
    width: 100%;
    text-align: center;
    color: black;
`

export const EmptyLine = styled.div<{ isTop?: boolean }>`
    height: 12px;
    width: 100%;
    background-color: #E6E6E6;
    left: 0px;
    position: absolute;
    bottom: ${props => !props.isTop && "0px"};
    top: ${props => props.isTop && "0px"};
`

export const ScoreLine = styled.div<{ score: number, isTop?: boolean }>`
    height: 12px;
    width: ${props => props.score + "%"};
    background-color: #ADDDB7;
    left: ${props => i18n.language === "HeIl" ? "" : "0px"};
    right: ${props => i18n.language === "HeIl" ? "0px" : ""};
    position: absolute;
    bottom: ${props => !props.isTop && "0px"};
    top: ${props => props.isTop && "0px"};
`

export const UVcontainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    height: 228px;
    background-color: #FAFAFA;

    @media (max-width: 768px) {
        flex-direction: column;
        height: initial;
    }
`

export const UVImg = styled.img`
    width: 45%;
    height: auto;
    object-fit: cover;

    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`

export const UVImgSkeleton = styled.div`
    width: 45%;
    height: auto;
    object-fit: cover;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;

    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`

export const UVTitle = styled.p`
    font: normal normal 700 24px/100% 'Clinique Helvetica Neue';
    width: 100%;
    margin: 0px 0px 12px;
    color: black;
`

export const UVText = styled.p`
    font: normal normal 400 16px/148% 'Clinique Helvetica Neue';
    width: 100%;
    margin: 0px 0px 8px;
    color: black;
`

export const UVTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 24px 16px 16px;
    }
`

export const KeepResultsContainer = styled.div`
    background-color: rgba(173, 221, 183, 1);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 40px 40px;
    box-sizing: border-box;
    margin-top: 48px;

    @media (max-width: 768px){
        padding: 32px 20px 20px;
    }
`

export const KeepResultsTitle = styled.p`
    font: normal normal 700 48px/48px 'Clinique Helvetica Neue';
    margin: 0px;
    padding-bottom: 20px;

    @media (max-width: 768px){
        font-size: 32px;
    }
`

export const KeepResultsText = styled.p`
    font: normal normal 400 18px/100% 'Clinique Helvetica Neue';
    margin: 0px;
    padding-bottom: 60px;

    @media (max-width: 768px){
        padding-bottom: 32px;
    }
`

export const KeepYourResultsButton = styled(MainBlackButton)`
    max-width: 200px;

    @media (max-width: 768px){
        width: 100%;
        max-width: initial;
    }
`