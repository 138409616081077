import { motion } from "framer-motion";
import styled from "styled-components";
import { MainBlackButton } from "./basics";

export const Component = styled(motion.div)`
    display: flex;
    width: 100vw;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    background-color: #FAFAFA;
    min-height: 100svh;

    @media (min-width: 768px){
        justify-content: center;
    }

    @media (max-width: 768px){
        padding: 64px 16px 92px;
    }
`

export const QuestColoredContainer = styled.div`
    background-color: #ADDDB7;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

export const QuestsButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    @media (max-width: 768px){
        gap: 8px;
        margin-top: 12px;
    }
`

export const QuestButton = styled(MainBlackButton)`
    flex: 1 1 40%;
`

export const WhatsMySkinTypeText = styled.p`
    font: normal normal 400 22px/100% 'Clinique Helvetica Neue';
    text-decoration: underline;
    width: 500px;
    text-align: start;

    @media (max-width: 768px){
        padding: 0px;
        padding-left: 24px;
        padding-right: 24px;
        margin: 0px;
        width: 100%;
        box-sizing: border-box;
    }
`
export const TellUsTitle = styled.p`
    font: normal normal 300 48px/48px 'Clinique Helvetica Neue';
    color: #000;
    text-align: start;
    margin: 0px 0px 64px; 

    @media (max-width: 768px){
        font: normal normal 300 30px/30px 'Clinique Helvetica Neue';
        margin: 32px 0px;
    }
`

export const BackGround = styled.img`
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
`

export const QuestTitle = styled.p`
    font: normal normal 400 16px/24px 'Clinique Helvetica Neue';
    color: #1A1A1A;
    margin: 16px 0px;
`

export const QuestColorBox = styled.div<{ $color: string, $isEnabled: boolean, $isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1 1 23%;
    aspect-ratio: 1/1;
    gap: 8px;
    background-color: ${props => !props.$isEnabled ? "white" : props.$color};
    opacity: ${props => props.$isEnabled ? 1 : 0.3};
    box-shadow: ${props => props.$isSelected ? "0px 4px 12px 0px #0000001A" : ""};
    transition: all 0.3s;

    @media (max-width: 768px){
        flex: initial;
        gap: 8px;
        height: initial;
        aspect-ratio: initial;
    }
`

export const QuestColorText = styled.p<{ $title?: boolean }>`
    padding: 0px;
    margin: 0px;
    font: normal normal 400 11px/16px 'Clinique Helvetica Neue';
    font-weight: ${props => props.$title ? "400" : ""};
`

export const SPFQuestColorText = styled(QuestColorText)`
    font-size: 14px;
`;

export const QuestColorContainer = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    margin-bottom: 64px;

    @media (max-width: 768px){
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
    }
`

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 16px 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;

    @media (max-width: 768px){
        position: fixed;
        padding: 16px;
        margin: 0px;
        box-shadow: 0px -4px 12px 0px #0000001A;
    }
`

export const BottomButton = styled.button<{ $enabled: boolean }>`
    color: ${props => props.$enabled ? "white" : "#999999"};
    background-color: ${props => props.$enabled ? "black" : "#E6E6E6"};
    pointer-events: ${props => props.$enabled ? "all" : "none"};
    font: normal normal 400 14px/20px 'Clinique Helvetica Neue';
    padding-top: 14px;
    padding-bottom: 10px;
    margin: 0px;
    border: none;
    width: 100%;
    transition: 0.3s;
`

export const ReadyBoldText = styled.p`
    
`

