import { KoreanContainer, KoreanItem, KoreanPoint } from '../../styles/onBoardStyle';
import Checkbox from './checkbox';

interface KoreanPPProps {
	isFirstChecked: boolean;
	checkFirst: Function;
	isScndChecked: boolean;
	checkScnd: Function;
}

const KoreanPP = (props: KoreanPPProps) => {
	const handleAllCheckClick = () => {
		if (props.isFirstChecked && props.isScndChecked) {
			props.checkFirst(false);
			props.checkScnd(false);
		} else {
			props.checkFirst(true);
			props.checkScnd(true);
		}
	};

	return (
		<KoreanContainer>
			<KoreanItem $isFirst>
				<Checkbox checked={props.isFirstChecked && props.isScndChecked} onChange={() => handleAllCheckClick()} disabled={false} />
				<label>전체 동의하기</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isFirstChecked} onChange={() => props.checkFirst(!props.isFirstChecked)} disabled={false} />
				<label>개인정보 수집 및 이용 동의하기 (필수)</label>
			</KoreanItem>

			<KoreanItem $isPoint>
				<KoreanPoint>•</KoreanPoint>
				<label>
					개인정보 수집 항목: <span style={{ fontWeight: 'bold' }}>얼굴 이미지</span>
				</label>
			</KoreanItem>

			<KoreanItem $isPoint>
				<KoreanPoint>•</KoreanPoint>
				<label>
					개인정보 수집 및 이용 목적: <span style={{ fontWeight: 'bold' }}>얼굴 이미지 분석을 통해 맞춤형 스킨케어 솔루션과 관련 제품 추천 제공</span>
				</label>
			</KoreanItem>

			<KoreanItem $isPoint>
				<KoreanPoint>•</KoreanPoint>
				<label>
					개인정보 보유 및 이용 기간: <span style={{ fontWeight: 'bold' }}>앱 사용 세션 이후 즉시 파기</span>
				</label>
			</KoreanItem>

			<KoreanItem $isPoint>
				<KoreanPoint>•</KoreanPoint>
				<label>
					개인정보 수집 거부 시 서비스 제한: <span style={{ fontWeight: 'bold' }}>위 사항들에 동의하지 않을 시 서비스 이용이 제한 될 수 있습니다.</span>
				</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isScndChecked} onChange={() => props.checkScnd(!props.isScndChecked)} disabled={false} />
				<label>만 14세 이상 동의하기 (필수)</label>
			</KoreanItem>
		</KoreanContainer>
	);
};

export default KoreanPP;
