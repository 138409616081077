import styled from "styled-components";
import { Center } from "./basics";

import { motion } from "framer-motion";

export const Component = styled(motion.div) <{ height: number }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: ${props => props.height}px;
    position: relative;
    left: 0px;
    right: 0px;
`

export const ImageBackground = styled(motion.img)`
    position: fixed;
    min-width: 100%;
    height: 100%;
    bottom: 0px;
    z-index: -1;
    transition: all 0.5s;
    object-fit: cover;

    @media (max-width:768px){
        width: 100%;
        right: 0px;
    }
`

export const ImagebackgroundWithLag = styled.img`
    position: fixed;
    min-width: 100%;
    height: 100%;
    bottom: 0px;
    z-index: -2;
    transition: all 0.5s;
    object-fit: cover;

    @media (max-width:768px){
        width: 100%;
        right: 0px;
    }
`

export const WhiteText = styled.p<{ step: number, isSelected: boolean }>`
    color: white;
    font: normal normal 400 48px/100% 'Clinique Helvetica Neue';
    text-align: center;
    padding: 0px;
    margin: 16px 0px;
    opacity: ${props => props.isSelected ? 1 : setTextOpacity(props.step)};
    transition: opacity 0.3s;

    @media (max-width: 768px){
        font-size: 36px;
        margin: 8px 0px;
    }
`

export const TipsText = styled.p`
    color: white;
    font: normal normal 400 12px/100% 'Clinique Helvetica Neue';
    padding: 0px;
    margin-bottom: 16px;

    @media (max-width: 768px){
        font-size: 16px;
        margin-bottom: 32px;
    }
`

const setTextOpacity = (step: number) => {
    switch (step) {
        case 1:
            return 0.8
        case 2:
            return 0.6
        case 3:
            return 0.4
        case 4:
            return 0.2
        default:
            return 0
    }
}

export const TipsContainer = styled(Center) <{ isdisable: boolean }>`
    flex-direction: column;
    margin-bottom: 78px;
    pointer-events: ${props => props.isdisable && "none"};
    @media (max-width: 768px){
        margin-bottom: 0px;
    }
`

export const TapToProceedText = styled.p`
    margin-top: 24px;
    position: absolute;
    bottom: 20%;
    opacity: 0.8;
    color: white;
    font: normal normal 400 16px/24px 'Clinique Helvetica Neue';
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.41);
`

