import i18n from "../i18nextInit"
import { BackGround, BottomButton, Component, QuestTitle, TellUsTitle } from "../styles/questStyle"
import readyBG from "../assets/Images/GreenGlass.jpg"
import { useDispatch, useSelector } from "react-redux"
import * as ActionTypes from '../constantes/actionTypes'
import { CliniqueState, StepView } from "../type"
import { useTranslation } from "react-i18next"
import { sendAnalytics } from "../constantes/utilitaries"

const AnalysisReady = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const sessionID = useSelector((state: CliniqueState) => state.session.sessionID);

    return (
        <Component
            style={{ justifyContent: "center" }}
            initial={{ x: (i18n.language === "HeIl" || i18n.language === "ArAr") ? "-100%" : "100%" }}
            animate={{ x: 0 }}
            exit={{ x: (i18n.language === "HeIl" || i18n.language === "ArAr") ? "100%" : "-100%" }}
            key="READY_KEY"
            transition={{ type: "spring", duration: 0.5 }}>

            <BackGround src={readyBG} />

            <TellUsTitle style={{ margin: "0px" }}>
                {t("skin-analysis-title")}
            </TellUsTitle>

            <QuestTitle style={{ margin: "8px 0px 32px" }}>
                {t("skin-analysis-content")}
            </QuestTitle>

            <BottomButton $enabled={true} 
                onClick={() => {
                    dispatch({ type: ActionTypes.SWITCH_STEP, value: StepView.results })
                    sendAnalytics("see my skin report", "click", sessionID)
                }}>
                {t("see-skin-report")}
            </BottomButton>
        </Component>
    )
}

const AnalysisReadyComp = () => {
    return <AnalysisReady />
}

export default AnalysisReadyComp